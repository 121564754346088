import styles from './WorkAddExistingFile.module.css';
import WorkListPenspringDrive from '../WorkListPenspringDrive'
import PSLogo from '../../assets/logos/homescreen48.png'
import PenspringSmall from '../../assets/penspring_medium_noreg.png'
import Required from '../Required'
import FileUploadDropzone from '../FileUploadDropzone'
import GoogleDrive from '../GoogleDrive'
import WorkSummaryLine from '../WorkSummaryLine'
import { guidEmpty } from '../../utils/GuidValidate';

export default (props) => {
	const { 
		penspringDriveOpen,
		setPenspringDriveOpen,
		handleSetPenspringFile,
		handleSetFileLocal,
		fileLocal,
		fileGoogle,
		filePenspring,
		handleSetFileGoogle,
		setGoogleDriveOpen,
		googleDriveOpen,
		isMobile,
	 } = props

	return (
		<div className={isMobile ? styles.containerMobile : styles.container}>
			<div className={styles.rowWrap}>
				<div className={styles.titleRequired}>
					Choose an Existing File
					<Required
						setIf={true}
						className={styles.requiredPosition}
						setWhen={(fileGoogle && fileGoogle?.file) || filePenspring || fileLocal} />
				</div>
				<div>
					<FileUploadDropzone
						preLabel={'OPTIONAL'} label={'Click here to select a local file, or drag & drop a file here'}
						subLabel={'Only one file is allowed at a time - Microsoft Word, HTML, OpenOffice, PDF, Richtext, data and text.'}
						setFile={handleSetFileLocal} />
						
					<GoogleDrive
						fileGoogle={fileGoogle}
						handleSetFileGoogle={handleSetFileGoogle}
						setGoogleDriveOpen={setGoogleDriveOpen}
						googleDriveOpen={googleDriveOpen}
						isMobile={isMobile}
						{...props} />

					<div className={styles.penspringDrive}>
						<div className={isMobile ? styles.rowGoogleMobile : styles.rowPenspring} onClick={() => setPenspringDriveOpen(!penspringDriveOpen)}>
							<img src={PSLogo} height={25} />
							<div className={styles.penspringText}>
								Or click here to copy an existing
							</div>
							<div className={styles.existingPenspring}>
								<img src={PenspringSmall} height={19} className={styles.penspringBackground} style={{ position: 'relative', top: '3px' }} />
							</div>
							<div>file</div>
						</div>
						{filePenspring && filePenspring.workId && filePenspring.workId !== guidEmpty &&
							<div style={{ marginTop: '10px' }}>
								<WorkSummaryLine work={filePenspring}
									isWorkAddView={true} //This is for the Mine subfolder display on WorkAddView to choose a file (which will override showEditLink, by the way)
									showEditLink //Because this is on the Myworks and if it is on mobile, then show the pencil at the beginning for a short cut.
									onClick={() => handleSetPenspringFile()}
									personId={props.personId}
									nameLength={150}
									darkerBackground={true}
									darkerLabel
									includeDates
									chosenWork={filePenspring}
									thisWorkFolderId={''}
									showExplorerOptions
									noFocusColor={!(true)}
									showDocumentDetails={true}
									// moveWorkToFolder={moveWorkToFolder}
									// isMoveWorkId={isMoveWorkId}
									// deleteWorkFromFolder={deleteWorkFromFolder}
									mineOrOthers={'Mine'}
									hasParentFolderGroup={false}
									{...props} />
							</div>
						}
						<div className={penspringDriveOpen ? styles.showPenspringDrive : styles.hide}>
							<WorkListPenspringDrive 
								openList={penspringDriveOpen} 
								setWork={handleSetPenspringFile} 
								work={filePenspring} {...props} />
						</div>
					</div>
				</div>
			</div>        
		</div>
	)
}


