import React, {useState} from 'react';
import styles from './WebsiteLinkEntry.module.css';
import {Popover} from "@mui/material";
import InputText from "../InputText";
import ButtonWithIcon from "../ButtonWithIcon";

function WebsiteLinkEntry(props) {
	const {isOpen, onSave, onCancel } = props;
	const [websiteLink, setWebsiteLink] = useState('')
	const [linkDisplayText, setLinkDisplayText] = useState('')
	const [websiteLinkError, setWebsiteLinkError] = useState('')
	const [linkDisplayTextError, setLinkDisplayTextError] = useState('')

	const processForm = () => {
		let hasError = false;
		if (!websiteLink) {
			setWebsiteLinkError('A website link is required.')
			hasError = true
		}
		if (!linkDisplayText) {
			setLinkDisplayTextError('A description or name is required.')
			hasError = true
		}
		if (hasError) return false

		onSave(websiteLink, linkDisplayText)
		setLinkDisplayText('')
		setWebsiteLink('')
	}

	return (
	  <Popover
		  open={isOpen}
		  anchorReference="anchorPosition"
		  anchorPosition={{ top: 100, left: 100 }}
		  anchorOrigin={{
			  vertical: 'top',
			  horizontal: 'left',
		  }}
		  transformOrigin={{
			  vertical: 'top',
			  horizontal: 'left',
		  }}
	  >
		  <div className={styles.container}>
			  Enter a website address with the text you want displayed
			  <InputText
				  size={"long"}
				  value={websiteLink}
				  label={`Website`}
				  onChange={(event) => {
					  setWebsiteLink(event.target.value)
					  setWebsiteLinkError('')
				  }}
				  required={true}
				  whenFilled={websiteLink}
				  error={websiteLinkError} />
			  <InputText
				  size={"medium"}
				  value={linkDisplayText}
				  label={`Description`}
				  onChange={(event) => {
					  setLinkDisplayText(event.target.value)
					  setLinkDisplayTextError('')
				  }}
				  required={true}
				  whenFilled={linkDisplayText}
				  error={linkDisplayTextError} />

			  <div className={styles.rowCentered}>
				  <a className={styles.noButton} onClick={onCancel}>Cancel</a>
				  <ButtonWithIcon label={'Save'} icon={'floppy_disk0'} onClick={processForm}/>
			  </div>
		  </div>
	  </Popover>
  )
}


export default WebsiteLinkEntry;