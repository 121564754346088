import React from 'react';
import styles from './SelectSingleDropDown.module.css';
import classes from 'classnames';
import Required from '../Required'
import { useMediaQuery } from "react-responsive"

export default ({
	label,
	id,
	onChange,
	value,
	error,
	options,
	optgroups,
	height,
	className = "",
	labelClass = "",
	selectClass = "",
	noBlank = false,
	indexName,
	zeroethLabel,
	zeroethValue,
	includeDownArrow,
	includeDownArrowFirst,
	required,
	whenFilled,
	disabled
}) => {

	const isMobile = useMediaQuery({ query: '(max-width: 500px)' })
	const arrowFirst = includeDownArrowFirst && isMobile ? '&#x25BC; ' : '';

	return (
		<div className={classes(styles.container, className)}>
			<div className={styles.row}>
				{label && <span htmlFor={id} className={classes(required ? styles.requiredLabelText : styles.labelText, labelClass)}>{label}</span>}
				<Required setIf={required} setWhen={whenFilled}/>
			</div>
			<div>
				<select
					id={id}
					name={id}
					value={value}
					disabled={disabled}
					onChange={(event) => onChange(event)}
					className={classes(styles.editControl, styles[`size${height}`], selectClass)}
				>
					{!noBlank && <option key={-1} value={zeroethValue || 0}>{zeroethLabel ? zeroethLabel : "- -"} {includeDownArrow && isMobile ? '&#x25BC;' : ''}</option>}
					{!optgroups && options && options.length > 0 &&
						options.map((option, index) => {
							let tabSpace = "";
							for (let i = 0; i < option.levelDown; i++) {
								tabSpace += "&nbsp;&nbsp;&nbsp;&nbsp;";
							}
							return <option key={index} value={option.id}
								dangerouslySetInnerHTML={{ __html: arrowFirst + tabSpace + option.label}}></option>
						})
					}
					{optgroups && optgroups.length > 0 &&
						optgroups.map((optgroup, index) => {
							//The optgroup is the parent of the options that belong to that group.
							//As soon as that optgroup changes, write the end optgroup tag and start the next one.
							let tabSpace = "&nbsp;&nbsp;&nbsp;&nbsp;";
							return (
								<optgroup key={index} label={optgroup.label}>
									{optgroup.options && optgroup.options.length > 0 && optgroup.options.map((opt, optIndex) =>
										<option key={optIndex + 9999} value={opt.id}
										        dangerouslySetInnerHTML={{__html: tabSpace + opt.label}}></option>
									)};
								</optgroup>
							)
						})
					}
				</select>
			</div>
			{error && <div className={styles.alertMessage}>{error}</div>}
		</div>
	)
};
