import React, {useState, useEffect} from 'react'
import styles from './GroupMemberAdd.module.css'
import classes from 'classnames'
import SelectSingleDropDown from '../../components/SelectSingleDropDown'
import MessageModal from '../../components/MessageModal'
import ButtonWithIcon from '../ButtonWithIcon/ButtonWithIcon'
import { useNavigate } from 'react-router'
import { formatPhoneNumber } from '../../utils/numberFormat.js';

function GroupMemberAdd(props) {
	const navigate = useNavigate()
	const { bulkDelimiterOptions, fieldOptions, groupOptions, fromGroupId } = props

	const [isBulkEntered, setIsBulkEntered] = useState(false)
	const [members, setMembers] = useState([]) //This is an array of objects whether it is fed by the single entry of the bulk paste
	const [duplicateEntries, setDuplicateEntries] = useState([])
	const [invalidPhoneOrEmail, setInvalidPhoneOrEmail] = useState([])
	const [isShowingNoBulkEntryMessage, setIsShowingNoBulkEntryMessage] = useState(false)
	const [chosenGroup, setChosenGroup] = useState('')
	const [bulk, setBulk] = useState({
		delimiter: 'tab',
		firstField: 'firstName',
		secondField: 'lastName',
		thirdField: 'emailAddress',
		fourthField: '',
		fifthField: '',
		memberData: '', //This should always be text for the textarea.  Never an array of objects.
	})

	useEffect(() => {
		setChosenGroup(fromGroupId)
	}, [fromGroupId])

	useEffect(() => {
		setBulk({
			delimiter: 'tab',
			firstField: 'firstName',
			secondField: 'lastName',
			thirdField: 'emailAddress',
			fourthField: '',
			fifthField: '',
			memberData: '', //This should always be text for the textarea.  Never an array of objects.
		})
	}, [])

	const returnToBulkEntry = () => {
		setIsBulkEntered(false)
	}

	const goToBulkVerification = () => {
		let newMembers = []
		let lines = bulk && !!bulk.memberData && bulk.memberData.split('\n')
		let splitCharacter = bulk.delimiter === "comma" ? ',' : bulk.delimiter === "semicolon" ? ";" : bulk.delimiter === "hyphen" ? '-' : bulk.delimiter === "tab" ? '\t' : ','

		if (!lines) {
			handleNoBulkEntryMessageOpen();
			return;
		}

		lines.forEach(line => {
			let checkBlank = line.replace(/<[^>]*>/g, ' ')
				.replace(/\s{2,}/g, ' ')
				.replace(/&nbsp;/g, ' ')
				.trim();

			if (!!checkBlank) {
				let col = line.split(splitCharacter)
				let m = {};
				if (bulk.firstField) {
					if (bulk.firstField === 'firstName') {
						m.firstName = col[0].trim();
					} else if (bulk.firstField === 'lastName') {
						m.lastName = col[0].trim();
					} else if (bulk.firstField === 'fullNameLastFirst') {
						if (col[0].indexOf(",") > -1) {
							let name = col[0].split(",");
							m.lastName = name[0].trim();
							m.firstName = name[1].trim();
						} else {
							m.lastName = col[0].substring(col[0].indexOf(" "));
							m.firstName = col[0].substring(col[0].lastIndexOf(" "))
						}
					} else if (bulk.firstField === 'fullNameFirstFirst') {
						let name = col[0].split(" ")
						m.firstName = name[0].trim()
						m.lastName = name[name.length - 1].trim()
					} else if (bulk.firstField === 'memberId') {
						m.memberId = col[0].trim()
					} else if (bulk.firstField === 'emailAddress') {
						m.emailAddress = col[0].trim()
					} else if (bulk.firstField === 'phone') {
						m.phone = formatPhoneNumber(col[0].trim())
					}
				}
				if (bulk.secondField) {
					if (bulk.secondField === 'firstName') {
						m.secondName = col[1].trim()
					} else if (bulk.secondField === 'lastName') {
						m.lastName = col[1].trim()
					} else if (bulk.secondField === 'fullNameLastFirst') {
						if (col[0].indexOf(",") > -1) {
							let name = col[0].split(",")
							m.lastName = name[0].trim()
							m.firstName = name[1].trim()
						} else {
							m.lastName = col[0].substring(col[0].indexOf(" ")).trim()
							m.firstName = col[0].substring(col[0].lastIndexOf(" ")).trim()
						}
					} else if (bulk.secondField === 'fullNameFirstFirst') {
						let name = col[0].split(" ")
						m.firstName = name[0].trim()
						m.lastName = name[name.length - 1].trim()
					} else if (bulk.secondField === 'memberId') {
						m.memberId = col[1].trim()
					} else if (bulk.secondField === 'emailAddress') {
						m.emailAddress = col[1].trim()
					} else if (bulk.secondField === 'phone') {
						m.phone = formatPhoneNumber(col[1].trim())
					}
				}
				if (bulk.thirdField) {
					if (bulk.thirdField === 'firstName') {
						m.thirdName = col[2].trim()
					} else if (bulk.thirdField === 'lastName') {
						m.lastName = col[2].trim()
					} else if (bulk.thirdField === 'fullNameLastFirst') {
						if (col[0].indexOf(",") > -1) {
							let name = col[0].split(",")
							m.lastName = name[0].trim()
							m.firstName = name[1].trim()
						} else {
							m.lastName = col[0].substring(col[0].indexOf(" ")).trim()
							m.firstName = col[0].substring(col[0].lastIndexOf(" ")).trim()
						}
					} else if (bulk.thirdField === 'fullNameFirstFirst') {
						let name = col[0].split(" ")
						m.firstName = name[0].trim()
						m.lastName = name[name.length - 1]
					} else if (bulk.thirdField === 'memberId') {
						m.memberId = col[2].trim()
					} else if (bulk.thirdField === 'emailAddress') {
						m.emailAddress = col[2].trim()
					} else if (bulk.thirdField === 'phone') {
						m.phone = formatPhoneNumber(col[2].trim())
					}
				}
				if (bulk.fourthField) {
					if (bulk.fourthField === 'firstName') {
						m.fourthName = col[3].trim()
					} else if (bulk.fourthField === 'lastName') {
						m.lastName = col[3].trim()
					} else if (bulk.fourthField === 'fullNameLastFirst') {
						if (col[0].indexOf(",") > -1) {
							let name = col[0].split(",")
							m.lastName = name[0].trim()
							m.firstName = name[1].trim()
						} else {
							m.lastName = col[0].substring(col[0].indexOf(" "))
							m.firstName = col[0].substring(col[0].lastIndexOf(" "))
						}
					} else if (bulk.fourthField === 'fullNameFirstFirst') {
						let name = col[0].split(" ")
						m.firstName = name[0].trim()
						m.lastName = name[name.length - 1].trim()
					} else if (bulk.fourthField === 'memberId') {
						m.memberId = col[3].trim()
					} else if (bulk.fourthField === 'emailAddress') {
						m.emailAddress = col[3].trim()
					} else if (bulk.fourthField === 'phone') {
						m.phone = formatPhoneNumber(col[3].trim())
					}
				}
				if (bulk.fifthField) {
					if (bulk.fifthField === 'firstName') {
						m.fifthName = col[4].trim()
					} else if (bulk.fifthField === 'lastName') {
						m.lastName = col[4].trim()
					} else if (bulk.fifthField === 'fullNameLastFirst') {
						if (col[0].indexOf(",") > -1) {
							let name = col[0].split(",")
							m.lastName = name[0].trim()
							m.firstName = name[1].trim()
						} else {
							m.lastName = col[0].substring(col[0].indexOf(" "))
							m.firstName = col[0].substring(col[0].lastIndexOf(" "))
						}
					} else if (bulk.fifthField === 'fullNameFirstFirst') {
						let name = col[0].split(" ")
						m.firstName = name[0].trim()
						m.lastName = name[name.length - 1].trim()
					} else if (bulk.fifthField === 'memberId') {
						m.memberId = col[4].trim()
					} else if (bulk.fifthField === 'emailAddress') {
						m.emailAddress = col[4].trim()
					} else if (bulk.fifthField === 'phone') {
						m.phone = formatPhoneNumber(col[4].trim())
					}
				}
				newMembers = newMembers ? newMembers.concat(m) : [m]
			}
		})

		newMembers = stripOutDuplicates(newMembers)
		newMembers = stripOutInvalidPhoneOrEmail(newMembers)
		newMembers = newMembers.reduce((acc, m) => {
			if (!!m.firstName || !!m.lastName || !!m.emailAddress || !!m.phone) {
				acc = acc ? acc.concat(m) : [m]
			}
			return acc
		}, [])
		setIsBulkEntered(true)
		setMembers(newMembers)
	}

	const stripOutDuplicates = (newMembers) => {
		const {pendingGroupInvites, group} = props
		let newDuplicateEntries = []
		let minusMembers = Object.assign([], newMembers)

		newMembers && newMembers.length > 0 && newMembers.forEach((m, index) => {
			pendingGroupInvites && pendingGroupInvites.length > 0 && pendingGroupInvites.forEach(p => {
				if (m.firstName === p.fname && m.lastName === p.lname && ((m.emailAddress && m.emailAddress === p.email) || (m.phone && m.phone === p.phone))) {
					let exists = newDuplicateEntries && newDuplicateEntries.length > 0 && newDuplicateEntries.filter(d => d.firstName === m.firstName && d.lastName === m.lastName && (d.emailAddress === m.emailAddress || d.phone === m.phone))[0]
					if (!exists) {
						newDuplicateEntries = newDuplicateEntries ? newDuplicateEntries.concat(m) : [m]
						delete minusMembers[index]
					}
				}
			})
			group && !!group.members && group.members.forEach(p => {
				if (m.firstName === p.fname && m.lastName === p.lname && ((m.emailAddress && m.emailAddress === p.email) || (m.phone && m.phone === p.phone))) {
					let exists = newDuplicateEntries && newDuplicateEntries.length > 0 && newDuplicateEntries.filter(d => d.firstName === m.firstName && d.lastName === m.lastName && (d.emailAddress === m.emailAddress || d.phone === m.phone))[0]
					if (!exists) {
						newDuplicateEntries = newDuplicateEntries ? newDuplicateEntries.concat(m) : [m]
						delete minusMembers[index]
					}
				}
			})
		})

		setDuplicateEntries(newDuplicateEntries)
		return minusMembers
	}

	const stripOutInvalidPhoneOrEmail = (newMembers) => {
		let newInvalidPhoneOrEmail = []
		let minusMembers = Object.assign([], newMembers)

		newMembers && newMembers.length > 0 && newMembers.forEach((m, index) => {
			if ((m.emailAddress && !validateEmail(m.emailAddress)) || (m.emailAddress && !validatePhone(m.phone))) {
				let exists = newInvalidPhoneOrEmail && newInvalidPhoneOrEmail.length > 0 && newInvalidPhoneOrEmail.filter(d => d.firstName === m.firstName && d.lastName === m.lastName && (d.emailAddress === m.emailAddress || d.phone === m.phone))[0]
				if (!exists) {
					newInvalidPhoneOrEmail = newInvalidPhoneOrEmail ? newInvalidPhoneOrEmail.concat(m) : [m]
					delete minusMembers[index];
				}
			}
		});

		setInvalidPhoneOrEmail(newInvalidPhoneOrEmail)
		return minusMembers;
	}

	const handleBulkEntry = (event) => {
		setBulk({...bulk, memberData: event.target.value})
	}

	const changeBulk = (event) => {
		if (bulk) {
			const field = event.target.id
			let newBulk = Object.assign({}, bulk)
			newBulk[field] = event.target.value
			setBulk(newBulk)
		}
	}

	const validateEmail = (email) => {
		let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ //eslint-disable-line
		return re.test(email)
	}

	const validatePhone = (phone) => {
		if (!phone) return true
		return stripPhoneFormatAndPrefix(phone).length === 10 ? true : false
	}

	const stripPhoneFormatAndPrefix = (phone) => {
		phone = phone && phone.replace(/\D+/g, "")
		if (phone && phone.indexOf('1') === 0) { //if 1 is in the first place, get rid of it.
			phone = phone.substring(1)
		}
		return phone
	}

	const processBulk = () => {
		const {setGroupMembers, personId } = props
		setGroupMembers(personId, chosenGroup, members)
		setMembers([])
		//setTimeout(() => getInvitesPending(personId), 500)
		navigate(-1)
	}

	const handleNoBulkEntryMessageOpen = () => setIsShowingNoBulkEntryMessage(true)
	const handleNoBulkEntryMessageClose = () => setIsShowingNoBulkEntryMessage(false)

	return (
		<section className={styles.container}>
			<div className={styles.formLeft}>
				<div className={classes(styles.rowRight)}>
					{isBulkEntered &&
						<span className={styles.cancelButton} onClick={returnToBulkEntry}>
							{`<- Prev`}
						</span>
					}
					{!isBulkEntered &&
						<span className={styles.cancelButton} onClick={() => navigate(-1)}>
							{`Cancel`}
						</span>
					}
					<div className={styles.buttonWidth}>
						<ButtonWithIcon icon={'checkmark0'} 
							onClick={isBulkEntered ? processBulk : goToBulkVerification} 
							label={isBulkEntered ? `Finish` : `Next ->`} 
							submitSuccess={false} />
					</div>
				</div>
				{!isBulkEntered &&
					<div>
						<div className={styles.selectList}>
							<SelectSingleDropDown
								id={`groupList`}
								label={'Assign to Group?'}
								value={chosenGroup || ''}
								options={groupOptions}
								height={`medium-short`}
								onChange={(event) => setChosenGroup(event.target.value)} />
						</div>
						<SelectSingleDropDown
							id={`delimiter`}
							label={`How are the fields separated?`}
							value={bulk.delimiter}
							options={bulkDelimiterOptions}
							height={`medium`}
							onChange={changeBulk}/>
						<SelectSingleDropDown
							id={`firstField`}
							label={`First field`}
							value={bulk.firstField}
							options={fieldOptions}
							height={`medium`}
							onChange={changeBulk}/>
						<SelectSingleDropDown
							id={`secondField`}
							label={`Second field`}
							value={bulk.secondField}
							options={fieldOptions}
							height={`medium`}
							onChange={changeBulk}/>
						<SelectSingleDropDown
							id={`thirdField`}
							label={`Third field`}
							value={bulk.thirdField}
							options={fieldOptions}
							height={`medium`}
							onChange={changeBulk}/>
						<SelectSingleDropDown
							id={`fourthField`}
							label={`Fourth field`}
							value={bulk.fourthField}
							options={fieldOptions}
							height={`medium`}
							onChange={changeBulk}/>
						<SelectSingleDropDown
							id={`fifthField`}
							label={`Fifth field`}
							value={bulk.fifthField}
							options={fieldOptions}
							height={`medium`}
							onChange={changeBulk}/>
						<span className={styles.labelBulk}>{`Paste in member data in bulk (one member per line)`}</span>
						<textarea rows={25} cols={100} value={bulk.memberData} onChange={(event) => handleBulkEntry(event)}
						          className={styles.bulkBox}></textarea>
					</div>
				}
				{isBulkEntered &&
					<div>
						{duplicateEntries &&
							<div className={styles.displayTable}>
								<div className={duplicateEntries.length > 0 ? styles.warningText : styles.validText}>{`You have ${duplicateEntries.length} duplicate ${duplicateEntries.length === 1 ? 'entry' : 'entries'}`}</div>
								{!duplicateEntries.length ? '' :
									<table className={styles.tableStyle}>
										<thead>
										<tr>
											<td className={styles.hdr}>First</td>
											<td className={styles.hdr}>Last</td>
											<td className={styles.hdr}>Member id</td>
											<td className={styles.hdr}>Email address</td>
											<td className={styles.hdr}>Phone</td>
										</tr>
										</thead>
										<tbody>
										{duplicateEntries && duplicateEntries.length > 0 && duplicateEntries.map((m, i) =>
											<tr key={i}>
												<td>
													<span className={styles.txtRed}>{m.firstName}</span>
												</td>
												<td>
													<span className={styles.txtRed}>{m.lastName}</span>
												</td>
												<td>
													<span className={styles.txtRed}>{m.memberId}</span>
												</td>
												<td>
													<span className={styles.txtRed}>{m.emailAddress}</span>
												</td>
												<td>
													<span className={styles.txtRed}>{m.phone}</span>
												</td>
											</tr>
										)}
										</tbody>
									</table>
								}
							</div>
						}
						{invalidPhoneOrEmail &&
							<div className={styles.displayTable}>
								<div className={styles.warningText}>{`You have ${invalidPhoneOrEmail.length} invalid phone or email address`}</div>
								{!invalidPhoneOrEmail.length ? '' :
									<table className={styles.tableStyle}>
										<thead>
										<tr>
											<td className={styles.hdr}>First</td>
											<td className={styles.hdr}>Last</td>
											<td className={styles.hdr}>Member id</td>
											<td className={styles.hdr}>Email address</td>
											<td className={styles.hdr}>Phone</td>
										</tr>
										</thead>
										<tbody>
										{invalidPhoneOrEmail && invalidPhoneOrEmail.length > 0 && invalidPhoneOrEmail.map((m, i) =>
											<tr key={i}>
												<td>
													<span className={styles.txtRed}>{m.firstName}</span>
												</td>
												<td>
													<span className={styles.txtRed}>{m.lastName}</span>
												</td>
												<td>
													<span className={styles.txtRed}>{m.memberId}</span>
												</td>
												<td>
													<span className={styles.txtRed}>{m.emailAddress}</span>
												</td>
												<td>
													<span className={styles.txtRed}>{m.phone}</span>
												</td>
											</tr>
										)}
										</tbody>
									</table>
								}
							</div>
						}
						<div className={members && members.length > 0 ? styles.headerText : styles.headerTextRed}>{`${members.length === 0 ? 'No' : members.length === 1 ? '1 entry' : members.length + ' entries'} will be assigned to this group`}</div>
						{members && members.length > 0 &&
							<table className={styles.tableStyle}>
								<thead>
								<tr>
									<td className={styles.hdr}>First</td>
									<td className={styles.hdr}>Last</td>
									<td className={styles.hdr}>Member id</td>
									<td className={styles.hdr}>Email address</td>
									<td className={styles.hdr}>Phone</td>
								</tr>
								</thead>
								<tbody>
								{members && members.length > 0 && members.map((m, i) =>
									<tr key={i}>
										<td>
											<span className={styles.txt}>{m.firstName}</span>
										</td>
										<td>
											<span className={styles.txt}>{m.lastName}</span>
										</td>
										<td>
											<span className={styles.txt}>{m.memberId}</span>
										</td>
										<td>
											<span className={styles.txt}>{m.emailAddress}</span>
										</td>
										<td>
											<span className={styles.txt}>{m.phone}</span>
										</td>
									</tr>
								)}
								</tbody>
							</table>
						}
						<div className={styles.bottomSpace}></div>
					</div>
				}
			</div>
      <MessageModal show={isShowingNoBulkEntryMessage} handleClose={handleNoBulkEntryMessageClose} heading={`No entries found`}
                    explain={`It doesn't appear that there are any group members entered in the bulk entry box below.`}
                    isConfirmType={false}
                    onClick={handleNoBulkEntryMessageClose}/>
		</section>
	);
}

export default GroupMemberAdd;
