import React, {useState} from 'react';
import styles from './InputText.module.css';
import classes from 'classnames';
import MessageModal from '../MessageModal';
import Required from '../Required';

function InputText(props) {
	const {
		name,
		label,
		placeholder,
		value,
		defaultValue,
		error,
		isPasswordType = false,
		size,
		height,
		maxLength = 500,
		inputClassName,
    labelClassName,
    instructionClassName,
		onEnterKey,
		noShadow,
		instructions,
		instructionsBelow,
		required = false,
		whenFilled,
		autoFocus,
		onBlur,
		autoComplete,
		onDoubleClick = () => {},
		disabled
	} = props;

	const [isShowingModal_numberOnly, setIsShowingModal_numberOnly] = useState(false);
	const [isShowingModal_greaterThan, setIsShowingModal_greaterThan] = useState(false);

	const isNumbersOnly = (event) => {
		const {numberOnly, maxNumber, onChange} = props;
		if (event.target.value === 'x') {
			onChange(event);
		} else if (isNaN(event.target.value) && numberOnly) {
			handleNumberOnlyOpen();
		} else if (maxNumber && Number(event.target.value) > Number(maxNumber)) {
			handleGreaterThanMaxOpen();
		} else {
			onChange(event);
		}
	}

	const handleNumberOnlyOpen = () => setIsShowingModal_numberOnly(true)
	const handleNumberOnlyClose = () => setIsShowingModal_numberOnly(false)

	const handleGreaterThanMaxOpen = () => setIsShowingModal_greaterThan(true)
	const handleGreaterThanMaxClose = () => setIsShowingModal_greaterThan(false)

	return (
		<div className={classes(styles.container, inputClassName)}>
			<div className={styles.row}>
				{label && <span htmlFor={name} className={classes(required ? styles.requiredLabelText : styles.labelText, labelClassName)}>{label}</span>}
				<Required setIf={required} setWhen={whenFilled}/>
			</div>
			<div className={instructionsBelow ? styles.column : styles.row}>
				<input
					onChange={isNumbersOnly}
					onKeyUp={onEnterKey}
					type={isPasswordType ? `password` : `text`}
					id={name}
					name={name}
					disabled={disabled}
					autoFocus={autoFocus}
					placeholder={placeholder}
					onBlur={onBlur}
					onDoubleClick={onDoubleClick}
					maxLength={maxLength || 75}
					style={{opacity: disabled ? '.6' : '1'}}
					className={classes(disabled ? styles.lowOpacity : '', styles[`size${height}`], noShadow ? styles.noShadow : '', 
						size === `medium-left` ? styles.cutRight : '',
						size === `medium-right` ? styles.cutLeft : '',
						size === `long` ? styles.input_long : size === `medium` ? styles.input_medium : (size === `medium-short` || size === `medium-left` || size === `medium-right`) ? styles.input_mediumShort : size === `medium-long` ? styles.input_mediumLong : size === `super-short` ? styles.input_superShort : styles.input_short)}
					value={value}
					defaultValue={defaultValue}
					autoComplete={autoComplete || 'nope'}/>
				<span className={classes(styles.instructions, instructionClassName)}>{instructions}</span>
			</div>
			{error && <div className={styles.alertMessage}>{error}</div>}
      <MessageModal show={isShowingModal_numberOnly} handleClose={handleNumberOnlyClose} heading={`Numbers Only`}
                    explain={`Please enter numbers only.`} onClick={handleNumberOnlyClose}/>
      <MessageModal show={isShowingModal_greaterThan} handleClose={handleGreaterThanMaxClose} heading={`Number is Over the Limit`}
                    explain={`The number you entered is greater than the maximum allowed.`}
                    onClick={handleGreaterThanMaxClose}/>
		</div>
	);
}

export default InputText;