import React, { useState, useEffect, useRef } from 'react';
import styles from './MenuDocumentPopup.module.css';
import Target from '../../assets/Inline Icons/move-target.svg'
import { useMediaQuery } from 'react-responsive';

function MenuDocumentPopup(props) {
	const { children, isMoveWorkId, mineOrOthers, moveWorkToFolder, thisWorkFolderId, onClick=()=>{}, work } = props;
	const isMobile = useMediaQuery({ query: '(max-width: 900px)' })
	const [opened, setOpened] = useState(false);
	const containerRef = useRef(null);


	useEffect(() => {
		const handleClickOutside = (event) => {
			let isMessage = false
			let element = event.target
			let loop = 0
			while (element && loop < 10 && !isMessage) {
				isMessage = element && element.classList && Array.from(element.classList).some(className => className.includes('ButtonWithIcon'))
				element = element.nextSibling
				loop++
			}
			if (containerRef.current && (!containerRef.current.contains(event.target) || isMessage)) {
				setTimeout(() => setOpened(false), 300)
			}
		}
		const handleKeyDown = (event) => {
			if (event.key === 'Escape' && containerRef.current) { // ESC key
				setTimeout(() => setOpened(false), 300)
			}
		};
		document.addEventListener('mousedown', handleClickOutside);
		document.addEventListener('keydown', handleKeyDown);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
			document.removeEventListener('keydown', handleKeyDown);
		}
	}, [opened]);

	const handleSetOpened = (event) => {
		//event.stopPropagation()
		// event.preventDefault()
		setOpened(!opened)
	}

	return (
		<div className={styles.container} ref={containerRef} onClick={() => onClick(work)}>
			<div className={styles.row}>
				<div className={opened ? isMobile ? styles.showMobile : styles.show : styles.hide}>
					{children}
				</div>
				{mineOrOthers === 'Mine' && isMoveWorkId &&
					<a onClick={() => moveWorkToFolder('end', thisWorkFolderId)} className={styles.menuItem} title={'Move document to this folder'}>
						<img src={Target} alt={'Target'} height={19} width={20} />
					</a>
				}
				<div className={styles.ellipses} onClick={(event) => handleSetOpened(event)}>
					...
				</div>
			</div>
		</div>
	)
}

export default MenuDocumentPopup;