import React, {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import EditReviewView from '../views/EditReviewView/EditReviewView.js'
import {connect} from 'react-redux'
import * as actionWorks from '../actions/works.js'
import * as actionWorkEditReview from '../actions/work-edit-review.js'
import * as actionChapter from '../actions/chapters.js'
import * as actionEditReview from '../actions/edit-review.js'
import * as actionPersonConfig from '../actions/person-config.js'
import * as actionTranslatedSentence from '../actions/translated-sentence'
import * as fromBookmarks from '../reducers/bookmarks.js'
import * as actionBookmarks from '../actions/bookmarks.js'
import * as actionGroups from '../actions/groups.js';
import * as fromDraftComparison from '../reducers/draft-comparison.js'
import {doSort} from '../utils/sort.js'
import {useMediaQuery} from 'react-responsive'
import downloadOptions from '../data/downloadOptions'
import fontSizeOptions from '../data/fontSizeOptions'
import fontNameOptions from '../data/fontNameOptions'
import { guidEmpty } from '../utils/GuidValidate.js'
import * as editorService from '../services/editor-dom.js'

import {
  selectMe,
  selectWorkEditReview,
  selectLeftSidePanelOpen,
  selectPersonConfig,
  selectColorsEditor,
  selectWorkSegments,
  selectEditSegments,
  selectChapterListLevels,
  selectListLevelGeneral,
  selectEditSegmentHistory,
  selectTranslatedSentence,
} from '../store.js'

const mapStateToProps = (state) => {
  const personId = selectMe(state).personId
  const editorName = {
    firstName: selectMe(state).fname,
    lastName: selectMe(state).lname
  }
  let workSummary = selectWorkEditReview(state)
  const isAuthor = workSummary && workSummary.authorPersonId === personId

  //Draft comparison is only for the author.  But we'll send in the data objects since the data will be blank from the database and it won't be a burden
  const isDraftView = fromDraftComparison.selectIsDraftView(state.draftComparison) || false
  const editorColors = selectColorsEditor(state)
  const draftComparison = fromDraftComparison.selectDraftComparisons(state.draftComparison)
  // const tabsData = isDraftView
  //     ? fromDraftComparison.selectDraftTabs(state.draftComparison)
  //     : fromEditReview.selectEditorTabs(state.editReview, personId, workSummary, 0, selectMe(state).fname, editorColors) //personId
  const edits = selectEditSegments(state)
  let count = 0
  let tabsData = workSummary && workSummary.editsPendingEditors && workSummary.editsPendingEditors.length > 0
    && workSummary.editsPendingEditors.filter(m => m.editorPersonId !== personId).reduce((acc, m) => {  
    let editCount = edits && edits.length > 0 && edits.filter(e => e.personId === m.editorPersonId)
    let label = m.editorFirstName
    if (m.editorLastName && m.editorLastName.length > 0) label += ' ' + m.editorLastName.substring(0, 1)
    let option = {
      id: m.editorPersonId,
      label,
      count: (editCount && editCount.length) || 0,
      editorColor: editorColors && editorColors.length > 0 && editorColors[count].hexColor,
      editorName: {
        firstName: m.editorFirstName,
        lastName: m.editorLastName,
      },
      languageId: m.languageId,
      languageName: m.languageName
    }
    count++
    return acc && acc.length > 0 ? acc.concat(option) : [option]
  }, [])
  tabsData = doSort(tabsData, {sortField: 'count', isAsc: false, isNumber: true})

  const editLanguageOptions = tabsData && tabsData.length > 0 && tabsData.reduce((acc, m) => {
    let exists = acc && acc.length > 0 && acc.filter(f => f.id === m.languageId)[0]
    if (!exists && !!m.languageId) {
      let option = {
        id: m.languageId,
        label: m.languageName
      }
      return acc && acc.length > 0 ? acc.concat(option) : [option]
    }
  }, [])

  const setEditLabel = (edit) => {
    if (edit.type === 'TEXT') {
      return 'Sentence edit'
    } else if (edit.type === 'MOVE') {
      return 'Move sentences'
    } else if (edit.type === 'ADDPARAGRAPH') {
      return 'Add paragraph break'
    } else if (edit.type === 'DELETEPARAGRAPH') {
      return 'Delete paragraph break'
    } else if (edit.type === 'ADDPARAGRAPHSENTENCE') {
      return 'Add paragraph and sentence'
    } else if (edit.type === 'ADDSENTENCE') {
      return 'Add sentence'
    } else if (edit.type === 'DELETESENTENCE') {
      return 'Delete sentence'
    } else if (edit.type === 'ADDLIST') {
      return 'Add list'
    } else if (edit.type === 'ADDLISTITEM') {
      return 'Add list item'
    } else if (edit.type === 'DELETELISTITEM') {
      return 'Delete list item'
    } else if (edit.type === 'REORDERLISTITEMS') {
      return 'Re-order list items'
    } else if (edit.type === 'ADDTAB') {
      return 'Add tab'
    } else if (edit.type === 'DELETETAB') {
      return 'Delete tab'
    } else if (edit.type === 'LISTLEVELMINUS') {
      return 'Move list item left'
    } else if (edit.type === 'LISTLEVELPLUS') {
      return 'Move list item right'
    }
  }

  count = 1
  let editOptions = edits && edits.length > 0 && edits.reduce((acc, m) => {
    const avoidDuplicate = acc && acc.length > 0 && acc.filter(d => d.id === m.elementId && d.label.indexOf(setEditLabel(m)) > -1)[0]
    if (!avoidDuplicate) {
      let option = {
        id: m.elementId,
        label: count++ + ' ' + setEditLabel(m)
      }
      return acc = acc && acc.length > 0 ? acc.concat(option) : [option]
    } else {
      return acc
    }
  }, [])

  const authorTab = {
    id: workSummary && workSummary.authorPersonId,
    label: workSummary && workSummary.firstName,
    count: 'Author',
    editorColor: 'white',
  }

  if (!editorService.isMobile()) {
    if (tabsData && tabsData.length > 0) {
      tabsData.unshift(authorTab)
    } else {
      tabsData = [authorTab]
    }
  }

  return {
    //isLMSTransfer: props.params && props.params.isLMSTransfer === 'LMStransfer',
    personId,
    authorPersonId: workSummary && workSummary.authorPersonId,
    thisEditorColor: 'cc3300', //orange
    segments: selectWorkSegments(state),
    editSegmentHistory: selectEditSegmentHistory(state),
    chapterListLevels: selectChapterListLevels(state),
    listLevelGeneral: selectListLevelGeneral(state),
    editOptions,
    edits,
    isAuthor,
    fontNameOptions,
    fontSizeOptions,
    downloadOptions,
    editorName,
    draftComparison,
    isDraftView,
    workSummary,
    chapterId: workSummary ? workSummary.chapterId_current && workSummary.chapterId_current !== guidEmpty ? workSummary.chapterId_current : workSummary.chapterOptions && workSummary.chapterOptions.length > 0 && workSummary.chapterOptions[0].chapterId : guidEmpty,
    tabsData,
    translatedSentence: selectTranslatedSentence(state),
    leftSidePanelOpen: selectLeftSidePanelOpen(state),
    personConfig: selectPersonConfig(state),
    bookmarkOptions: fromBookmarks.selectBookmarkOptions(state.bookmarks),
    bookmarks: fromBookmarks.selectBookmarks(state.bookmarks),
    //textProcessingProgress: selectTextProcessingProgress(state),
    editorColors,
  }
}

const bindActionsToDispatch = dispatch => ({
  getWorkSegments: (personId, workId, chapterId, languageId) => dispatch(actionEditReview.getWorkSegments(personId, workId, chapterId, languageId)),
  getEditSegments: (personId, workId, chapterId, languageId) => dispatch(actionEditReview.getEditSegments(personId, workId, chapterId, languageId)),
  restoreEditSegment: (editSegmentId, languageId) => dispatch(actionEditReview.restoreEditSegment(editSegmentId, languageId)),
  responseEdit: (edit, responseType, comment, listItemElementIds, runFunction, addListItemNextParentElementId) => dispatch(actionEditReview.responseEdit(edit, responseType, comment, listItemElementIds, runFunction, addListItemNextParentElementId)),
  addOrUpdateSegments: (personId, segments, runFunction) => dispatch(actionEditReview.addOrUpdateSegments(personId, segments, runFunction)),
  addOrUpdateEdit: (edit, runFunction) => dispatch(actionEditReview.addOrUpdateEdit(edit, runFunction)),
  addOrUpdateCommentOrSentence: (personId, comment, workId, chapterId, elementId, languageId, isAuthor, editSegmentId, editType, newSentence) => dispatch(actionEditReview.addOrUpdateCommentOrSentence(personId, comment, workId, chapterId, elementId, languageId, isAuthor, editSegmentId, editType, newSentence)),
  // setDraftTabSelected: (draftComparisonId) => dispatch(actionDraftComparison.setDraftTabSelected(draftComparisonId)),
  // toggleDraftView: () => dispatch(actionDraftComparison.toggleDraftView()),
  // setBookmarks: (personId, chapterId, languageId) => dispatch(actionBookmarks.init(personId, chapterId, languageId)),
  saveNewBookmark: (personId, chapterId, languageId, hrefId, name) => dispatch(actionBookmarks.saveNewBookmark(personId, chapterId, languageId, hrefId, name)),
  // setDraftComparison: (personId, workId, chapterId, languageId) => dispatch(actionDraftComparison.init(personId, workId, chapterId, languageId)),
  // deleteBookmark: (personId, chapterId, languageId, hrefId) => dispatch(actionBookmarks.deleteBookmark(personId, chapterId, languageId, hrefId)),
  setWorkCurrentSelected: (personId, workId, chapterId, languageId, goToPage) => dispatch(actionWorks.setWorkCurrentSelected(personId, workId, chapterId, languageId, goToPage)),
  // setPenspringHomeworkSubmitted: (personId, workId) => dispatch(actionWorks.setPenspringHomeworkSubmitted(personId, workId)),
  // setPenspringDistributeSubmitted: (personId, workId) => dispatch(actionWorks.setPenspringDistributeSubmitted(personId, workId)),
  // saveLastVisitedHrefId: (personId, workId, chapterId, languageId, prevHrefId) => dispatch(actionWorks.saveLastVisitedHrefId(personId, workId, chapterId, languageId, prevHrefId)),
   getTranslation: (personId, workId, languageId, elementId, chapterId, translateText)  => dispatch(actionTranslatedSentence.getTranslation(personId, workId, languageId, elementId, chapterId, translateText)),
  // clearTranslation: () => dispatch(actionTranslatedSentence.clearTranslation()),
  updatePersonConfig: (personId, field, value) => dispatch(actionPersonConfig.updatePersonConfig(personId, field, value)),
  getPersonConfig: (personId) => dispatch(actionPersonConfig.init(personId)),
  //updatePersonConfigNotPersist: (personId)  => dispatch(actionPersonConfig.updatePersonConfigNotPersist(personId)),
  deleteWork: (personId, workId) => dispatch(actionWorks.deleteWork(personId, workId)),
  getWorkEditReview: (personId, workId) => dispatch(actionWorkEditReview.getWorkEditReview(personId, workId)),
  //  part of the score function that isn't working due to the cursor location management or EditorDiv.
  //setGradebookScoreByPenspring: (personId, studentAssignmentResponseId, score) => dispatch(actionGradebook.setGradebookScoreByPenspring(personId, studentAssignmentResponseId, score)),
  addChapterListLevels: (chapterListLevels, chapterId) => dispatch(actionWorks.addChapterListLevels(chapterListLevels, chapterId)),
  getChapterListLevels: (workId, chapterId) => dispatch(actionWorks.getChapterListLevels(workId, chapterId)),
  getListLevelGeneral: () => dispatch(actionWorks.getListLevelGeneral()),
  downloadWork: (wordConversion) => dispatch(actionWorks.downloadWork(wordConversion)),
  deleteFile: (personId, workId, conversionType, languageId) => dispatch(actionWorks.deleteFile(personId, workId, conversionType, languageId)),
  getEditSegmentHistory: (personId, chapterId, elementId, languageId, runFunction) => dispatch(actionEditReview.getEditSegmentHistory(personId, chapterId, elementId, languageId, runFunction)),
  toggleGroupJoinLink: (personId, groupId, runFunction) => dispatch(actionGroups.toggleGroupJoinLink(personId, groupId, runFunction)),
  acceptAllAddParagraphSentence: (editsAddParagraphSentence, runFunction) => dispatch(actionEditReview.acceptAllAddParagraphSentence(editsAddParagraphSentence, runFunction)),
  deleteAllAddParagraphSentence: (editsAddParagraphSentence, runFunction) => dispatch(actionEditReview.deleteAllAddParagraphSentence(editsAddParagraphSentence, runFunction)),
  acceptAllAddListItem: (editsAddListItem, addListItemNextParentElementId, runFunction) => dispatch(actionEditReview.acceptAllAddListItem(editsAddListItem, addListItemNextParentElementId, runFunction)),
})

const storeConnector = connect(
  mapStateToProps,
  bindActionsToDispatch,
)

let hasCalledChapterListLevels = false

function Container(props) {
  const {
    personId,
    getPersonConfig,
    getWorkEditReview,
    getWorkSegments,
    getEditSegments,
    getChapterListLevels,
    getListLevelGeneral,
    workSummary
  } = props

  let {edits} = props

  const params = useParams()
  let {languageId} = useParams()
  // Cut back the tabsData to just the languages that this version of the document is called for as the languageId parameter
  //tabsData = tabsData && tabsData.length > 0 && tabsData.filter(m => m.languageId === Number(languageId))

  const isTranslation = workSummary && workSummary.languageId_current !== workSummary.languageId
    ? { 
        languageId: workSummary && workSummary.languageId_current, 
        languageName: workSummary && workSummary.languageName_current 
      }
    : ''

    //languageId was coming through as 0.
    //the languageId is used to change from the native language to a translation language.
  languageId = languageId && languageId !== "undefined" ? languageId : workSummary && workSummary.languageId_current

  useEffect(() => {
    if (personId && params.workId) {
      getListLevelGeneral()
      getPersonConfig(personId)
      getWorkSegments(personId, params.workId, params.chapterId, languageId)  
      getEditSegments(personId, params.workId, params.chapterId, languageId)
      getWorkEditReview(personId, params.workId)
      if (!hasCalledChapterListLevels) {
        getChapterListLevels(params.workId)
        hasCalledChapterListLevels = true
      }
    }
    //params && params.isdraft && setDraftComparison(personId, workSummary.workId, workSummary.chapterId_current, languageId) //If draftview is set on, then this will automatically set the view parameter in the draftComparison store.  There is a toggle function to turn it off.
    //setBookmarks(personId, workSummary.chapterId_current, languageId) //WE NEED THE WORKID HERE AND NOT JUST THE CHAPTERiD_CURRENT!
  }, [personId, params.workId, params.chapterId, languageId])

  return <EditReviewView {...props} {...params} editLanguageId={languageId} edits={edits} isTranslation={isTranslation} chapterId={params.chapterId} />
}

export default storeConnector(Container)
