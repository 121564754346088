
import React, {useEffect} from 'react';
import EditorInviteGoogleContactsView from '../views/EditorInviteGoogleContactsView/EditorInviteGoogleContactsView.js';
import { connect } from 'react-redux';
import * as actionContacts from '../actions/contacts.js';
import * as actionPersonConfig from '../actions/person-config.js';
import * as actionGroups from '../actions/groups.js';

import { selectMe, selectPersonConfig, selectGroups, selectGroupContactsWorks, selectContactMembers, selectGroupByName } from '../store.js';
import { useParams } from 'react-router';

// takes values from the redux store and maps them to props
const mapStateToProps = state => {
    let me = selectMe(state);
    const groups = selectGroups(state)

    const groupOptions = groups && groups.length > 0 && groups.reduce((acc, m) => {
      let exists = m.groupId && acc && acc.length > 0 && acc.filter(f => f.id === m.groupId)[0]
      if (!exists && !!m.groupId) {
        let option = {
          id: m.groupId,
          label: m.groupName
        }
        return acc && acc.length > 0 ? acc.concat(option) : [option]
      }
    }, [])

    return {
      group: selectGroupContactsWorks(state),
      personId: me.personId,
      owner_personId: me.personId,
      personConfig: selectPersonConfig(state),
      groupOptions,
      contactMembers: selectContactMembers(state),
      groupByName: selectGroupByName(state)
    }
}

const bindActionsToDispatch = dispatch => ({
  updatePersonConfig: (personId, field, value)  => dispatch(actionPersonConfig.updatePersonConfig(personId, field, value)),
  getGroups: (personId) => dispatch(actionGroups.getGroups(personId)),
  getGroupByName: (personId, groupName) => dispatch(actionGroups.getGroupByName(personId, groupName)),
  setContactCurrentSelected: (personId, contactPersonId, href) => dispatch(actionContacts.setContactCurrentSelected(personId, contactPersonId, href)),
  assignContactToGroup: (personId, contact_personId, groupId, firstName, lastName, photo, emailAddress, includeIndividualContact, isDelete) => dispatch(actionGroups.assignContactToGroup(personId, contact_personId, groupId, firstName, lastName, photo, emailAddress, includeIndividualContact, isDelete)),
  setGroupMembers: (personId, groupId, members) => dispatch(actionGroups.setGroupMembers(personId, groupId, members)),
  getGroupContactsWorks: (personId, groupId) => dispatch(actionGroups.getGroupContactsWorks(personId, groupId)),
  checkContactMembers: (emailAddresses) => dispatch(actionContacts.checkContactMembers(emailAddresses)),
  addOrUpdateGroup: (groupData, sendTo) => dispatch(actionGroups.addOrUpdateGroup(groupData, sendTo)),
});

const storeConnector = connect(
  mapStateToProps,
  bindActionsToDispatch,
);

function Container(props) {
  const { personId, getGroups, getGroupContactsWorks, getContacts } = props
  const params = useParams()

  useEffect(() => {
    if (personId) {
      {
        getGroups(personId)
        getGroupContactsWorks(personId, params.groupChosen)
      }
    }
  },[personId])

  return <EditorInviteGoogleContactsView {...props} />
}

export default storeConnector(Container);
