import React, {useEffect} from 'react';
import { useParams } from 'react-router-dom'
import WorkSettingsView from '../views/WorkSettingsView';
import { connect } from 'react-redux';
import * as actionWorkEditReview from '../actions/work-edit-review.js'
import * as actionWorks from '../actions/works.js';
import * as actionChapters from '../actions/chapters.js';
import * as actionContacts from "../actions/contacts";
import * as actionLanguageList from "../actions/language-list";
import * as actionFileTreeSubContents from '../actions/work-file-tree-sub-contents.js'
import {selectMe, selectLanguages, selectWorkTypes, selectGroups, selectWorkEditReview, selectContacts} from '../store.js';

// takes values from the redux store and maps them to props
const mapStateToProps = state => {
  let me = selectMe(state);
  const workSummary = selectWorkEditReview(state)
  const contacts = selectContacts(state)
  let contactOptions = contacts && contacts.length > 0 && contacts.reduce((acc, m) => {
    let option = {
      id: m.personId,
      label: m.firstName + ' ' + m.lastName
    }
    return acc = acc && acc.length > 0 ? acc.concat(option) : [option]
  }, [])

  const workTypes = selectWorkTypes(state)
  let workTypeList = workTypes && workTypes.length > 0 && workTypes.reduce((acc, m) => {
    let option = { id: m.workTypeId, label: m.name, code: m.code }
    return acc = acc && acc.length > 0 ? acc.concat(option) : [option]
  }, [])

  //Fill in the blank numbers for the chapterOptions. 
  //Go for 100 chapters and fill in when the numbers match.
  let chapterOptions = []
  for(let i = 1; i <= 100; i++) {
    //it is possible that the author may have two or more chapters with the same number until the user reorders according to their preference.
    let option = {}
    var existChapters = workSummary?.chapterOptions?.filter(m => m.chapterNbr === i)
    if (existChapters?.length > 0) {
      existChapters?.map(m => {
        option = { ...m,
          id: m.chapterNbr,
          label: m.chapterNbr,
          name: m.name,
        }
        chapterOptions.push(option)
      })

    } else {
      option = {
        id: i,
        label: i
      }
      chapterOptions.push(option)
    }
  }

  return {
    personId: me.personId,
    workSummary,
    chapterOptions,
    workTypeList,
    languageChosen: 1,
    contacts,
    contactOptions,
    languageList: selectLanguages(state),
    groups: selectGroups(state),
  }
};

// binds the result of action creators to redux dispatch, wrapped in callable functions
const bindActionsToDispatch = dispatch => ({
  getWorkEditReview: (personId, workId) => dispatch(actionWorkEditReview.getWorkEditReview(personId, workId)),
  deleteWork: (personId, workId) => dispatch(actionWorks.deleteWork(personId, workId)),
  updateChapterDueDate: (personId, workId, chapterId, languageId, dueDate) => dispatch(actionChapters.updateChapterDueDate(personId, workId, chapterId, languageId, dueDate)),
  updateChapterComment: (personId, workId, chapterId, comment) => dispatch(actionChapters.updateChapterComment(personId, workId, chapterId, comment)),
  addOrUpdateDocument: (workRecord, fileData) => dispatch(actionWorks.addOrUpdateDocument(workRecord, fileData)),
  updateWorkMetadata: (work) => dispatch(actionWorks.updateWorkMetadata(work)),
  getContacts: (personId) => dispatch(actionContacts.getContacts(personId)),
  getWorkTypes: () => dispatch(actionWorks.getWorkTypes()),
  getLanguages: () => dispatch(actionLanguageList.getLanguages()),
  getMyWorks: (personId) => dispatch(actionFileTreeSubContents.getMyWorks(personId)),
});

const mergeAllProps = (store, actions) => ({
    ...store, ...actions,
});

const storeConnector = connect(
  mapStateToProps,
  bindActionsToDispatch,
  mergeAllProps
);

function Container(props) {
  const { personId, getWorkEditReview, getWorkTypes, getContacts, getLanguages } = props
  const params = useParams()

  useEffect(() => {
    getWorkEditReview(personId, params.workId)
    getWorkTypes()
    getContacts(personId)
    getLanguages()
  }, [personId])

  return <WorkSettingsView {...props} />;
}

export default storeConnector(Container);
