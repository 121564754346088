import React from 'react';
import styles from './EditorFullTextView.module.css';
import classes from "classnames";
import TabPage from '../TabPage'

function EditorFullTextView(props) {
  const { edits, tabsData, handleEditorTabChosen, tabNav, navText, chosenTab, handleKeyDOWNEditor, handleMouseUp } = props
    return (
      <div className={styles.container}>
        <div className={styles.toolSection}>
          {!(tabsData && tabsData.length > 0) &&
            <div>
              <div className={styles.fullViewHeader}>{`Editors' versions`}</div>
              <div className={classes(styles.fullViewHeader, styles.noEdits)}>
                {!(edits && edits.length > 0) ? 'No edits to display' : ''}
              </div>
            </div>
          }
          <div className={classes(styles.tabPage, styles.row)}>
            <TabPage tabsData={tabsData}
                     onClick={handleEditorTabChosen}
                     navClose={tabNav}
                     navText={navText}
                     chosenTab={chosenTab}
                     showZeroCount={true}
                     showListAfterQuantity={6}/>
          </div>
        </div>
        <div>
          <div className={styles.editorDivRight}>
            <div className={styles.tabInstructions}>Changes cannot be made in this view</div>
            <div id="tabView" contentEditable={true}
              spellCheck={'true'}
              //  className={styles.tabViewPosition}
              className={styles.editorDiv}
              onKeyDown={handleKeyDOWNEditor}
              onMouseUp={(event) => handleMouseUp(event, false)}/>
          </div>
        </div>
      </div>
    )
}

export default EditorFullTextView