 const downloadOptions = [
  {
    value: 'Docx',
    span: <span style={{fontSize: '12px'}}>Word &reg;</span>,
    title: 'Word',
  },
  {
    value: "Html",
    span: <span style={{fontSize: '12px'}}>Html</span>,
    title: 'Html',
  },
  {
    value: "Text",
    span: <span style={{fontSize: '12px'}}>Text</span>,
    title: 'Text',
  },
  {
    value: "Odt",
    span: <span style={{fontSize: '12px'}}>Open Office (Odt)</span>,
    title: 'Open Office (Odt)',
  },
  {
    value: "Bmp",
    span: <span style={{fontSize: '12px'}}>Bmp</span>,
    title: 'Bmp',
  },
  {
    value: "Doc",
    span: <span style={{fontSize: '12px'}}>Doc</span>,
    title: 'Doc',
  },
  {
    value: "Epub",
    span: <span style={{fontSize: '12px'}}>Epub</span>,
    title: 'Epub',
  },
  {
    value: "Jpeg",
    span: <span style={{fontSize: '12px'}}>Jpeg</span>,
    title: 'Jpeg',
  },
  {
    value: "Markdown",
    span: <span style={{fontSize: '12px'}}>Markdown</span>,
    title: 'Markdown',
  },
  {
    value: "Mhtml",
    span: <span style={{fontSize: '12px'}}>Mhtml</span>,
    title: 'Mhtml',
  },
  {
    value: "Mobi",
    span: <span style={{fontSize: '12px'}}>Mobi</span>,
    title: 'Mobi',
  },
  {
    value: "OpenXps",
    span: <span style={{fontSize: '12px'}}>OpenXps</span>,
    title: 'OpenXps',
  },
  {
    value: "Pdf",
    span: <span style={{fontSize: '12px'}}>Pdf</span>,
    title: 'Pdf',
  },
  {
    value: "Png",
    span: <span style={{fontSize: '12px'}}>Png</span>,
    title: 'Png',
  },
  {
    value: "Rtf",
    span: <span style={{fontSize: '12px'}}>Rtf</span>,
    title: 'Rtf',
  },
  {
    value: "Tiff",
    span: <span style={{fontSize: '12px'}}>Tiff</span>,
    title: 'Tiff',
  },
  {
    value: "WordML",
    span: <span style={{fontSize: '12px'}}>WordML</span>,
    title: 'WordML',
  },
  {
    value: "Xps",
    span: <span style={{fontSize: '12px'}}>Xps</span>,
    title: 'Xps',
  },
]

export default downloadOptions