import * as types from '../actions/actionTypes';

export default function (state = {}, action) {
	switch (action.type) {
		case types.GROUP_CONTACTS_WORKS_INIT: {
			return action.payload;
		}

    case types.GROUP_CONTACTS_UPDATE: {
      const {contactPersonId, firstName, lastName, photo, emailAddress, isDelete} = action.payload
      let newState = Object.assign({}, state)
      let newContacts = Object.assign([], newState.contacts)
      if (isDelete) {
        newContacts = newContacts && newContacts.length > 0 && newContacts.filter(m => m.personId !== contactPersonId)
      } else {
        let newContact = {
          firstName,
          lastName,
          photo,
          emailAddress,
          personId: contactPersonId,
        }
        newContacts = newContacts && newContacts.length > 0 ? newContacts.concat(newContact) : [newContact]
      }
      newState.contacts = newContacts
      return newState;
    }

    default:
			return state;
	}
}

export const selectGroupContactsWorks = (state) => state;
