import React, { useState, useEffect } from 'react'
import styles from './FileUploadDropzone.module.css'
import {useDropzone} from 'react-dropzone'
import moveTarget from '../../assets/Inline Icons/move-target.svg'

function FileUploadDropzone(props) {
	const { preLabel, label, subLabel, fileTypes, targetIcon } = props

	const [acceptFileTypes, setAcceptFileTypes] = useState({
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
		'text/plain': ['.txt'],
		'application/pdf': ['.pdf'],
		'application/vnd.oasis.opendocument.text': ['.odt'],
		'text/html': ['.html'],
		'text/css': ['.css'],
		'text/javascript': ['.js'],
		'text/javascript': ['.jsx'],
		'text/x': ['.py'],
		'text/x': ['.java'],
		'text/plain': ['.cs'],
		'text/x': ['.c'],
		'text/x': ['.cpp'],
		'application/x': ['.php'],
		'text/x': ['.rb'],
		'text/x': ['.swift'],
		'text/typescript': ['.ts'],
		'text/typescript': ['.tsx'],
		'application/xml': ['.xml'],
		'application/json': ['.json'],
		'application/sql': ['.sql'],
		'text/markdown': ['.md'],
	})

	useEffect(() => {
		if (fileTypes) {
			setAcceptFileTypes(fileTypes)
		}
	}, [fileTypes])

	const {
		acceptedFiles,
		fileRejections,
		getRootProps,
		getInputProps
	} = useDropzone({
		maxFiles: 1,
		accept: acceptFileTypes})

	const acceptedFileItems = acceptedFiles.map((file, index) => {
		if (index === 0) props.setFile(file)
		return (
			<li key={file.path}>
				{file.path} - {file.size} bytes
			</li>
		)
	})

	const fileRejectionItems = fileRejections.map(({ file, errors  }) => {
		//props.setFile(null)
		return (
			<li key={file.path}>
				{file.path} - {file.size} bytes
				<ul>
					{errors.map(e => <li key={e.code}>{e.message}</li>)}
				</ul>
			</li>
		)
	})

	return (
		<section className={styles.container}>
      <div {...getRootProps({ className: styles.dropzone })} className={styles.row}>
        <div className={styles.imageMoveTarget}>
					{targetIcon ? targetIcon  : <img src={moveTarget} width={22}/>}
        </div>
        <div>
          <input {...getInputProps()} />
          {preLabel && <div className={styles.preLabel}>{preLabel}</div>}
          <div className={styles.fitLabel}>{label || 'Click here to select a local file (or drag & drop)'} <div className={styles.allowed}>{subLabel || '(Only 1 file is allowed)  Microsoft&reg; Word, HTML, ...'}</div></div>
        </div>
      </div>
			<div>
				{/*<h4>Accepted file</h4>*/}
				<div className={styles.chosenFile}>{acceptedFileItems}</div>
				{fileRejectionItems && fileRejectionItems.length > 0 && <h4>Rejected files</h4>}
				{/* <div>{fileRejectionItems}</div> */}
			</div>
		</section>
	)
}

export default FileUploadDropzone