import React, {useEffect} from 'react';
import { useParams, useNavigate } from 'react-router-dom'
import MyProfileView from '../views/MyProfileView';
import MyWorksView from '../views/MyWorksView';
import LoginView from '../views/LoginView';
import { connect } from 'react-redux';
import * as actionEditorInvite from '../actions/editor-invite-response';
import * as actionMyProfile from '../actions/my-profile.js';
import * as actionGroups from '../actions/groups.js';
import * as loginUser from "../actions/login";
import {selectMe, selectMyProfile, selectEditorInviteIsActive, selectUsernameToVerify, selectFriendInvitation} from '../store.js';
import jwtDecode from 'jwt-decode'

const mapStateToProps = (state, props) => {
	let me = selectMe(state);

	return {
		personId: me.personId,
		langCode: me.langCode,
		userProfile: selectMyProfile(state),
		isActiveInvite: selectEditorInviteIsActive(state),
		loginData: selectMe(state),
		usernameToVerify: selectUsernameToVerify(state),
		friendInvitation: selectFriendInvitation(state),
	}
};

const bindActionsToDispatch = dispatch => ({
	setEditorInviteGUIDResponse: (inviteCode, firstName, lastName, emailAddress, createNew) => dispatch(actionEditorInvite.setEditorInviteGUIDResponse(inviteCode, firstName, lastName, emailAddress, createNew)),
	getMyProfile: (personId) => dispatch(actionMyProfile.getMyProfile(personId)),
	setMyProfileNew: (user, isNew) => dispatch(actionMyProfile.setMyProfileNew(user, isNew)),
	updateMyProfile: (user) => dispatch(actionMyProfile.updateMyProfile(user)),
	//removeProfilePicture: (personId, profilePictureId) => dispatch(actionMyProfile.removeProfilePicture(personId, profilePictureId)),
	getEditorInviteDecline: (inviteCode) => dispatch(actionEditorInvite.getEditorInviteDecline(inviteCode)),
	verifyUsername: (personId, username) => dispatch(actionEditorInvite.verifyUsername(personId, username)),
	isEditorInviteActive: (inviteCode) => dispatch(actionEditorInvite.isEditorInviteActive(inviteCode)),
	login: (userData, inviteResponse, salta, sendto, authLogin) => dispatch(loginUser.login(userData, inviteResponse, salta, sendto, authLogin)),
	logout: ()  => dispatch(loginUser.logout()),
	getInviteFromShortCode: (inviteCodeShort) => dispatch(actionEditorInvite.getInviteFromShortCode(inviteCodeShort)),
	addUserToGroupInvite: (inviteCodeShort) => dispatch(actionGroups.addUserToGroupInvite(inviteCodeShort)),
	
});

const storeConnector = connect(
	mapStateToProps,
	bindActionsToDispatch,
);

function HomeContainer(props) {
	const { personId, getMyProfile, setEditorInviteGUIDResponse, isEditorInviteActive, isActiveInvite, getInviteFromShortCode, friendInvitation, addUserToGroupInvite } = props;
	const params = useParams()
	const navigate = useNavigate()
	let storage

	const { exp } = jwtDecode(window.localStorage.getItem('authToken'));
	const isValidToken = new Date().getTime() < (exp * 1000);

	useEffect(() => {
		if (friendInvitation && friendInvitation.friendInvitationId && params.createNew !== 'reset') {
			if (friendInvitation.friendInvitationId) setEditorInviteGUIDResponse(friendInvitation.friendInvitationId, friendInvitation.fname, friendInvitation.lname, friendInvitation.email, params.createNew)
			personId && getMyProfile(personId)
			isEditorInviteActive(friendInvitation.friendInvitationId)
			if (window) storage = window.localStorage.getItem('loglevel') //workFileTreeExplorer
		}
		getInviteFromShortCode(params.inviteCodeShort)
	}, [friendInvitation])

	useEffect(() => {
		//If the user is logged in, then let the user be a part of the group and go to the MyWorksView page
		if (isValidToken && isActiveInvite && params.createNew === 'grp') {
			addUserToGroupInvite(params.inviteCodeShort) //The personId will be picked up on the server side.
			setTimeout(() => navigate('/myWorks'), 500)
		}
	}, [isValidToken, isActiveInvite])

	if (window && !storage) storage = window.localStorage.getItem('workFileTreeExplorer')


	return (!(friendInvitation && friendInvitation.friendInvitationId) || (!isActiveInvite && params.createNew !== 'reset'))
		? null
		: isActiveInvite === 'VALID' || params.createNew === 'reset'
			? params.createNew.indexOf('existing') > -1 || !!storage
				? <LoginView {...props} {...params}/>
				: <MyProfileView {...props} {...params}/>
			: <div style={{marginLeft: '100px', marginTop: '40px', alignItems: 'center'}}>
						This is an invalid or expired invitation<br/><br/>
						Go to<br/> <a href={'/'} style={{color: 'blue', cursor: 'pointer', textDecoration: 'none'}}>the penspring landing page</a>
				</div>
}

export default storeConnector(HomeContainer);
