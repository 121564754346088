import * as types from './actionTypes'
import {init as fromChaptersInit} from './chapters.js'
import * as workFileTreeSubContents from './work-file-tree-sub-contents.js'
import {guidEmpty} from '../utils/GuidValidate.js'
import {apiHost} from '../api_host.js'

export const init = (personId, includeAssignmentWorkId = guidEmpty) => {
  //ToDo the languageId needs to be sent in here for editReview.
	return dispatch => {
		//dispatch({type: types.FETCHING_RECORD, payload: {works: true} })
		return fetch(`${apiHost}ebi/works/${personId}/${includeAssignmentWorkId}`, {
			method: 'get',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json()
				} else {
					const error = new Error(response.statusText)
					error.response = response
					throw error
				}
			})
			.then(response => {
				dispatch({type: types.WORKS_INIT, payload: response})
				dispatch({type: types.FETCHING_RECORD, payload: {works: false}})
				if (includeAssignmentWorkId && includeAssignmentWorkId !== guidEmpty) {
					dispatch({type: types.WORK_CURRENT_SET_SELECTED, payload: {workId: includeAssignmentWorkId}})
					window.location = `/editReview/${includeAssignmentWorkId}`
          //ToDo the languageId needs to be sent in here for editReview.
				}
			})
		.catch(error => { console.error(`request failed but it is probably because this user doesn't have any records.`, error) })
	}
}

export const getWorkSummary = (personId, workId) => {
	return dispatch => {
		return fetch(`${apiHost}ebi/work/summary/${personId}/${workId}`, {
			method: 'get',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json()
				} else {
					const error = new Error(response.statusText)
					error.response = response
					throw error
				}
			})
			.then(response => {
				dispatch({type: types.WORK_SUMMARY_INIT, payload: response})
			})
		//.catch(error => { console.l og('request failed', error) })
	}
}


export const setWorks = (works) => {
	return {type: types.WORKS_INIT, payload: works}
}

export const setWorkIdCurrent = (workId_current) => {
	return {type: types.WORK_CURRENT_SET_SELECTED, payload: {workId: workId_current}}
}


export const initWorkIdCurrent = (personId) => {
	return dispatch => {
		let storage = localStorage.getItem("recentWork")
		if (storage) {
			let response = JSON.parse(storage)
			dispatch(setWorkIdCurrent(response.workId))
			dispatch({type: types.CHAPTER_CURRENT_SET_SELECTED, payload: response.chapterId})
			dispatch({type: types.LANGUAGE_CURRENT_SET_SELECTED, payload: response.languageId})
			dispatch(fromChaptersInit(personId, response.workId))
		}

		return fetch(`${apiHost}ebi/recentWork/` + personId, {
			method: 'get',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json()
				} else {
					const error = new Error(response.statusText)
					error.response = response
					throw error
				}
			})
			.then(response => {
				dispatch(setWorkIdCurrent(response.workId))
				dispatch({type: types.CHAPTER_CURRENT_SET_SELECTED, payload: response.chapterId})
				dispatch({type: types.LANGUAGE_CURRENT_SET_SELECTED, payload: response.languageId})
				dispatch(fromChaptersInit(personId, response.workId))
				localStorage.setItem("recentWork", JSON.stringify(response))
			})
		//.catch(error => { console.l og('request failed', error) })
	}
}

export const setWorkCurrentSelected = (personId, workId, chapterId, languageId, goToPage) => {
	chapterId = !chapterId ? guidEmpty : chapterId
	languageId = !languageId ? 0 : languageId

	return dispatch => {
		//The return will tell us that the chapterId was changed and will send us the chapterText back.
		fetch(`${apiHost}ebi/recentWork`, {
			method: 'put',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
			body: JSON.stringify({
				personId,
				workId,
				chapterId,
				languageId,
			}),
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json()
				} else {
					const error = new Error(response.statusText)
					error.response = response
					throw error
				}
			})
			.then(response => {
				//Since we want the freedom to send in a recentWork without specifying a chapterId or languageId, let's get the recentWork
				//  back as the response and set those values accordingly.
				//let languageName = response.name
				dispatch({type: types.WORK_CURRENT_SET_SELECTED,
					payload: {
						workId: response.workId,
						chapterId_current: response.chapterId,
						chapterId: response.chapterId,
						languageId: response.languageId
					}
				})
				if (response.isChapterChange) {
					dispatch({type: types.CHAPTER_TEXT_INIT, payload: response.chapterText})
					dispatch({type: types.CHAPTER_CHANGED, payload: true})
				}
				if (goToPage && goToPage !== "STAY") {
					window.location = goToPage
				}
			})
	}
}

//I believe that this has been replaced by a direct axios call in the WorkAddView page.
//Then the replacement for just the document metadata is called "updateWorkMetadata"
export const addOrUpdateDocument = (work, fileData, sendToPage) => {
	//The only difference here between a new or updated document is whether an existing workId is present.
  const workOwners = work.workOwners.join(',')
	return dispatch => {
		fetch(`${apiHost}ebi/work/${work.personId}/${work.workId || guidEmpty}/${work.workTypeId}/${work.workName}/${work.languageId || 1}/${work.description || ' '}/${work.dueDate || ' '}/${work.activeDate || ' '}/${work.internalId || ' '}/${work.workFolderId || guidEmpty}/${work.groupId || guidEmpty}/${workOwners}`, {
			method: 'post',
			headers: {
				'Accept': 'multipart/form-data',
				'Content-Type': 'multipart/form-data',
				'Access-Control-Allow-Credentials': 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
			body: JSON.stringify(fileData),
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json()
				} else {
					const error = new Error(response.statusText)
					error.response = response
					throw error
				}
			})
			.then(response => {
				//dispatch({type: work.workId ? types.WORK_NEW_UPDATE : types.WORK_NEW_ADD, payload: response})
				//dispatch(workFileTreeSubContents.getMyWorks(work.personId))
				window.location = `/editReview/${response.workId}/${response.languageId_current}`
				//if (sendToPage) window.location = sendToPage
			})
	}
}

export const updateWorkMetadata = (work) => {
	work.workOwners = work.workOwners || []
	if (work.dueDate && work.dueDate.indexOf('T') > -1) work.dueDate = work.dueDate.substring(0, work.dueDate.indexOf('T'))
	if (work.activeDate && work.activeDate.indexOf('T') > -1) work.activeDate = work.activeDate.substring(0, work.activeDate.indexOf('T'))
	return dispatch => {
		fetch(`${apiHost}ebi/work/update/metadata`, {
			method: 'post',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
			body: JSON.stringify(work),
		})
	}
}

export const deleteWork = (personId, workId) => {
	return dispatch => {
		dispatch({type: types.WORK_DELETE, payload: workId})
		fetch(`${apiHost}ebi/work/delete/${personId}/${workId}`, {
			method: 'get',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json()
				} else {
					const error = new Error(response.statusText)
					error.response = response
					throw error
				}
			})
			.then(response => {
				//dispatch(fromGroups.init(personId))
				//dispatch(init(personId)) //This is this Works actions page's init function.
				//dispatch(initWorkIdCurrent(personId))
				dispatch(workFileTreeSubContents.getMyWorks(personId))
			})
	}
	//.catch(error => { console.l og('request failed', error) })
}

export const deleteWorkFromFolder = (mineOrOthers, personId, levelType, id) => { //If levelType 'work' then id is workId; if levelType 'folder' then id is workFolderId
	return dispatch => {
		dispatch({ type: types.WORK_FILE_DELETE, payload: { mineOrOthers, personId, levelType, id } })
		fetch(`${apiHost}ebi/work/deleteFromFolder/${mineOrOthers}/${personId}/${levelType}/${id}`, {
			method: 'get',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json()
				} else {
					const error = new Error(response.statusText)
					error.response = response
					throw error
				}
			})
			.then(() => {
				dispatch(workFileTreeSubContents.getMyWorks(personId))
			})
	}
	//.catch(error => { console.l og('request failed', error) })
}

export const downloadWork = (wordConversion) => {
	return dispatch =>
		fetch(`${apiHost}ebi/work/download`, {
			method: 'post',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
			body: JSON.stringify(wordConversion),
		})
}

export const deleteFile = (personId, workId, conversionType, languageId=1) => {
  return dispatch =>
    fetch(`${apiHost}ebi/work/delete/file/${personId}/${workId}/${languageId}/${conversionType}`, {
      method: 'get',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
        "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
        "Authorization": "Bearer " + localStorage.getItem("authToken"),
      }
    })
}


export const updateGroupWorkStatus = (personId, groupId, workId, groupWorkStatusName) => {
	return dispatch =>
		fetch(`${apiHost}ebi/work/groupStatus/update/` + personId + `/` + groupId + `/` + workId + `/` + groupWorkStatusName, {
			method: 'get',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json()
				} else {
					const error = new Error(response.statusText)
					error.response = response
					throw error
				}
			})
			.then(response => {
				dispatch({type: types.GROUP_EDIT_REPORT_INIT, payload: response})
			})
}

export const addOrUpdateFolder = (folderRecord) => {
	return dispatch => {
		fetch(`${apiHost}ebi/fileFolder/` + folderRecord.personId, {
			method: 'post',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
			body: JSON.stringify(folderRecord),
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json()
				} else {
					const error = new Error(response.statusText)
					error.response = response
					throw error
				}
			})
			.then(response => {
				dispatch(workFileTreeSubContents.getMyWorks(folderRecord.personId))
			})
	}
}


export const setPenspringHomeworkSubmitted = (personId, workId) => {
	return dispatch => {
		fetch(`${apiHost}ebi/work/set/penspringSubmitted/` + personId + `/` + workId, {
			method: 'get',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
		})
			.then(response => {
				dispatch({type: types.WORK_PENSPRING_SUBMITTED, payload: workId})
			})
	}
}

export const setPenspringDistributeSubmitted = (personId, workId) => {
	return dispatch => {
		fetch(`${apiHost}ebi/work/set/penspringDistribute/` + personId + `/` + workId, {
			method: 'get',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
		})
			.then(response => {
				dispatch({type: types.WORK_PENSPRING_DISTRIBUTED, payload: workId})
			})
	}
}


export const getWorkTypes = () => {
	return dispatch => {
		let storage = localStorage.getItem("workTypes");
		storage && dispatch && dispatch({type: types.WORK_TYPES_INIT, payload: JSON.parse(storage)});

		return fetch(`${apiHost}ebi/work/types`, {
			method: 'get',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json()
				} else {
					const error = new Error(response.statusText)
					error.response = response
					throw error
				}
			})
			.then(response => {
				dispatch({type: types.WORK_TYPES_INIT, payload: response})
				localStorage.setItem("workTypes", JSON.stringify(response));
			})
	}
}

export const getChapterListLevels = (workId, chapterId=guidEmpty) => {
	return dispatch => {
		return fetch(`${apiHost}ebi/chapter/listLevels/${workId}/${chapterId}`, {
			method: 'get',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json()
				} else {
					const error = new Error(response.statusText)
					error.response = response
					throw error
				}
			})
			.then(response => {
				dispatch({type: types.CHAPTER_LIST_LEVELS_INIT, payload: response})
			})
	}
}

export const addChapterListLevels = (chapterListLevels, chapterId) => {
	return dispatch => {
		fetch(`${apiHost}ebi/chapter/listLevels/${chapterId}`, {
			method: 'post',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
			body: JSON.stringify(chapterListLevels),
		})
	}
}

export const getListLevelGeneral = () => {
	return dispatch => {
		return fetch(`${apiHost}ebi/listLevels/general`, {
			method: 'get',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json()
				} else {
					const error = new Error(response.statusText)
					error.response = response
					throw error
				}
			})
			.then(response => {
				dispatch({type: types.LIST_LEVELS_INIT, payload: response})
			})
	}
}

export const getTextImageUploads = (textImageGroupId) => {
	return dispatch => {
		return fetch(`${apiHost}ebi/textImages/get/${textImageGroupId}`, {
			method: 'get',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json()
				} else {
					const error = new Error(response.statusText)
					error.response = response
					throw error
				}
			})
			.then(response => {
				dispatch({ type: types.TEXT_IMAGE_UPLOADS_INIT, payload: response })
			})
	}
}

export const getTextImageGroups = () => {
	return dispatch => {
		return fetch(`${apiHost}ebi/textImages/groups`, {
			method: 'get',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json()
				} else {
					const error = new Error(response.statusText)
					error.response = response
					throw error
				}
			})
			.then(response => {
				dispatch({ type: types.TEXT_IMAGE_GROUPS_INIT, payload: response })
			})
	}
}



export const setTextImageUpload = (textImageGroupId, { convertedText, confidence, name }) => {
	textImageGroupId = textImageGroupId && textImageGroupId  !== guidEmpty ? textImageGroupId : guidEmpty
	return dispatch => {
		return fetch(`${apiHost}ebi/textImage/set/${textImageGroupId}`, {
			method: 'post',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
			body: JSON.stringify({
				name,
				convertedText,
				confidence
			}),
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json()
				} else {
					const error = new Error(response.statusText)
					error.response = response
					throw error
				}
			})
			.then(response => {
				//The response looks like:
				// public class ReturnTextImageGroupVM {
				// 		public Guid TextImageGroupId { get; set; }
        //    public List < TextImageGroup > TextImageGroups { get; set; }
        // }

				dispatch({ type: types.TEXT_IMAGE_GROUPS_INIT, payload: response.textImageGroups })
				dispatch(getTextImageUploads(response.textImageGroupId))
			})
	}
}

export const removeTextImageUpload = (textImageUploadId) => {
	textImageUploadId = textImageUploadId && textImageUploadId !== guidEmpty ? textImageUploadId : guidEmpty
	return dispatch => {
		return fetch(`${apiHost}ebi/textImages/remove/${textImageUploadId}`, {
			method: 'get',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json()
				} else {
					const error = new Error(response.statusText)
					error.response = response
					throw error
				}
			})
			.then(response => {
				dispatch({ type: types.TEXT_IMAGE_UPLOADS_INIT, payload: response })
			})
	}
}


export const updateSequenceTextImageUpload = (textImageUploadId, sequence) => {
	textImageUploadId = textImageUploadId && textImageUploadId !== guidEmpty ? textImageUploadId : guidEmpty
	return dispatch => {
		return fetch(`${apiHost}ebi/textImages/sequence/${textImageUploadId}/${sequence}`, {
			method: 'get',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json()
				} else {
					const error = new Error(response.statusText)
					error.response = response
					throw error
				}
			})
			.then(response => {
				dispatch({ type: types.TEXT_IMAGE_UPLOADS_INIT, payload: response })
			})
	}
}

export const updateNameTextImageUpload = (textImageUploadId, name) => {
	textImageUploadId = textImageUploadId && textImageUploadId !== guidEmpty ? textImageUploadId : guidEmpty
	return dispatch => {
		return fetch(`${apiHost}ebi/textImages/name/${textImageUploadId}/${name}`, {
			method: 'get',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json()
				} else {
					const error = new Error(response.statusText)
					error.response = response
					throw error
				}
			})
			.then(response => {
				dispatch({ type: types.TEXT_IMAGE_UPLOADS_INIT, payload: response })
			})
	}
}
