import React from 'react';
import styles from './LogoSlogan.module.css';
import Logo from "../../assets/logos/penspring_reg.png";
import Slogan from "../../assets/logos/Penspring_slogan_reg.png";
import {Link} from 'react-router-dom';

export default ({larger}) => {
    return (
      <Link to={'/'} className={styles.columnCenter}>
        <img src={Logo} alt={`penspring`} className={larger ? styles.logoLarger : styles.logo} />
        <img src={Slogan} className={larger ? styles.sloganLarger : styles.slogan} alt={`get an edge in word-wise`}/>
      </Link>
    )
};
