import React, {useState} from 'react';
import {useNavigate} from "react-router";
import styles from './MenuGroupRibbon.module.css';
import MessageModal from '../MessageModal';
// import TextareaModal from '../../components/TextareaModal';
import classes from 'classnames';
import Icon from '../Icon';
// import Target from "../../assets/Inline Icons/move-target.svg";
// import MoveGroup from "../../assets/Inline Icons/move-end-white.svg";
import {guidEmpty} from '../../utils/GuidValidate'

function MenuGroupRibbon(props) {
  const {
    className = "",
    mineOrOthers,
    setChosenGroup,
  } = props;
  const navigate = useNavigate();
  //const hasChosenGroup = hasChosenGroupInFolder(guidEmpty)

  const [isShowingModal_delete, setIsShowingModal_delete] = useState(false);
  const [isShowingModal_chooseGroup, setIsShowingModal_chooseGroup] = useState(false);
  const [isShowingModal_notSubmittedYet, setIsShowingModal_notSubmittedYet] = useState(false);
  const [isShowingModal_noAccess, setIsShowingModal_noAccess] = useState(false);

  const handleDeleteOpen = () => setIsShowingModal_delete(true)
  const handleDeleteClose = () => {
    setIsShowingModal_delete(false)
  }
  const handleDelete = () => {
    const {deleteGroup, personId, chosenGroup} = props;
    deleteGroup(personId, chosenGroup.groupId);
    setChosenGroup('')
    handleDeleteClose();
  }

  const handleChooseGroupClose = () => setIsShowingModal_chooseGroup(false)

  const handleNotSubmittedYetClose = () => setIsShowingModal_notSubmittedYet(false)

  const handleNoAccessClose = () => setIsShowingModal_noAccess(false)

  return (
    <div className={classes(styles.container, styles.row, className)}>
      {/*{mineOrOthers === 'Mine' &&*/}
      {/*  <a onClick={() => setEnterFolderTitle(true)} title={'Add new folder'} className={styles.addNewFolderIcon}>*/}
      {/*    <Icon pathName={'folder_plus_inside'} premium={true} superscript={'plus'} supFillColor={'white'} fillColor={'white'}*/}
      {/*          className={classes(styles.image, styles.moreTopMargin)}/>*/}
      {/*  </a>*/}
      {/*}*/}
      {mineOrOthers === 'Mine' &&
        <a onClick={() => navigate(`/groupAddNew`)} title={'Add new group'} className={styles.addNewWorkIcon}>
          <Icon pathName={'users2'} premium={true} superscript={'plus'} supFillColor={'white'} fillColor={'white'}
                className={classes(styles.image, styles.moreTopMargin)} larger/>
        </a>
      }
      {/*{mineOrOthers === 'Mine' &&*/}
      {/*  <a onClick={chosenGroup && chosenGroup.groupId !== guidEmpty ? validateDelete : handleChooseGroupOpen}*/}
      {/*     title={'Delete the chosen group'} className={styles.addNewWorkIcon}>*/}
      {/*    <Icon pathName={'users2'} premium={true} superscript={'minus'}*/}
      {/*          supFillColor={chosenGroup && chosenGroup.groupId !== guidEmpty ? 'white' : ''}*/}
      {/*          className={classes(styles.image, styles.moreTopMargin, chosenGroup && chosenGroup.groupId !== guidEmpty ? '' : styles.opacityLow)}*/}
      {/*          fillColor={chosenGroup && chosenGroup.groupId !== guidEmpty ? 'white' : ''} larger/>*/}
      {/*  </a>*/}
      {/*}*/}
      {/*{mineOrOthers === 'Mine' && isMoveGroupId &&*/}
      {/*  <a onClick={() => moveGroupToFolder('end', guidEmpty)} className={styles.moveTarget} title={'Move group to this folder'}>*/}
      {/*    <img src={Target} alt={'Target'} height={28} width={45}/>*/}
      {/*  </a>*/}
      {/*}*/}
      {/*{mineOrOthers === 'Mine' && !isMoveGroupId &&*/}
      {/*  <a onClick={chosenGroup && chosenGroup.groupId ? () => moveGroupToFolder('start', guidEmpty) : handleChooseGroupOpen} className={styles.moveStart} title={'Move document from this folder'}>*/}
      {/*    <img src={MoveGroup} alt={'Move group'} height={21} width={35} className={hasChosenGroup ? '' : styles.opacityLow}/>*/}
      {/*  </a>*/}
      {/*}*/}
      {/*{mineOrOthers === 'Mine' &&*/}
      {/*  <a onClick={chosenGroup ? validateDelete : handleChooseGroupOpen} title={`Delete this group`}>*/}
      {/*			<Icon pathName={`trash2`} premium={true} className={classes(styles.image, styles.moreTopMargin,*/}
      {/*					(chosenGroup ? '' : styles.opacityLow))}*/}
      {/*			      fillColor={chosenGroup ? 'white' : ''}/>*/}
      {/*  </a>*/}
      {/*}*/}
      {/*  <TextareaModal show={enterFolderTitle} handleClose={() => setEnterFolderTitle(false)} heading={`Enter Folder Title`} onClick={submitAddGroupFolder} />*/}
      <MessageModal show={isShowingModal_delete} handleClose={handleDeleteClose} heading={`Delete this group?`}
                    isConfirmType={true}
                    explain={`Are you sure you want to delete this group?`} onClick={handleDelete}/>
      <MessageModal show={isShowingModal_chooseGroup} handleClose={handleChooseGroupClose} heading={`Choose a group`}
                    explain={`Click on a group name and then the tools will become available for use. And be aware that you cannot delete the default 'My individual contacts' group.`}
                    onClick={handleChooseGroupClose}/>
      <MessageModal show={isShowingModal_notSubmittedYet} handleClose={handleNotSubmittedYetClose}
                    heading={`Homework not yet submitted`}
                    explain={`This homework has not been submitted yet.`} onClick={handleNotSubmittedYetClose}/>
      <MessageModal show={isShowingModal_noAccess} handleClose={handleNoAccessClose}
                    heading={`You do not have access to this action`}
                    explain={`You are not the owner of this file in order to choose this action.`}
                    onClick={handleNoAccessClose}/>
    </div>
  )
}

export default MenuGroupRibbon;