import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom'
import { useNavigate } from 'react-router'
import styles from './WorkSettingsView.module.css';
import globalStyles from '../../utils/globalStyles.module.css';
import OneFJefFooter from '../../components/OneFJefFooter';
import MessageModal from '../../components/MessageModal';
import ButtonWithIcon from '../../components/ButtonWithIcon';
import InputText from '../../components/InputText';
import SelectSingleDropDown from '../../components/SelectSingleDropDown';
import DateTimePicker from '../../components/DateTimePicker';
import Icon from '../../components/Icon'
import SelectBetweenLists from '../../components/SelectBetweenLists'
import { useMediaQuery } from "react-responsive"
import classes from 'classnames'

function WorkSettingsView(props) {
  const {
    personId,
    languageList = [],
    workTypeList = [],
    updateWorkMetadata,
    workSummary,
    chapterOptions,
    getMyWorks
  } = props;


  const params = useParams()
  const navigate = useNavigate()
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' })

  const [isShowingSectionInfo, setIsShowingSectionInfo] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [isShowingChooseEntry, setIsShowingChooseEntry] = useState(false)
  const [work, setWork] = useState({ workTypeId: 1, languageId: 1, internalId: '' })
  const [entryError, setEntryError] = useState('')
  const [moreInfo, setMoreInfo] = useState(false)
  const [showWorkOwners, setShowWorkOwners] = useState(false)
  const [workNameError, setWorkNameError] = useState('')
  const [workTypeIdError, setWorkTypeIdError] = useState('')
  const [languageIdError, setLanguageIdError] = useState('')
  const [chapterOrder, setChapterOrder] = useState([])
  const [errorChapterName, setErrorChapterName] = useState({})
  

  useEffect(() => {
    setWork({
      workId: '',
      personId: '',
      workTypeId: 1,
      workName: '',
      languageId: 1,
      description: '',
      dueDate: '',
      activeDate: '',
      internalId: '',
      workOwners: [],
    })
    return () => setWork({
      workId: '',
      personId: '',
      workTypeId: 1,
      workName: '',
      languageId: 1,
      description: '',
      dueDate: '',
      activeDate: '',
      internalId: '',
      workOwners: [],
    })
  }, [])

  useEffect(() => {
    if (workSummary && workSummary.workId) {
      setWork({
        workId: workSummary.workId,
        personId,
        workTypeId: workSummary.workTypeId || 1,
        workName: workSummary.title,
        languageId: workSummary.nativeLanguageId || 1,
        description: workSummary.description,
        dueDate: workSummary.dueDate && workSummary.dueDate.indexOf('T') > -1 ? workSummary.dueDate.substring(0, workSummary.dueDate.indexOf('T')) : null,
        activeDate: workSummary.activeDate && workSummary.activeDate.indexOf('T') > -1 ? workSummary.activeDate.substring(0, workSummary.activeDate.indexOf('T')) : null,
        internalId: workSummary.internalId,
        workOwners: workSummary.workOwners && workSummary.workOwners.length > 0 && workSummary.workOwners.reduce((acc, m) => acc = acc && acc.length > 0 ? acc.concat(m.personId) : [m.personId], []),
      })
      if (!(workSummary.nativeLanguageId && workSummary.nativeLanguageId == 1)
        || workSummary.description
        || workSummary.dueDate
        || workSummary.activeDate
        || workSummary.internalId) {
        setMoreInfo(true)
      }
      if (workSummary.workOwners && workSummary.workOwners.length > 1) {
        setShowWorkOwners(true)
      }
      setChapterOrder(chapterOptions)
    }
  }, [workSummary])

  const processForm = (event) => {
    event.stopPropagation()
    event.preventDefault()
    const authorTextDiv = document.getElementById('authorText')
    let newEntryError = ''
    if (!work.workName) {
      setWorkNameError("Please enter a document name")
      newEntryError += "Please enter a document name</br>"
    }

    if (!work.workTypeId || work.workTypeId === '0') {
      setWorkTypeIdError("Please choose a work Type")
      newEntryError += "Please choose a work Type</br>"
    }

    if (!work.languageId || work.languageId === '0') {
      setLanguageIdError("Please choose a native text language")
      newEntryError += "Please choose a native text language</br>"
    }

    chapterOrder?.length > 1 && chapterOrder.forEach(m => {
      if (m.chapterId && !m.name) {
        newEntryError += "There is at least one chapter name that is blank</br>"
      }
    })

    if (newEntryError) {
      setEntryError(newEntryError)
    } else {
      setSubmitSuccess(true)
      const chaptersUpdate = chapterOrder?.reduce((acc, m) => {
        if (m.name) { //filter the chapter orders to get rid of all of the blank options for the list of 100.
          let option = {
            chapterId: m.chapterId,
            chapterNbr: m.chapterNbr,
            name: m.name
          }
          acc = acc && acc.length > 0 ? acc.concat(option) : [option]
        }
        return acc 
      }, [])
      let updateWork = { ...work, chaptersUpdate } 
      updateWorkMetadata(updateWork)
      setTimeout(() => getMyWorks(personId), 500)
      setTimeout(() => navigate('/myWorks'), 1000)
    }
  }

  const handleWorkName = (value) => {
    setWork({ ...work, workName: value });
    setWorkNameError('')
  }

  const handleWorkTypeId = (value) => {
    setWork({ ...work, workTypeId: value });
    setWorkTypeIdError('')
  }

  const handleLanguageId = (value) => {
    setWork({ ...work, languageId: value });
    setLanguageIdError('')
  }

  const updateChapterOrder = (event, chapter) => {
    const chapterNbr = event.target.value
    //Get the currentChapter
    let currentChapter = chapterOrder.filter(m => m.chapterId === chapter.chapterId)[0]
    //Cut the currentChapter out of the chapterOrder entirely as well as any of the chapterOptions which don't have a name (empty) so we can rebuild again from the beginning.
    let newChapterOrder = chapterOrder.filter(m => m.name).filter(m => m.chapterId !== chapter.chapterId)
    if (chapterNbr !== chapter.label) {
      //reset the values for the currentChapter
      currentChapter.id = Number(chapterNbr)
      currentChapter.label = Number(chapterNbr)
      currentChapter.chapterNbr = Number(chapterNbr)
      //Add the currentChapter back into the chapterOrder which will require us to reset the chapterOrder consecutively.
      newChapterOrder.push(currentChapter)
      setChapterOrder(resetChapterOrder(newChapterOrder))
    }
  }

  const resetChapterOrder = (newChapterOrder) => {
    let chapterOptions = []
    for (let i = 1; i <= 100; i++) {
      //it is possible that the author may have two or more chapters with the same number until the user reorders according to their preference.
      let option = {}
      var existChapters = newChapterOrder?.filter(m => m.chapterNbr === i)
      if (existChapters?.length > 0) {
        existChapters?.map(m => {
          option = {
            ...m,
            id: m.chapterNbr,
            label: m.chapterNbr,
            name: m.name,
          }
          chapterOptions.push(option)
        })

      } else {
        option = {
          id: i,
          label: i
        }
        chapterOptions.push(option)
      }
    }
    return chapterOptions
  }

  const handleChapterName = (event, chapter) => {
    let newChapterOrder = [...chapterOrder]
    if (event.target.value !== "") {
      let newErrorChapterName = { ...errorChapterName } || {}
      newErrorChapterName[chapter.chapterId] = ""
    }
    newChapterOrder = newChapterOrder?.map(m => {
      if (m.chapterId === chapter.chapterId) {
        m.name = event.target.value
      }
      return m
    })
    setChapterOrder(newChapterOrder)
  }

  const checkBlankName = (event, chapter) => {
    if (event.target.value === "") {
      let newErrorChapterName = {...errorChapterName} || {}
      newErrorChapterName[chapter.chapterId] = "A chapter name is required"
      setErrorChapterName(newErrorChapterName)
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.mainDiv}>
        <div className={globalStyles.pageTitle}>
          Document Settings
        </div>
        <div className={styles.background}>
          <form>
            <div>
              <InputText
                value={work.workName || ''}
                size={"medium-long"}
                name={"workName"}
                label={`Document name`}
                inputClassName={styles.textWhite}
                required={true}
                whenFilled={work.workName}
                error={workNameError}
                onChange={(event) => handleWorkName(event.target.value)} />
            </div>
            <div>
              <SelectSingleDropDown
                label={`Work type`}
                value={work.workTypeId || ''}
                options={workTypeList || []}
                disabled={true}
                height={`high`}
                className={styles.singleDropDownHidden}
                labelClass={styles.textWhite}
                required={true}
                whenFilled={work.workTypeId}
                error={workTypeIdError}
                onChange={(event) => handleWorkTypeId(event.target.value)} />
            </div>
            <div className={styles.rowMoreInfo} onClick={() => setMoreInfo(!moreInfo)}>
              <Icon pathName={'chevron_right'} premium={false} fillColor={'white'} className={moreInfo ? styles.lessInfo : styles.moreInfo} />
              {moreInfo ? 'Less Info' : 'More Info'}
            </div>
            <div className={moreInfo ? styles.showMoreInfo : styles.hide}>
              <div className={styles.description}>
                <span className={styles.labelHigher}>Description (optional)</span>
                <textarea rows={5} cols={42} value={work.description || ''} id={`description`} className={styles.messageBox}
                  onChange={(event) => setWork({ ...work, description: event.target.value })}></textarea>
              </div>
              <div>
                <SelectSingleDropDown
                  label={`Native Text Language`}
                  value={work.languageId || ''}
                  options={languageList || []}
                  height={`medium`}
                  className={styles.singleDropDown}
                  labelClass={styles.textWhite}
                  required={true}
                  whenFilled={work.languageId}
                  error={languageIdError}
                  onChange={(event) => handleLanguageId(event.target.value)} />
              </div>
              <div>
                <InputText
                  value={work.internalId || ''}
                  inputClassName={styles.textWhite}
                  size={"medium"}
                  name={"internalId"}
                  label={"Internal id"}
                  onChange={(event) => setWork({ ...work, internalId: event.target.value })} />
              </div>
              <div>
                <div className={styles.dueDate}>
                  <DateTimePicker label={'Due date'}
                    value={work.dueDate || ''}
                    labelClass={styles.textWhite}
                    onChange={(event) => setWork({ ...work, dueDate: event.target.value })} />
                </div>
                <div className={styles.dueDate}>
                  <DateTimePicker label={'Active date'}
                    value={work.activeDate || ''}
                    labelClass={styles.textWhite}
                    onChange={(event) => setWork({ ...work, activeDate: event.target.value })} />
                </div>
              </div>
            </div>
            <div className={styles.rowMoreInfo} onClick={() => setShowWorkOwners(!showWorkOwners)}>
              <Icon pathName={'chevron_right'} premium={false} fillColor={'white'} className={showWorkOwners ? styles.lessInfo : styles.moreInfo} />
              {showWorkOwners ? 'Hide other document owners' : 'View other document owners'}
            </div>
            <div className={showWorkOwners ? styles.showWorkOwners : styles.hide}>
              <SelectBetweenLists
                leftLabel={'My contacts'}
                rightLabel={'Document owners'}
                onChange={setWork}
                work={work}
                values={work.workOwners}
                options={props.contactOptions}
                labelClass={styles.documentOwners} />

              {workSummary.workOwners && workSummary.workOwners.length > 0 &&
                <div>
                  <div className={styles.ownersLabel}>Current document owners:</div>
                  {workSummary.workOwners.map((m, i) =>
                    <div className={styles.ownersText} key={i}>{m.fname + ' ' + m.lname}</div>
                  )}
                </div>
              }
            </div>
            {chapterOrder?.length > 1 &&
              <div>
                <hr/>
                <div className={isMobile ? styles.rowBetween : styles.row}>
                  <div className={classes(styles.labelChapter, isMobile ? '' : styles.moveRight)}>Chapters</div>
                  <div className={styles.row}>
                    <div className={styles.linkText} onClick={() => navigate(`/workAddNew/filePenspring/${work.workId}`)}>Add a new chapter</div>
                    <Link to={`/workAddNew/filePenspring/${work.workId}`} className={styles.menuItem} data-rh={'Add new document'} title={'Add new document'}
                      tabIndex='-1'>
                      <Icon pathName={'document0'} premium={true} superscript={'plus'} supFillColor={'#fbd56f'} fillColor={'white'} tabIndex='-1' className={styles.addChapterIcon}/>
                    </Link>
                  </div>
                </div>
                {workSummary && (workSummary.chapterOptions?.length > 1 || workSummary.chapterOptions[0].name !== workSummary.workName || workSummary.chapterOptions[0].chapterNbr !== 1) && chapterOrder?.map((m, i) => { //If the user intentionally set the first chapter as a different name or the chapterNbr is not 1, then this was an effort to indicate that this work is going to have multiple chapters if it doesn't already.
                  if (m.chapterId) {
                    return (
                      <div className={styles.row}>
                        <div className={styles.listTop}>
                          <SelectSingleDropDown
                            id={m.label}
                            label={''}
                            noBlank
                            value={m.id}
                            options={chapterOrder}
                            height={`medium-short`}
                            labelClass={styles.textWhite}
                            className={styles.selectList}
                            onChange={(event) => updateChapterOrder(event, m)} />
                        </div>
                        <div className={styles.chapterName}>
                          <InputText
                            key={`chapterName${i}`}
                            size={isMobile ? 'medium-long' : 'long'}
                            name={'name'}
                            label={''}
                            value={m.name || ''}
                            onChange={(event) => handleChapterName(event, m)}
                            onBlur={(event) => checkBlankName(event, m)}
                            required={true}
                            whenFilled={m.name}
                            error={errorChapterName[m.chapterId]} />
                        </div>
                      </div>
                    )
                  }
                })}
              </div>
            }
          </form>
        </div>
        <div className={styles.buttonsCenter}>
          <div className={styles.buttonPosition}>
            <span className={styles.cancelButton} onClick={() => navigate(-1)}>
              Cancel
            </span>
            <ButtonWithIcon label={'Submit'} icon={'checkmark0'} onClick={processForm} submitSuccess={submitSuccess} />
          </div>
        </div>
        <OneFJefFooter />
        <MessageModal displayTempMessage
          show={entryError}
          setEntryError={setEntryError}
          explain={entryError}
          handleClose={() => setEntryError('')}
          heading={`Entry Error!`}
          onClick={() => setEntryError('')} />
      </div>
    </div>
  )
}

export default WorkSettingsView