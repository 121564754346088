import React, {useState} from 'react'
import styles from './MenuDocumentRibbon.module.css'
import TextareaModal from '../../components/TextareaModal'
import classes from 'classnames'
import Icon from '../Icon'
import {guidEmpty} from '../../utils/GuidValidate'

function MenuDocumentRibbon(props) {
	const {className="", personId, mineOrOthers } = props
	const [enterFolderTitle, setEnterFolderTitle] = useState(false)

	const submitAddWorkFolder = (folderTitle) => {
		props.addWorkFolder(personId, guidEmpty, folderTitle, mineOrOthers)
		setEnterFolderTitle(false)
	}

  return (
		<div className={classes(styles.container, styles.row, className)}>
			{mineOrOthers === 'Mine' &&
				<a onClick={() => setEnterFolderTitle(true)} title={'Add new folder'}>
					<Icon pathName={'folder_only'} premium={true} superscript={'plus'} supFillColor={'white'} fillColor={'white'}
								className={classes(styles.image, styles.moreTopMargin)}/>
				</a>
			}
			<TextareaModal show={enterFolderTitle} handleClose={() => setEnterFolderTitle(false)} heading={`Enter Folder Title`} onClick={submitAddWorkFolder} className={styles.centered}/>
		</div>
  )
}

export default MenuDocumentRibbon