import React from 'react';
import styles from './TextDisplay.module.css';
import classes from 'classnames';

export default ({text, label, className, nowrap=false, noDisplayIfBlank}) => {
    return noDisplayIfBlank && !text ? null : (
        <div className={classes(styles.container, className, (nowrap ? styles.nowrap : ''))}>
            <span className={classes(styles.label, (nowrap ? styles.nowrap : ''))}>{label}</span>
            <span className={classes(styles.text, (nowrap ? styles.nowrap : ''))}>{text}</span>
        </div>
    )
}
