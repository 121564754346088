import React, { useState, useEffect } from 'react';
import styles from './style.module.css'
import MobileHeader from '../../components/MobileHeader';
//import IdleTimeOutHandler from '../../utils/IdleTimeOutHandler'
import { AuthProvider } from "../../hooks/auth";
import { Route, Routes } from "react-router";
//import Landing from "../../containers/landing";
import Login from "../../containers/login";
import Logout from "../../containers/logout";
import RequireAuth from "../../components/RequireAuth";
import MyWorks from "../../containers/my-works";
import MyGroups from "../../containers/my-groups";
import EditReview from "../../containers/edit-review";
import NotFound from "../../containers/not-found";

import AccessReport from '../../containers/access-report.js';
import AssignmentDashboard from '../../containers/assignment-dashboard.js';
import ChapterUploadFile from '../../containers/chapter-upload-file.js';
import ContributorReport from '../../containers/contributor-report.js';
import DataDeletionPolicy from '../../containers/data-deletion-policy.js';
import DraftSettings from '../../containers/draft-settings.js';
import DraftReview from '../../containers/draft-review.js';
import EditorInviteNameEmail from '../../containers/editor-invite-name.js';
import EditorInviteOptions from '../../containers/editor-invite-options.js';
import EditorInviteResponse from '../../containers/editor-invite-response.js';
import EditorInviteWorkAssign from '../../containers/editor-invite-work.js';
import EditorInviteContactList from '../../containers/editor-invite-contact-list.js';
import EditorInviteGoogleContacts from '../../containers/editor-invite-google-contacts.js';
import EditorInviteByBulk from '../../containers/editor-invite-by-bulk.js';
import FileFolderAddNew from '../../containers/file-folder-add-update.js';
//import FirstNav from '../../containers/first-nav.js';
import ForgotPassword from '../../containers/password-forgot.js';
import ForgotUsername from '../../containers/username-forgot.js';
import GiveAccessToEditors from '../../containers/give-access-to-editors.js';
import GiveAccessToWorks from '../../containers/give-access-to-works.js';
import GroupAddNew from '../../containers/group-add-new.js';
import GroupChooseExisting from '../../containers/group-choose-existing';
import GroupEditReport from '../../containers/group-edit-report.js';
import GroupMemberUpdate from '../../containers/group-member-update.js';
import GroupSettings from '../../containers/group-settings.js';
import GroupTypeChoice from '../../containers/group-type-choice.js';
import GroupWorkAssign from '../../containers/group-work-assign.js';
import GroupWorks from '../../containers/group-works.js';
import GroupContacts from '../../containers/group-contacts.js';
import MyContacts from '../../containers/my-contacts.js';
// import MyGroups from '../../containers/my-groups.js';
// import MyGroupsReport from '../../containers/my-groups-report.js';
import MyProfile from '../../containers/my-profile.js';
import OpenCommunity from '../../containers/open-community.js';
import PeerGroupAddOrUpdate from '../../containers/peer-group-add-update.js';
import PeerGroupAddNewView from '../../containers/peer-group-add-update.js';
import PrivacyPolicy from '../../containers/privacy-policy.js';
import ResetPassword from '../../containers/password-reset.js';
import ServicePolicy from '../../containers/service-policy.js';
import SplitChapter from '../../containers/chapter-splitter.js';
import VideoYouTube from '../../containers/video-youtube.js'
import WorkAddNew from '../../containers/work-add-new.js';
import WorkEditReport from '../../containers/work-edit-report.js';
import WorkSettings from '../../containers/work-settings.js';
import WorkUploadFile from '../../containers/work-upload-file.js';


export default function AppView(props) {
	const [lastTouchY, setLastTouchY] = useState(0)

	const touchstartHandler = (event) => {
		if (event.touches.length !== 1) return;
		setLastTouchY(event.touches[0].clientY);
	}

	const touchmoveHandler = (event) => {
		let touchY = event.touches[0].clientY;
		let touchYDelta = touchY - lastTouchY;
		setLastTouchY(touchY);

		if (touchYDelta > 0) {
			event.preventDefault();
			return;
		}
	}

	useEffect(() => {
		document.addEventListener('touchstart', touchstartHandler, false);
		document.addEventListener('touchmove', touchmoveHandler, false);
	}, [])

	return (
		<AuthProvider>
			{props.personId &&
				<div className={styles.fitTop}>
					<MobileHeader {...props} />
				</div>
			}
			{props.children}
			{/*<IdleTimeOutHandler*/}
			{/*  onActive={()=>{setIsActive(true)}}*/}
			{/*  onIdle={()=>{setIsActive(false)}}*/}
			{/*  onLogout={()=>{setLogout(true)}}*/}
			{/*/>*/}
			<Routes>
				<Route path="/" element={<Login />} />
				<Route path="/login" element={<Login />} />
				<Route path="/login/:createNew" element={<Login />} />
				<Route path="/logout" element={<Logout />} />
				<Route path="/resetPassword" element={<ResetPassword />} />
				<Route path="/forgotPassword" element={<ForgotPassword />} />
				<Route path="/forgotUsername" element={<ForgotUsername />} />
				<Route path="/privacy" element={<PrivacyPolicy />} />
				<Route path="/service" element={<ServicePolicy />} />
				<Route path="/dataDeletion" element={<DataDeletionPolicy />} />
				<Route path="/invite/:inviteCode/:firstName/:lastName/:emailAddress/:phone/:createNew" element={<EditorInviteResponse />} />
				<Route path="/i/:inviteCodeShort/:createNew" element={<EditorInviteResponse />} />
				<Route path="/video/:videoName" element={<VideoYouTube />} />
				<Route path="/group/invite/:inviteCode/:createNew" element={<EditorInviteResponse />} />
				<Route path="/myProfile/:createNew" element={<MyProfile />} />
				<Route path="/myGroups" element={<RequireAuth><MyGroups /></RequireAuth>} />
				<Route path="/myWorks" element={<RequireAuth><MyWorks /></RequireAuth>} />
				<Route path="/myWorks/group/:groupChosen" element={<RequireAuth><MyWorks /></RequireAuth>} />
				<Route path="/myWorks/:init" element={<RequireAuth><MyWorks /></RequireAuth>} />
				<Route path="/editReview/:workId/:chapterId/:languageId" element={<RequireAuth><EditReview /></RequireAuth>} />
				{/*<Route path="/editReview/:isLMSTransfer/:workId" element={<RequireAuth><EditReview/></RequireAuth>}/>*/}
				{/*<Route path="/editReview/:chosenTabPersonId/:hrefId" element={<RequireAuth><EditReview/></RequireAuth>}/>*/}

				{/*<Route path="/firstNav/:sendTo" element={<RequireAuth><FirstNav/></RequireAuth>} />*/}
				{/*<Route path="/workSettings" element={<RequireAuth><WorkSettings/></RequireAuth>} />*/}
				<Route path="/myContacts" element={<RequireAuth><MyContacts /></RequireAuth>} />
				<Route path="/editorInviteContactList" element={<RequireAuth><EditorInviteContactList /></RequireAuth>} />
				<Route path="/editorInviteContactList/:groupChosen" element={<RequireAuth><EditorInviteContactList /></RequireAuth>} />
				<Route path="/editorInviteGoogleContacts" element={<RequireAuth><EditorInviteGoogleContacts /></RequireAuth>} />
				<Route path="/editorInviteGoogleContacts/:groupChosen" element={<RequireAuth><EditorInviteGoogleContacts /></RequireAuth>} />
				<Route path="/editorInviteByBulk" element={<RequireAuth><EditorInviteByBulk /></RequireAuth>} />
				<Route path="/editorInviteByBulk/:groupChosen" element={<RequireAuth><EditorInviteByBulk /></RequireAuth>} />
				<Route path="/giveAccessToEditors" element={<RequireAuth><GiveAccessToEditors /></RequireAuth>} />
				<Route path="/giveAccessToWorks" element={<RequireAuth><GiveAccessToWorks /></RequireAuth>} />
				<Route path="/giveAccessToWorks/:workSearch/:id" element={<RequireAuth><GiveAccessToWorks /></RequireAuth>} />
				<Route path="/workAddNew" element={<RequireAuth><WorkAddNew /></RequireAuth>} />
				<Route path="/workAddNew/filePenspring/:workId" element={<RequireAuth><WorkAddNew /></RequireAuth>} />
				<Route path="/workAddNew/group/:groupId" element={<RequireAuth><WorkAddNew /></RequireAuth>} />
				<Route path="/workAddNew/folder/:workFolderId" element={<RequireAuth><WorkAddNew /></RequireAuth>} />
				<Route path="/groupWorks/:groupId" element={<RequireAuth><GroupWorks /></RequireAuth>} />
				<Route path="/groupContacts/:groupId" element={<RequireAuth><GroupContacts /></RequireAuth>} />
				<Route path="/workUploadFile" element={<RequireAuth><WorkUploadFile /></RequireAuth>} />
				<Route path="/chapterUploadFile" element={<RequireAuth><ChapterUploadFile /></RequireAuth>} />
				<Route path="/draftSettings" element={<RequireAuth><DraftSettings /></RequireAuth>} />
				<Route path="/draftReview" element={<RequireAuth><DraftReview /></RequireAuth>} />
				<Route path="/draftReview/:workId/:languageId" element={<RequireAuth><DraftReview /></RequireAuth>} />
				<Route path="/draftReview/:workId/:languageId/:leftTabChosen/:leftDate" element={<RequireAuth><DraftReview /></RequireAuth>} />
				<Route path="/editorInviteOptions" element={<RequireAuth><EditorInviteOptions /></RequireAuth>} />
				<Route path="/editorInviteNameEmail" element={<RequireAuth><EditorInviteNameEmail /></RequireAuth>} />
				<Route path="/editorInviteNameEmail/:groupChosen" element={<RequireAuth><EditorInviteNameEmail /></RequireAuth>} />
				<Route path="/editorInviteWorkAssign/:firstName/:lastName/:phone/:inviteMessage"
					element={<RequireAuth><EditorInviteWorkAssign /></RequireAuth>} />
				<Route path="/splitChapter" element={<RequireAuth><SplitChapter /></RequireAuth>} />
				<Route path="/openCommunity" element={<RequireAuth><OpenCommunity /></RequireAuth>} />
				<Route path="/myProfile" element={<RequireAuth><MyProfile /></RequireAuth>} />
				<Route path="/workEditReport" element={<RequireAuth><WorkEditReport /></RequireAuth>} />
				<Route path="/workEditReport/:workSearch/:id" element={<RequireAuth><WorkEditReport /></RequireAuth>} />

				<Route path="/report/e/:editType" element={<RequireAuth><ContributorReport /></RequireAuth>} />
				<Route path="/report/e/:editType/editCount/:editTypeCount"
					element={<RequireAuth><ContributorReport /></RequireAuth>} />
				<Route path="/report/e/:editType/:workId" element={<RequireAuth><ContributorReport /></RequireAuth>} />
				<Route path="/report/e/:editType/:workId/editCount/:editTypeCount"
					element={<RequireAuth><ContributorReport /></RequireAuth>} />
				<Route path="/report/e/:editType/:workId/:personId" element={<RequireAuth><ContributorReport /></RequireAuth>} />
				<Route path="/report/e/:editType/:workId/:personId/editCount/:editTypeCount"
					element={<RequireAuth><ContributorReport /></RequireAuth>} />
				<Route path="/report/e/:editType/:workId/:personId/:sections"
					element={<RequireAuth><ContributorReport /></RequireAuth>} />
				<Route path="/report/e/:editType/:workId/:personId/:sections/editCount/:editTypeCount"
					element={<RequireAuth><ContributorReport /></RequireAuth>} />
				<Route path="/report/t/:editType" element={<RequireAuth><ContributorReport /></RequireAuth>} />
				<Route path="/report/t/:editType/editCount/:editTypeCount"
					element={<RequireAuth><ContributorReport /></RequireAuth>} />
				<Route path="/report/t/:editType/:workId" element={<RequireAuth><ContributorReport /></RequireAuth>} />
				<Route path="/report/t/:editType/:workId/editCount/:editTypeCount"
					element={<RequireAuth><ContributorReport /></RequireAuth>} />
				<Route path="/report/t/:editType/:workId/:languageId"
					element={<RequireAuth><ContributorReport /></RequireAuth>} />
				<Route path="/report/t/:editType/:workId/:languageId/editCount/:editTypeCount"
					element={<RequireAuth><ContributorReport /></RequireAuth>} />
				<Route path="/report/t/:editType/:workId/:languageId/:personId"
					element={<RequireAuth><ContributorReport /></RequireAuth>} />
				<Route path="/report/t/:editType/:workId/:languageId/:personId/editCount/:editTypeCount"
					element={<RequireAuth><ContributorReport /></RequireAuth>} />
				<Route path="/report/t/:editType/:workId/:languageId/:personId/:sections"
					element={<RequireAuth><ContributorReport /></RequireAuth>} />
				<Route path="/report/t/:editType/:workId/:languageId/:personId/:sections/editCount/:editTypeCount"
					element={<RequireAuth><ContributorReport /></RequireAuth>} />
				<Route path="/report/t/:editType/works/languages/editors/noSections/le/:langOrEditorCount"
					element={<RequireAuth><ContributorReport /></RequireAuth>} />
				<Route
					path="/report/t/:editType/works/languages/editors/noSections/le/:langOrEditorCount/editCount/:editTypeCount"
					element={<RequireAuth><ContributorReport /></RequireAuth>} />

				<Route path="/groupTypeChoice" element={<RequireAuth><GroupTypeChoice /></RequireAuth>} />
				<Route path="/groupAddNew" element={<RequireAuth><GroupAddNew /></RequireAuth>} />
				<Route path="/groupAddNew/:groupId" element={<RequireAuth><GroupAddNew /></RequireAuth>} />
				<Route path="/groupAddNew/:workFolderId/:groupId" element={<RequireAuth><GroupAddNew /></RequireAuth>} />
				<Route path="/groupAddNewByWorkFolder/:workFolderId" element={<RequireAuth><GroupAddNew /></RequireAuth>} />
				<Route path="/groupChooseExisting/:workFolderId" element={<RequireAuth><GroupChooseExisting /></RequireAuth>} />
				<Route path="/groupChooseExisting/:workFolderId/:groupId" element={<RequireAuth><GroupChooseExisting /></RequireAuth>} />
				<Route path="/groupSettings" element={<RequireAuth><GroupSettings /></RequireAuth>} />
				<Route path="/groupSettings/:groupChosen" element={<RequireAuth><GroupSettings /></RequireAuth>} />
				{/*<Route path="/myGroups" element={<RequireAuth><MyGroups/></RequireAuth>} />*/}
				{/*<Route path="/myGroupsReport" element={<RequireAuth><MyGroupsReport/></RequireAuth>} />*/}
				<Route path="/groupMemberUpdate/:memberPersonId" element={<RequireAuth><GroupMemberUpdate /></RequireAuth>} />
				<Route path="/accessReport" element={<RequireAuth><AccessReport /></RequireAuth>} />
				<Route path="/accessReport/:groupChosen" element={<RequireAuth><AccessReport /></RequireAuth>} />
				<Route path="/groupEditReport/e/:editType" element={<RequireAuth><GroupEditReport /></RequireAuth>} />
				<Route path="/groupEditReport/e/:editType/:workId/editCount/:editTypeCount" element={<RequireAuth><GroupEditReport /></RequireAuth>} />
				<Route path="/groupEditReport/e/:editType/:workId" element={<RequireAuth><GroupEditReport /></RequireAuth>} />
				<Route path="/groupEditReport/e/:editType/:workId/:personId" element={<RequireAuth><GroupEditReport /></RequireAuth>} />
				<Route path="/peerGroupAddOrUpdate/:groupChosen" element={<RequireAuth><PeerGroupAddOrUpdate /></RequireAuth>} />
				<Route path="/peerGroupAddOrUpdate/:groupChosen/:peerGroupId" element={<RequireAuth><PeerGroupAddOrUpdate /></RequireAuth>} />
				<Route path="/groupWorkAssign/:groupChosen/:masterWorkId" element={<RequireAuth><GroupWorkAssign /></RequireAuth>} />
				<Route path="/assignmentDashboard" element={<RequireAuth><AssignmentDashboard /></RequireAuth>} />
				<Route path="/assignmentDashboard/:groupChosen" element={<RequireAuth><AssignmentDashboard /></RequireAuth>} />
				<Route path="/assignmentDashboard/:groupChosen/:masterWorkId" element={<RequireAuth><AssignmentDashboard /></RequireAuth>} />
				<Route path="/fileFolderAddNew/:mineOrOthers/:workFolderId" element={<RequireAuth><FileFolderAddNew /></RequireAuth>} />
				<Route path="/workSettings/:workId" element={<RequireAuth><WorkSettings /></RequireAuth>} />

				<Route path="*" element={<NotFound />} />
			</Routes>
		</AuthProvider>
	)
}
