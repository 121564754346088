import React, {useState} from 'react';
import styles from './EditHistorySegment.module.css';
import classes from "classnames";
import EditorNameDateSentence from '../EditorNameDateSentence'
import AuthorHistoryOriginal from '../AuthorHistoryOriginal'
import MessageModal from '../MessageModal'
import Icon from '../Icon'

function EditHistorySegment(props) {
  const {history, visible, isAuthor, cleanDifferentEditText, getEditorColor, originalSentenceText, editsSorted, sendResponseEdit, editLanguageId} = props;

  const [isShowingModal, setIsShowingModal] = useState(false)

  const handleIsShowingModal = (history) => {
    if (editsSorted && editsSorted.length > 0) setIsShowingModal(true)
    let element = document.getElementById(history.elementId)
    if (element) {
      element.innerHTML = history.authorTextSnapshot
      element.style.backgroundColor = 'white'
    }
  }

  const handleRemoveEdits = () => {
    setIsShowingModal(false)
    editsSorted && editsSorted.length > 0 && editsSorted.forEach(m => {
      sendResponseEdit(m, 'RejectEdit')
    })
  }

  return (
    <div className={visible ? styles.show : styles.hide}>
      {history && history.length > 0 && history.map((m, i) => {
        let differenceEditText = cleanDifferentEditText(m.text)
        let editorColor = getEditorColor(m.personId)

        return (
          <div className={styles.historyBackground} key={i}>
            <div className={styles.header}>
              History
            </div>
            <div>
              <div className={styles.insidePadding}>
                <EditorNameDateSentence edit={m}
                                        isHistory
                                        editorColor={editorColor}
                                        personConfig={props.personConfig}
                                        originalSentenceText={originalSentenceText}
                                        differenceEditText={differenceEditText}
                                        handleSetChosenMoveEdit={props.handleSetChosenMoveEdit}/>

                <div className={styles.toolOptions}>
                  {isAuthor && <a className={styles.restoreButton} onClick={() => props.restoreEditSegment(m.editSegmentId, editLanguageId)}>Restore</a>}
                  <span className={styles.iconRow} title={'Vote up +1'}>
                    <Icon pathName={`thumbs_up0`} premium={true} className={styles.historyChoiceIcons}/>
                    <span className={styles.voteCount}>{m.voteUpCount === 0 ? '' : m.voteUpCount}</span>
                  </span>
                  <span className={styles.iconRow} title={'Vote down -1'}>
                    <Icon pathName={`thumbs_down0`} premium={true}
                          className={styles.historyChoiceIcons}/>
                    <span className={styles.voteCount}>{m.voteDownCount === 0 ? '' : m.voteDownCount}</span>
                  </span>
                  <span className={styles.iconRow} title={'Troll vote -1'}>
                    <div className={classes(styles.row, styles.moreLeft)}>
                      <Icon pathName={`blocked`} fillColor={'red'}
                            className={styles.imageBlocked}/>
                      <Icon pathName={`user_minus0`} premium={true}
                            className={styles.imageOverlay}/>
                    </div>
                    <span className={styles.trollCount}>{m.voteTrollCount === 0 ? '' : m.voteTrollCount}</span>
                  </span>
                </div>
              </div>
              {m.type === 'TEXT' &&
                <div className={styles.insidePadding}>
                  <AuthorHistoryOriginal history={m} />
                  {isAuthor &&
                    <div className={styles.toolOptions}>
                      <a className={styles.restoreButton} onClick={() => handleIsShowingModal(m)}>Restore original</a>
                    </div>
                  }
                </div>
              }
              </div>
          </div>
        )}
      )}
      <div className={styles.viewWidth}>
        <MessageModal show={isShowingModal} handleClose={() => setIsShowingModal(false)} heading={`Remove the edits?`}
                      explain={`Do you want to remove the existing edits for this sentence?`} isConfirmType={true}
                      onClick={handleRemoveEdits}/>
      </div>
    </div>
  )
}


export default EditHistorySegment