import React, { useState, useEffect } from 'react'
import styles from './FileUploadPenspring.module.css'
import { useMediaQuery } from 'react-responsive';
import moveTarget from '../../assets/Inline Icons/move-target.svg'
import Camera from '../../assets/camera.png'

function FileUploadPenspring(props) {
	const { preLabel, label, subLabel, fileTypes, targetIcon, setFile, captureCameraOnly } = props
	const isMobile = useMediaQuery({ query: '(max-width: 900px)' })

	const [acceptFileTypes, setAcceptFileTypes] = useState('application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/plain,application/pdf,application/vnd.oasis.opendocument.text,text/html,text/css,text/javascript,text/javascript,text/x,text/x,text/plain,text/x,text/x,application/x,text/x,text/x,text/typescript,text/typescript,application/xml,application/json,application/sql,text/markdown')

	useEffect(() => {
		if (fileTypes) {
			setAcceptFileTypes(fileTypes)
		}
	}, [fileTypes])

	return (
		<div className={styles.container}>
			<div
				className={styles.mainDiv}
				onClick={() => document.getElementById('hiddenFileInput').click()} // Trigger click on the hidden input
				style={{ cursor: 'pointer' }} // Optional: change cursor to indicate interactivity
			>
				<div className={styles.imageMoveTarget}>
					{targetIcon ? targetIcon : <img src={isMobile ? Camera : moveTarget} width={22} />}
				</div>
				<div>
					<input
						id="hiddenFileInput"
						type="file"
						onChange={setFile}
						capture={captureCameraOnly ? 'camera' : ''}
						accept={acceptFileTypes}
						style={{ display: 'none' }} // Hide the file input element
					/>
					{preLabel && <div className={styles.preLabel}>{preLabel}</div>}
					<div className={styles.rowWrap}>
						{label || 'Click here to select a local file (or drag & drop)'}
						<div className={styles.allowed}>
							{subLabel || '(Only 1 file is allowed)  Microsoft® Word, HTML, ...'}
						</div>
					</div>
				</div>
			</div>
		</div>

	)
}

export default FileUploadPenspring