import React, {useEffect, useState} from 'react'
import styles from './AuthoringEditor.module.css'
import * as editorService from '../../services/editor-dom'
import * as sentenceService from "../../services/sentence-splitter";
import WebsiteLinkEntry from '../../components/WebsiteLinkEntry'
import EditorDivFormatControls from '../../components/EditorDivFormatControls'
import EditorDivFormatControlsMobile from '../../components/EditorDivFormatControlsMobile'
import EditListChoice from '../../components/EditListChoice'
import ToggleBoardMobile from '../../components/ToggleBoardMobile'
import MessageModal from '../../components/MessageModal'
import EditorFullTextView from '../../components/EditorFullTextView'
import TabPage from '../../components/TabPage'
import AuthorContextMenu from '../AuthorContextMenu'
import classes from "classnames";
import backgroundColors from "../../utils/backgroundColors";
import {isCursorAtStartOrEnd, setCursorPosition} from "../../services/editor-dom";
import { guidEmpty } from '../../utils/GuidValidate.js'
import { useMediaQuery } from 'react-responsive';
import EditorSaveButton from '../../components/EditorSaveButton'

let workDownloadReady = null  //This is necessary because the download is refreshing the page and losing local state. So we accumulate a localStorage, a global variable and a local state.
//const limitChangeCounts = 20
let androidEnterKey = []
let isInitScroll = false
let isTextChanged = false
let backspaceNodes = []
let deleteKeyNodes = []
let savedCursorPosition = {}
let skipRestoreCursorOnce = false
let moveEditArray = []
let deleteSentenceArray = []
let isSurgicalTextChange = false

let personConfig = localStorage.getItem('personConfig')
personConfig = personConfig ? JSON.parse(personConfig) : []

function AuthoringEditor(props) {
  const {
    workSummary,
    segments = [],
    edits = [],
    isTranslation = false,
    isAuthor,
    addOrUpdateEdit,
    currentElement = {id: ''},
    handleSetCurrentElement,
    handleSetChosenSegment,
    chosenSegment,
    getNextId,
    chosenTab,
    tabsData,
    handleEditorTabChosen,
    tabNav,
    navText,
    contextMenu,
    initialContextMenu,
    setContextMenu,
    chapterListLevels,
    addChapterListLevels,
    listLevelGeneral,
    editLanguageId,
    getEditSegments,
  } = props;

  const isMobile = useMediaQuery({ query: '(max-width: 870px)' })

  const [changeCounts, setChangeCounts] = useState(0)
  const [revisions, setRevisions] = useState([''])
  const [chosenHTMLSegment, setChosenHTMLSegment] = useState([''])
  const [isOpenLinkEntry, setIsOpenLinkEntry] = useState(false)
  const [saveWorkSpaceTime, setSaveWorkSpaceTime] = useState(new Date())
  const [showInstructions, setShowInstructions] = useState(false)
  const [editChosen, setEditChosen] = useState()
  const [entryError, setEntryError] = useState('')
  const [showDownloadReady, setShowDownloadReady] = useState(false)  //This is necessary because the download is refreshing the page and losing local state. So we accumulate a localStorage, a global variable and a local state.
  const [showAddParagraphSentenceInsideList, setShowAddParagraphSentenceInsideList] = useState(false)  
  

  useEffect(() => {
    workDownloadReady = localStorage.getItem('workDownloadReady')
    workDownloadReady = workDownloadReady ? JSON.parse(workDownloadReady) : null
    if (workDownloadReady) setShowDownloadReady(true)  //This is necessary because the download is refreshing the page and losing local state. So we accumulate a localStorage, a global variable and a local state.
  }, [])

  useEffect(() => {
    if (isSurgicalTextChange) {
      isSurgicalTextChange = false  //This is for useTabToPreviousSentence and useTabToNextSentence
    } else if (segments && segments.length > 0 && workSummary && workSummary.workId) { //!isInit &&
      Promise.all([editorService.setSegmentViews(chosenTab, workSummary, segments, edits, isAuthor, props.personId, props.editorName, tabsData, getNextId, chapterListLevels, addChapterListLevels, listLevelGeneral)])
        .then(setCursorPosition(currentElement, currentElement, 0, 0))

      if (!isInitScroll) {
        //If there isn't any currentElement, then let's put the cursor proactively into the first spot of the main body to avoid having the user put the cursor in no-mans' land of BEFORE the main
        const scrollCurrentElementId = localStorage.getItem(`scrollCurrentElementId-${workSummary.workId}`)
        if (!scrollCurrentElementId) {
          editorService.setIntoFirstSpan()
        }
        isInitScroll = true
        // //Scroll the page into the view of where the user left off.
        // const scrollCurrentElementId = localStorage.getItem(`scrollCurrentElementId-${workSummary.workId}`)
        // const scrollCurrentElement = scrollCurrentElementId && document.querySelector(`[id="${scrollCurrentElementId}"]`)
        // if (scrollCurrentElement) {
        //   scrollCurrentElement.scrollIntoView({behavior: "smooth", block: "center"})
        //   // const tabViewElement = document.querySelector(`[id="${scrollCurrentElementId + '~tabView'}"]`)
        //   // if (tabViewElement) setTimeout(() => tabViewElement.scrollIntoView({behavior: "smooth", block: "center"}), 500)
        //   setTimeout(() => scrollDocumentToMatch(scrollCurrentElement, scrollCurrentElement.id), 500)
        // }
      }
      const editorDiv = document.getElementById('editorDiv')
      props.setHasListStructure(editorDiv && editorDiv.innerHTML.indexOf('<li') > -1)
    }
  }, [segments, workSummary, edits]) //Don't put in ", workSummary, isAuthor, props.personId" since it causes the cursor to keep moving to the beginning of editorDiv.


  useEffect(() => {
    if (props.deleteParagraphBreak) {
      let images = document.getElementsByClassName('ParagraphQuestionMinus')
      if (!images || images.length === 0) {
        if (props.deleteParagraphBreak && currentElement) {
          if (editorService.isCursorInsideList(currentElement)) {
            //ToDo message about not being able to do a paragraph break in the middle of a list structure.
          } else if (editorService.isCursorInsideSpan(currentElement)) {
            editorService.setDeleteParagraphBreakIcon(currentElement, props.personId, props.editorName, props.chapterId, addOrUpdateEdit, props.setIsInitEdits, props.setDeleteParagraphBreak, editLanguageId, getEditSegments)
          }
        }
      }
    } else {
      editorService.removeDeleteParagraphBreakIcons()
    }
  }, [props.deleteParagraphBreak])

  useEffect(() => {
    if (props.addParagraphBreak) {
      let images = document.getElementsByClassName('ParagraphQuestionPlus')
      if (!images || images.length === 0) {
        if (editorService.isCursorInsideList(currentElement)) {
          //Help ToDo message about not being able to do a paragraph break in the middle of a list structure.
        } else if (editorService.isCursorInsideSpan(currentElement)) {
          editorService.setAddParagraphBreakIcon(currentElement, props.personId, props.editorName, props.chapterId, addOrUpdateEdit, props.setIsInitEdits, props.setAddParagraphBreak, getNextId, editLanguageId, getEditSegments)
        }
      }
    } else {
      editorService.removeAddParagraphBreakIcons()
    }
  }, [props.addParagraphBreak])

  useEffect(() => {
    if (props.addParagraphSentence) {
      if (editorService.isCursorInsideList(currentElement)) {
        setShowAddParagraphSentenceInsideList(true) 
      } else {
        let images = document.getElementsByClassName('ParagraphSentenceQuestionPlus')
        if (!(images && images.length > 0) && editorService.isCursorInsideSpan(currentElement)) {
          editorService.setAddParagraphSentenceIcon(currentElement, props.personId, props.editorName, props.chapterId, addOrUpdateEdit, props.setIsInitEdits, props.setAddParagraphSentence, editLanguageId, props.getEditSegments)
        }
      }
    } else {
      editorService.removeAddParagraphSentenceIcons()
    }
  }, [props.addParagraphSentence])

  useEffect(() => {
    if (props.addSentence) {
      let images = document.getElementsByClassName('SentenceQuestionPlus')
      if (!(images && images.length > 0) && editorService.isCursorInsideSpan(currentElement)) {
        editorService.setAddSentenceIcon(currentElement, props.personId, props.editorName, props.chapterId, addOrUpdateEdit, props.setIsInitEdits, props.setAddSentence, editLanguageId, props.getEditSegments)
      }
    } else {
      editorService.removeAddSentenceIcons()
    }
  }, [props.addSentence])

  useEffect(() => {
    if (props.deleteSentence) {
      let images = document.getElementsByClassName('SentenceQuestionMinusStart')
      if (!(images && images.length > 0) && editorService.isCursorInsideSpan(currentElement)) {
        editorService.setDeleteSentenceIcons(currentElement, props.personId, props.editorName, props.chapterId, updateDeleteSentenceEdit, editLanguageId)
      }
    } else {
      editorService.removeDeleteSentenceStartIcons()
      editorService.removeDeleteSentenceEndIcons()
      deleteSentenceArray = []
    }
  }, [props.deleteSentence])

  useEffect(() => {
    if (props.addListItem) {
      let images = document.getElementsByClassName('ListItemQuestionPlus')
      if (!(images && images.length > 0) && editorService.isCursorInsideList(currentElement)) {
        editorService.setAddListItemIcon(currentElement, props.personId, props.editorName, props.chapterId, addOrUpdateEdit, props.setIsInitEdits, props.setAddListItem, isAuthor, getNextId, editLanguageId)
      }
    } else {
      editorService.removeAddListItemIcons()
    }
  }, [props.addListItem])

  useEffect(() => {
    if (props.deleteListItem) {
      if (editorService.isCursorInsideList(currentElement)) {
        editorService.setDeleteListItemIcon(currentElement, props.personId, props.editorName, props.chapterId, addOrUpdateEdit, props.setIsInitEdits, props.setDeleteListItem, isAuthor, editLanguageId)
      }
    } else {
      editorService.removeDeleteListItemIcons()
    }
  }, [props.deleteListItem])

  useEffect(() => {
    if (props.reorderListItems) {
      if (editorService.isCursorInsideList(currentElement)) editorService.setReorderDropDownLists(currentElement, edits, onChooseReorder, isAuthor)
    } else {
      editorService.removeReorderListItemsDropDowns()
    }
  }, [props.reorderListItems])

  useEffect(() => {
    if (props.moveSentences) {
      let images = document.getElementsByClassName('MoveStart')
      if (!(images && images.length > 0) && !editorService.isCursorInsideList(currentElement)) {
        editorService.setMoveSentencesStartIcon(currentElement, props.personId, props.editorName, props.chapterId, updateMoveEdit, editLanguageId)
      }
    } else {
      editorService.removeMoveSentencesStartIcons()
    }
  }, [props.moveSentences])

  const unHighlightChosenSegments = () => {
    const rewindCount = 5;
    for(let i = chosenSegment.length-1; i >= 0 && i >= chosenSegment.length - rewindCount; i--) {
      if (chosenSegment[i].type) {
        //Editor view
        let element = document.querySelector(`[id="${chosenSegment[i].id}"][data-type="${chosenSegment[i].type}"]`)
        if (!element) element = document.querySelector(`[data-span-id="${chosenSegment[i].id}"][data-type="${chosenSegment[i].type}"]`)
        if (element) {
          if (chosenSegment[i].type === 'TEXT') element.style.backgroundColor = backgroundColors.editPending
          else element.style.backgroundColor = backgroundColors.normal
        }
        //Tab view
        element = document.querySelector(`[id="${chosenSegment[i].id}~tabView"][data-type="${chosenSegment[i].type}"]`)
        if (!element) element = document.querySelector(`[data-span-id="${chosenSegment[i].id}~tabView"][data-type="${chosenSegment[i].type}"]`)
        if (element) {
          if (chosenSegment[i].type === 'TEXT') element.style.backgroundColor = backgroundColors.editPending
          else element.style.backgroundColor = backgroundColors.normal
        }
      }
    }
  }

  const updateChangeCounts = () => {
    if (isAuthor) {
      //6/22/2024 - decided not to do this since the cursor is jumping around to the start. Not good. We'll let the user be responsible for saving their document.
      // if (changeCounts > limitChangeCounts) {
      //   //savedCursorPosition = editorService.saveCursorLocation(document.getElementById('editorDiv'))
      //   saveByButtonPress()
      //   setChangeCounts(0)
      //   //editorService.restoreCursorLocation(document.getElementById('editorDiv'), savedCursorPosition)
      // } else {
      setChangeCounts(changeCounts + 1)
      // }
    }
  }

  const onChooseReorder = (listElement, listItemId, currentIndex, targetIndex, isAuthor, editLanguageId) => {
    if (!editLanguageId) editLanguageId = props.workSummary.languageId_current || props.workSummary.languageId //Just to ensure that we have a languageId
    editorService.updateListItemOrder({
      listElement,
      listItemId,
      currentIndex,
      targetIndex,
      isAuthor,
      addOrUpdateEdit,
      addOrUpdateSegments: props.addOrUpdateSegments,
      setSaveWorkSpaceTime,
      segments,
      chapterId: props.chapterId,
      getNextId,
      personId: props.personId,
      editorName: props.editorName,
      editLanguageId,
    })
    //We just need to get an element inside the listItemElement in order to call the setReorderDropDownLists again.
    let firstSpan = editorService.getFirstSpanFromParent(listElement)
    if (firstSpan) setTimeout(() => editorService.setReorderDropDownLists(firstSpan, edits, onChooseReorder, isAuthor), 500)
  }

  const handleTouchStart = (event) => {
    event.preventDefault(); // Prevent default touch behavior if needed
    handleMouseUp(event, true)
  };

  const handleTouchEnd = (event) => {
    event.preventDefault(); // Prevent default touch behavior if needed
  };

  // const handleTouchStart = (event) => {
  //   timer = setTimeout(() => onLongTouch(event), touchDuration);
  // }

  // const handleTouchEnd = (event) => {
  //   //stops short touches from firing the event
  //   if (timer) clearTimeout(timer); // clearTimeout, not cleartimeout..
  //   handleMouseUp(event, true)
  // }

  const onLongTouch = (event) => {
    if (!props.disableEditorPopup) handleContextMenu(event)
  };

  const updateMoveEdit = (updateType, elementId, editLanguageId, moveEndParagraph, isMoveEndParagraphTarget) => {
    if (updateType === 'ChoseStartElement') {
      moveEditArray.push(elementId)
      props.setMoveSequence(2)
    } else if (updateType === 'ChoseEndElement') {
      moveEditArray = editorService.getMoveSegments(moveEditArray, elementId, moveEndParagraph)
      props.setMoveSequence(3)
    } else if (updateType === 'ChoseTarget') {
      const targetElementId = moveEditArray[0]
      addOrUpdateEdit({
        editSegmentId: 0,
        editSegmentTypeId: 0, //This will be filled in on the server side by the type entered below
        personId: props.personId,
        chapterId: props.chapterId,
        elementId: Number(moveEditArray[0]),
        languageId: editLanguageId,
        startElementId: Number(moveEditArray[0]),
        precedingStartElementId: Number(editorService.getPrecedingElementId(moveEditArray[0])), //This is used in order to place the target icon in the editor display for the editor AFTER the segments have been moved to their new place ... os otherwise, we lose track of where to put the target icon where the sentences came FROM.
        moveEndParagraph: Number(moveEndParagraph),  //This variable looks like a boolean (the old intention) but it is now the paragraph's elementId of where that last segment came from.
        isMoveEndParagraphTarget: Number(isMoveEndParagraphTarget),
        moveToElementId: Number(elementId), //This is the parameter coming into this function (above)
        moveSegmentsArray: moveEditArray.toString(),
        type: 'MOVE',
        text: '',
        authorTextSnapshot: editorService.getMoveAuthorTextSnapshot(moveEditArray, elementId, moveEndParagraph, isMoveEndParagraphTarget),
      }, () => {
        setTimeout(() => getEditSegments(props.personId, guidEmpty, props.chapterId, editLanguageId), 500)
        setTimeout(() => editorService.setCursorPositionByRecall(targetElementId, 'TEXT'), 1000)
      })
      props.setMoveSentences(false)
      props.setMoveSequence(1)
      moveEditArray = []
    }
  }

  const updateDeleteSentenceEdit = (updateType, elementId, editLanguageId) => {
    if (updateType === 'ChoseStartElement') {
      deleteSentenceArray.push(elementId)
    } else if (updateType === 'ChoseEndElement') {
      editorService.deleteSentencesInArray(
        {
          startElementId: Number(deleteSentenceArray[0]),
          endElementId: elementId,
          isAuthor,
          saveEditorDivSegmentsPersistent: props.saveEditorDivSegmentsPersistent,
          handleSetCurrentElement,
          edits,
          segments,
          addOrUpdateEdit,
          editorName: props.editorName,
          personId: props.personId,
          workSummary,
          responseEdit: props.responseEdit,
          editLanguageId,
          getEditSegments: props.getEditSegments,
      })
      setTimeout(() => props.setIsInitEdits('FORCE'), 300)
      props.setDeleteSentence(false)
      deleteSentenceArray = []
    }
  }

  const saveByButtonPress = () => {
    if (isAuthor) {
      //savedCursorPosition = editorService.saveCursorLocation(document.getElementById('editorDiv'))
      let saveSegments = editorService.saveSegmentsToDB(segments, segments && segments[0].chapterId)
      props.addOrUpdateSegments(props.personId, saveSegments)
      setSaveWorkSpaceTime(new Date())
      setChangeCounts(0)
      //We might have to pull a trick here that if the sentence was split from where we were at, to match up the latest text sentence and find the new span in order to set the cursor location.
      editorService.restoreCursorLocation(document.getElementById('editorDiv'), savedCursorPosition)
    }
  }

  const setContextMenuIfMobile = () => {
    if (editorService.isMobile()) setContextMenu({show: true, x: 5, y: 45})
  }

  const showOuterHtml = () => {
    console.info('editorDiv: ', document.getElementById('editorDiv')  && document.getElementById('editorDiv').innerHTML)
    //console.info('tabView: ', document.getElementById('tabView').innerHTML)
  }

  const handleContextMenu = (event) => {
    event.preventDefault();
    let newHTML = [...chosenHTMLSegment]
    const {span, spanId, spanHTML} = editorService.spanMouseUp(event, chosenSegment, chosenHTMLSegment, saveRevision)
    if (spanId) {
      handleSetChosenSegment(span)
      //if (currentElement.id !== spanId)  //Dont' do this since an ADDTAB has the same elementId as the span TEXT eleentId
      editorService.setCurrentElementSpan(span, handleSetCurrentElement)  //This is for the parent page, EditReviewView
      //All sentences get the context menu (whether there is an existing edit or not) so that the user can enter a comment
      const {pageX, pageY} = event
      if (editorService.isMobile()) {
        setContextMenu({show: true, x: 5, y: 45}) //On mobile, put the context menu at the top left to give room for the keyboard. Open up the original text for context for the user.
      } else {
        setContextMenu({show: true, x: pageX, y: pageY})
      }
    }
    if (spanHTML) {
      newHTML.push(spanHTML)
      setChosenHTMLSegment(newHTML)
    }
  }

  const handleMouseUp = (event, isEditorDivView) => {
    event.stopPropagation()  
    const selectedText = getSelectionText(event.target)
    if (selectedText) return false
    if (isTranslation) editorService.clearTextHighlights('editorDiv', chosenSegment, edits)  //This is for the translation version where the editorDiv side is highlighted if it is the automatic gotoNextSentence setting
    if (event.target.nodeName === 'SELECT' && event.target.dataset.type === 'REORDERLISTITEMS') return true
    let spanElement = event.target
    //If the user clicked into the editorDiv, then we need to move the cursor into the mainDiv
    if (spanElement && (spanElement.id === 'editorDiv' || spanElement.id === '1')) editorService.setIntoFirstSpan()

    //If this is an image touch on mobile, then let the sidepanel open and find the currentElement in order to display.
    if (isMobile && spanElement && spanElement.nodeName === 'IMG' && spanElement.title) { //If it has a title then it is displaying an editor's name which means that it is an image that is going to be found in the side panel. 
      props.setIsOpenSlideOut(true)
    }

    //This could be a formatting element such as <u> or <i> or <b>, etc., which would not then be able to get up to the span with the Id.
    let loop = 0
    while (!(spanElement && (spanElement.nodeName === 'SPAN' || spanElement.nodeName === 'IMG') && spanElement.id) && loop < 8) {
      spanElement = spanElement.parentElement
      loop++
    }
    spanElement = handleSetCurrentElement(spanElement, savedCursorPosition)
    androidEnterKey = []
    if (event.target.nodeName === 'IMG' && event.target.dataset.type === 'COMMENT') {
      spanElement = document.querySelector(`span[id="${event.target.id.replace('~tabView', '')}"][data-type='TEXT']`)
      if (spanElement) {
        handleSetCurrentElement(spanElement)
        return
      }
    }

    handleSetChosenSegment(spanElement)
    savedCursorPosition = editorService.saveCursorLocation(document.getElementById('editorDiv'))
    if (props.reorderListItems && editorService.isCursorInsideList(spanElement)) editorService.setReorderDropDownLists(spanElement, edits, onChooseReorder, isAuthor)
    if (props.addListItem && editorService.isCursorInsideList(spanElement)) {
      //editorService.setAddListItemIcon(spanElement, props.personId, props.editorName, props.chapterId, addOrUpdateEdit, props.setIsInitEdits, props.setAddListItem, isAuthor, getNextId, editLanguageId)
      //Just do it! Don't make the user click a second time on the question-icon. If they click on a list item, then let them have a new one.
      if (!isAuthor) {
        addOrUpdateEdit({
          editSegmentId: 0,
          editSegmentTypeId: 0, //This will be filled in on the server side by the type entered below
          personId: props.personId,
          firstName: props.editorName && props.editorName.firstName,
          lastName: props.editorName && props.editorName.lastName,
          chapterId: props.chapterId,
          languageId: props.editLanguageId,
          elementId: spanElement.id,
          addListItemSequence: spanElement.dataset.addListItemSequence ? Number(spanElement.dataset.addListItemSequence) + 1 : 1,
          isNewAddListItemSequence: true, 
          text: '&nbsp;____&nbsp;',
          type: 'ADDLISTITEM',
          authorTextSnapshot: '',
        }, () => {
          setTimeout(() => getEditSegments(props.personId, null, props.chapterId, props.editLanguageId), 500)
          setTimeout(() => editorService.setCursorPositionByRecallAddListItem(spanElement.id, spanElement.dataset.addListItemSequence ? Number(spanElement.dataset.addListItemSequence) + 1 : 1), 1000)
        })
      } else {
        editorService.addNewListItemByAuthor(spanElement.id, getNextId)
      }
      props.setAddListItem(false) //Be sure to turn this off or it will continue to add more list items and confuse the user (like it did me)
    }
    if (props.deleteListItem && editorService.isCursorInsideList(spanElement)) editorService.setDeleteListItemIcon(spanElement, props.personId, props.editorName, props.chapterId, addOrUpdateEdit, props.setIsInitEdits, props.setDeleteListItem, isAuthor, editLanguageId)
    if (props.moveSentences && !editorService.isCursorInsideList(spanElement)) editorService.setMoveSentencesStartIcon(spanElement, props.personId, props.editorName, props.chapterId, updateMoveEdit, editLanguageId)
    if (props.addParagraphSentence && editorService.isCursorInsideSpan(spanElement) && spanElement.dataset.type === 'TEXT') {
      if (editorService.isCursorInsideList(spanElement)) {
        setShowAddParagraphSentenceInsideList(true) 
      } else {
        editorService.setAddParagraphSentenceIcon(spanElement, props.personId, props.editorName, props.chapterId, addOrUpdateEdit, props.setIsInitEdits, props.setAddParagraphSentence, editLanguageId, props.getEditSegments)
      }
    }
    if (props.addSentence && editorService.isCursorInsideSpan(spanElement)) editorService.setAddSentenceIcon(spanElement, props.personId, props.editorName, props.chapterId, addOrUpdateEdit, props.setIsInitEdits, props.setAddSentence, editLanguageId, props.getEditSegments)
    if (props.deleteSentence && (editorService.isCursorInsideSpan(spanElement) || (spanElement.nodeName ==='IMG' && spanElement.dataset.type === 'DELETESENTENCE'))) {
      //If deleteSentence edit type is chosen, then the first click is to choose the first sentence (and submit it as an edit)
      //A second click will determine the end of the sentences to be deleted (even if it is the current sentence)
      if (!deleteSentenceArray || deleteSentenceArray.length === 0) {
        deleteSentenceArray = [spanElement.id]
        updateDeleteSentenceEdit('ChoseStartElement', spanElement.id, editLanguageId)
        editorService.setDeleteSentencesEndIcons(updateDeleteSentenceEdit, editLanguageId, spanElement.id)
        editorService.setDeleteSentenceIcons(spanElement, props.personId, props.editorName, props.chapterId, updateDeleteSentenceEdit, editLanguageId)
      } else {
        //In the sentence-click case, however, (in contrast to the icon click that has the left arrow from the start of the given sentence) we will pick up the id
        //  of the next span so that it is deleted as well.  
        //If the delete-sentence image was clicked on (before the actual span) then we need to go nextSibling twice.
        let nextSpanElement = spanElement.nodeName === 'IMG' ? spanElement.nextSibling.nextSibling : spanElement.nextSibling
        let loop = 0
        while (!(nextSpanElement && nextSpanElement.nodeName === 'SPAN' && nextSpanElement.id) && loop < 10) {
          nextSpanElement = nextSpanElement && nextSpanElement.nextSibling
          loop++
        }
        //If the nextSpanElement was not yet found, then the last sentence of a paragraph must have been chosen. So we need to go to the next paragraph (parentElement) and get the first span from there
        if (!(nextSpanElement && nextSpanElement.nodeName === 'SPAN' && nextSpanElement.id)) {
          let previousParagraph = spanElement.parentElement.nextElementSibling
          if (previousParagraph) {
            nextSpanElement = previousParagraph.firstChild
            let loop = 0
            while (!(nextSpanElement && nextSpanElement.nodeName === 'SPAN' && nextSpanElement.id) && loop < 10) {
              nextSpanElement = nextSpanElement && nextSpanElement.nextSibling
              loop++
            }
          }
        }
        if (nextSpanElement) {
          updateDeleteSentenceEdit('ChoseEndElement', nextSpanElement && nextSpanElement.id, editLanguageId)
          editorService.setDeleteSentencesEndIcons(updateDeleteSentenceEdit, editLanguageId, spanElement.id)
          editorService.setDeleteSentencesEditorIcon(props.personId, props.editorName, props.chapterId, spanElement)
          editorService.removeDeleteSentenceEndIcons()
          deleteSentenceArray = []
          handleSetCurrentElement(nextSpanElement)
        }
        return //If this continues on it is possible that a second edit will be created locally as a TEXT which is blank so when the user goes to delete this DELETESENTENCE edit, the other one will stil exist and make the background of the sentence colored and show an edit (which doesn't match with the database, by the way).
      }
    }

    if (!props.moveSentences) props.setChosenMoveEdit() //Reset any moveEdit icons that could have been emphasized in the editor for display
    if (!props.addParagraphBreak) props.setChosenAddParagraphEdit()
    if (!props.deleteParagraphBreak) props.setChosenDeleteParagraphEdit('', 'ClearLast')
    if (!props.addParagraphSentence) props.setChosenAddParagraphSentenceEdit()
    if (!props.addSentence) props.setChosenAddSentenceEdit()
    if (!props.deleteSentence) props.setChosenDeleteSentenceEdit()
    if (!props.addListItem) props.setChosenAddListItemEdit()
    if (!props.deleteListItem) props.setChosenDeleteListItemEdit('', 'ClearLast')
    if (!props.reorderListItems) props.setChosenReorderListItemsEdit('', 'ClearLast')

    if (props.deleteParagraphBreak) {
      if (editorService.isCursorInsideList(spanElement)) {
        //ToDo message about not being able to delete a paragraph break in the middle of a list structure. You can either backspace from the beginning of the list item or delete the list item.
      } else if (editorService.isCursorInsideSpan(spanElement)) {
        editorService.setDeleteParagraphBreakIcon(spanElement, props.personId, props.editorName, props.chapterId, addOrUpdateEdit, props.setIsInitEdits, props.setDeleteParagraphBreak, editLanguageId, getEditSegments)
      }
    }

    if (props.addParagraphBreak) {
      if (editorService.isCursorInsideList(spanElement)) {
        //ToDo message about not being able to add a paragraph break in the middle of a list structure. As an author, you can hit enter anywhere inside the list item or choose to add another list item. As an editor, you can choose to add another list item.
      } else if (editorService.isCursorInsideSpan(spanElement)) {
        editorService.setAddParagraphBreakIcon(spanElement, props.personId, props.editorName, props.chapterId, addOrUpdateEdit, props.setIsInitEdits, props.setAddParagraphBreak, getNextId, editLanguageId, getEditSegments)
      }
    }

    // event.stopPropagation()
    // event.preventDefault()
    let currentSpan = spanElement //The mouseUpAction was setting this but we have already established, above, the spanElement as we ensure that it isn't an internal non-penspring span.
    let {returnIsTextChanged} = editorService.mouseUpAction({
      event,
      isEditorDivView,
      chosenHTMLSegment,
      setChosenAddParagraphEdit: props.setChosenAddParagraphEdit,
      handleSetCurrentElement,
      setContextMenuIfMobile,
      saveRevision,
      isAuthor,
      savedCursorPosition,
      chosenSegment,
      handleSetChosenSegment,
      isTextChanged,
      segments,
      addOrUpdateEdit,
      edits,
      personId: props.personId,
      editorName: props.editorName,
      workSummary,
      getNextId,
      setChosenHTMLSegment,
      setChosenAddParagraphSentenceEdit: props.setChosenAddParagraphSentenceEdit,
      setChosenAddSentenceEdit: props.setChosenAddSentenceEdit,
      setChosenDeleteListItemEdit: props.setChosenDeleteListItemEdit,
      setChosenDeleteParagraphEdit: props.setChosenDeleteParagraphEdit,
      setReorderListItems: props.setReorderListItems,
      scrollDocumentToMatch,
      isTranslation,
      editLanguageId,
      updateChangeCounts,
      getEditSegments: props.getEditSegments,
    })

    isTextChanged = returnIsTextChanged

    editorService.clearTextHighlights('tabView', chosenSegment, edits, spanElement)
    // if (savedCursorPosition && savedCursorPosition.start - savedCursorPosition.end === 0) {  //I don't know if this is helping us any.
    //   editorService.restoreCursorLocation(document.getElementById('editorDiv'), savedCursorPosition)
    // }
    //setFocusOnCurrentElement(currentSpan)
    setTimeout(() => setFocusOnCurrentElement(currentSpan), 300)
    if (isTextChanged) {
      saveRevision()
      updateChangeCounts()
      isTextChanged = false
    }
  }

  const setFocusOnCurrentElement = (element) => {
    if (element) {
      let editorDiv = document.getElementById('editorDiv')
      if (editorDiv) {
        editorDiv.focus()
        if (!isAuthor && element.nodeName === 'SPAN') {
          element.contentEditable = 'true'
          //element.focus()
          //setCursorPosition(element, element, 0, 0)
        }
        element && element.focus()
      }
    }
  }

  const handleKeyDOWNEditor = (event) => {
    event.stopPropagation()
    event.preventDefault()
    return false
  }

  const processEnterKey = (event, spanElement) => {
    event.stopPropagation()
    event.preventDefault()
    if (spanElement) {
      let parent = spanElement.parentElement
      let grandParent = parent && parent.parentElement
      let listItem = parent && parent.nodeName === 'LI' ? parent : grandParent && grandParent.nodeName === 'LI' ? grandParent : ''
      let newSpan

      if (listItem) {
        //If this is a list item and the current listItem doesn't have anything but a blank span (or a span with just a &nbsp;) then it is like a shift-TAB (or Backspace) to go backwards
        if (editorService.isListItemBlankContent(listItem)) {
          let elementNotTop = editorService.ensureChosenElementNotTop(spanElement, chosenSegment)
          const tabParagraphOrList = editorService.isFirstPositionOfParagraphOrList(elementNotTop, true, chosenSegment)

          editorService.adjustTab({
            chapterId: props.chapterId,
            event,
            tabParagraphOrList,
            currentElement,
            addOrUpdateEdit,
            isAuthor,
            getNextId,
            edits,
            personId: props.personId,
            editorName: props.editorName,
            getEditSegments: props.getEditSegments,
            workId: workSummary.workId,
            responseEdit: props.responseEdit,
            setIsInitEdits: props.setIsInitEdits,
            tabsData: props.tabsData,
            chapterListLevels,
            listLevelGeneral,
            addChapterListLevels,
            chosenSegment: props.chosenSegment,
            forceShiftKey: true, //Because this is a force backward movement.
            editLanguageId,
            setDeleteParagraphBreak: props.handleSetDeleteParagraphBreak,
          })
          currentElement.focus()
          setCursorPosition(currentElement, currentElement, 0, 0)

          //Add one to the cursor position for the left move. I don't know how it is going to respond to the right move.
          let {end, newElement, start} = savedCursorPosition
          editorService.restoreCursorLocation(document.getElementById('editorDiv'), {
            end: ++end,
            newElement,
            start: ++start
          })

        } else if (!isAuthor) {
          event.preventDefault()
          editorService.enterEditorNewListItem(spanElement, props.personId, props.chapterId, editLanguageId, addOrUpdateEdit, getEditSegments, handleSetChosenSegment, handleSetCurrentElement)
          return false
        } else {
          event.preventDefault()
          newSpan = editorService.createNewListItem(spanElement, getNextId, chapterListLevels)
          let test = newSpan
        }
      } else {
        newSpan = editorService.createNewParagraphOnEnterKey({
          element: spanElement,
          getNextId,
          isAuthor,
          addOrUpdateEdit,
          personId: props.personId,
          editorName: props.editorName,
          workId: workSummary.workId,
          chapterId: props.chapterId,
          languageId: workSummary.languageId_current,
          editLanguageId,
          getEditSegments: props.getEditSegments,
          setCursorPosition,
        })
      }
      if (newSpan) {
        newSpan.contentEditable = 'true'
        setCursorPosition(newSpan, newSpan, 0, 0)
        handleSetChosenSegment(newSpan)
        handleSetCurrentElement(newSpan)
        setChosenHTMLSegment(chosenHTMLSegment.concat(newSpan.innerHTML))
        skipRestoreCursorOnce = true
        saveRevision()
        androidEnterKey = []
      }
      return false
    }
  }

  const hasAndroidEnterKey = () => {
    if (editorService.isMobile()) {
      let found = androidEnterKey.filter(enterKey => enterKey.indexOf('ENTER') > -1)[0]
      if (found) {
        androidEnterKey = []
        return true
      }
    }
    return false
  }

  const handleKeyDOWN = (event) => {
    let spanElement = event.target
    if (!spanElement) return
    if (savedCursorPosition && (!spanElement || spanElement.id === '1' || spanElement.id === 'editorDiv')) {
      spanElement = savedCursorPosition.newElement
      if (spanElement && (spanElement.nodeName === 'P' || spanElement.nodeName === 'LI')) {
        let loop = 0
        spanElement = spanElement.firstChild
        while (spanElement && !(spanElement.nodeName === 'SPAN' && spanElement.id) && loop < 5) {
          spanElement = spanElement.nextElementSibling
        }
      }
    }
    handleSetCurrentElement(spanElement)
    //Help ToDo - What is this? androidEnterKey.push(event.nativeEvent.code + ' - ' + event.nativeEvent.key + ' - ' + event.keyCode)
    let selection
    let selectionChildren
    try {
      selection = window && window.getSelection() && window.getSelection().getRangeAt(0)
      selectionChildren = selection && selection.cloneContents().childNodes
    } catch(e) {
      //do nothing
    }

    if (event.ctrlKey && event.altKey && (event.key === "b" || event.key === "B") && !isTranslation && !isAuthor) {
      props.setDeleteParagraphBreak(!props.deleteParagraphBreak)
    } else if (event.ctrlKey && event.altKey && (event.key === "a" || event.key === "A") && !isTranslation && !isAuthor) {
      props.setAddParagraphBreak(!props.addParagraphBreak)
    } else if (event.ctrlKey && event.altKey && (event.key === "p" || event.key === "P") && !isTranslation && !isAuthor) {
      props.setAddParagraphSentence(!props.addParagraphSentence)
    } else if (event.ctrlKey && event.altKey && (event.key === "c" || event.key === "C") && !isTranslation && !isAuthor) {
      props.setAddSentence(!props.addSentence)
    } else if (event.ctrlKey && event.altKey && (event.key === "d" || event.key === "D") && !isTranslation && !isAuthor) {
      props.setDeleteSentence(!props.deleteSentence)
    } else if (event.ctrlKey && event.altKey && (event.key === "l" || event.key === "L") && !isTranslation && props.hasListStructure) {
      props.setAddListItem(!props.addListItem)
    } else if (event.ctrlKey && event.altKey && (event.key === "i" || event.key === "I") && !isTranslation && props.hasListStructure) {
      props.setDeleteListItem(!props.deleteListItem)
    } else if (event.ctrlKey && event.altKey && (event.key === "r" || event.key === "R") && props.hasListStructure) {
      props.setReorderListItems(!props.reorderListItems)
    } else if (event.ctrlKey && event.altKey && (event.key === "m" || event.key === "M") && !isTranslation) {
      props.setMoveSentences(!props.moveSentences)


    //Control+S
    } else if (isAuthor && event.ctrlKey && (event.key === 's' || event.key === 'S')) {
      event.stopPropagation()
      event.preventDefault()
      saveByButtonPress()

    //Control+A
    } else if (isAuthor && event.ctrlKey && (event.key === 'a' || event.key === 'A')) {
      return true

    //ENTER
    } else if (event.which === 13 || hasAndroidEnterKey()) { //13 is Enter key.
      processEnterKey(event, spanElement)

    //TAB
    } else if (event.which === 9) {
      //Note, if this is the editor and a tab is hit at the very beginning of the first sentence, then do the tab modification
      //  otherwise, let the editor tab into the next sentence.
      let elementNotTop = editorService.ensureChosenElementNotTop(event.target, chosenSegment)
      const tabParagraphOrList = editorService.isFirstPositionOfParagraphOrList(spanElement, event.shiftKey, chosenSegment)

      const listType = editorService.isListStart(spanElement)  //* or 1. are lists starters when hitting tab after those characters.
      if (listType) {
        event.preventDefault()
        setListChoice(listType)
      }

      //If NOT in a list but the cursor is in the first position of the first child
      // Else if in a list and (has a preceding tab element (span or paragraph) OR if there isn't a preceding tab element but the cursor is in the first child in the first position)
      if (isAuthor && !editorService.isCursorInsideList(event.target, spanElement) && tabParagraphOrList) {
        //Give the paragraph a text-indent (just one) then add to the margin-left which means block-indent (which is Word's convention)
        event.stopPropagation()
        event.preventDefault()
        let paragraph = editorService.setAsParagraphElement(elementNotTop)
        let focusElement = editorService.adjustParagraphForAuthor(paragraph, event.shiftKey)

        if (focusElement) setTimeout(() => {
          setCursorPosition(focusElement, focusElement, 0, 0)
          savedCursorPosition = editorService.saveCursorLocation(document.getElementById('editorDiv'))
        }, 500)

      } else if (tabParagraphOrList) { //This is for the author as well as the editor.  //editorService.isCursorInsideList(event.target)
        event.preventDefault()
        event.stopPropagation()
        savedCursorPosition = editorService.saveCursorLocation(document.getElementById('editorDiv'))

        editorService.adjustTab({
          chapterId: props.chapterId, 
          event,
          tabParagraphOrList,
          currentElement: spanElement,
          addOrUpdateEdit,
          isAuthor,
          getNextId,
          edits,
          personId: props.personId,
          editorName: props.editorName,
          getEditSegments: props.getEditSegments,
          workId: workSummary.workId,
          responseEdit: props.responseEdit,
          setIsInitEdits: props.setIsInitEdits,
          tabsData: props.tabsData,
          chapterListLevels,
          listLevelGeneral,
          addChapterListLevels,
          chosenSegment: props.chosenSegment,
          editLanguageId,
          setDeleteParagraphBreak: props.handleSetDeleteParagraphBreak,
        })
        //For the ADDTAB, the span element was changed to the paragraph in order to set the edit to the paragraph
        // but we need to set the cursor back into the span so that the user can conveniently continue with another tab or an edit.
        if (!isAuthor && spanElement.nodeName === 'SPAN') spanElement.contentEditable = 'true'
        spanElement.focus()
        setTimeout(() => setCursorPosition(spanElement, spanElement, 0, 0), 500)

        //Add one to the cursor position for the left move. I don't know how it is going to respond to the right move.
        let {end, newElement, start} = savedCursorPosition
        editorService.restoreCursorLocation(document.getElementById('editorDiv'), {
          end: ++end,
          newElement,
          start: ++start
        })
        saveRevision()

      } else if (!isAuthor) {
        event.preventDefault()
        event.stopPropagation()
        if (event.shiftKey) editorService.useTabToPreviousSentence(event.target)
        else editorService.useTabToNextSentence(event.target)
        skipRestoreCursorOnce = true
        const {spanId} = editorService.spanArrowKey(chosenSegment, chosenHTMLSegment, saveRevision) //The spanId is the one the cursor just moved to on TAB.

        if (isTextChanged && spanId) {
          let previousId = chosenSegment[chosenSegment.length - 1] && chosenSegment[chosenSegment.length - 1].id //The span Id that was just left with the TAB
          let span = document.querySelector(`[id="${previousId}"][data-type="TEXT"]`) //This span is the previous one that was just left after the TAB
          if (!span) span = document.querySelector(`[id="${previousId}"][data-type="ADDPARAGRAPHSENTENCE"]`) //This span is the previous one that was just left after the TAB
          if (span.dataset.type === 'ADDSENTENCE' || span.dataset.type === 'ADDPARAGRAPHSENTENCE') {
            if (span && span.nodeName === 'SPAN' && span && span.dataset && (span.dataset.type === 'ADDSENTENCE' || span.dataset.type === 'ADDPARAGRAPHSENTENCE')) {
              if (span.innerHTML === '&nbsp;&nbsp;' || span.innerHTML === '&nbsp;____&nbsp;') {
                span.innerHTML = '&nbsp;____&nbsp;'
              } else {
                let edit = edits && edits.length > 0 && edits.filter(m => m.personId === props.personId && Number(m.elementId) === Number(span.id) && span.dataset.type === 'ADDSENTENCE')[0]
                if (edit) {
                  if (span.innerHTML !== edit.text) {
                    let text = span.innerHTML
                    if (text.indexOf('&nbsp;') === 0) text = text.substring(6) //Strip off that extra space on the front which is used to give the user a space from the left to start type ing in the ____ blank

                    addOrUpdateEdit({
                      editSegmentId: edit.editSegmentId,
                      elementId: edit.elementId || spanId,
                      languageId: isTranslation ? isTranslation.languageId : workSummary.languageId_current || workSummary.languageId,
                      personId: props.personId,
                      firstName: props.editorName && props.editorName.firstName,
                      lastName: props.editorName && props.editorName.lastName,
                      chapterId: edit.chapterId,
                      editSegmentTypeId: 0, //This will be filled in on the server side by the type entered below
                      text,
                      type: edit.type, //This could be ADDSENTENCE or ADDPARAGRAPHSENTENCE
                      styleSnapshot: span && span.style.cssText,
                      authorTextSnapshot: '',
                    })
                  }
                }
              }
            }
            isTextChanged = false
          } else if (span.dataset.type === 'ADDLISTITEM') {
            if (span && span.nodeName === 'SPAN' && span && span.dataset && span.dataset.type === 'ADDLISTITEM') {
              if (span.innerHTML === '&nbsp;&nbsp;' || span.innerHTML === '&nbsp;____&nbsp;') {
                span.innerHTML = '&nbsp;____&nbsp;'
              } else {
                let edit = edits && edits.length > 0 && edits.filter(m => m.personId === props.personId && Number(m.elementId) === Number(span.id) && span.dataset.type === 'ADDLISTITEM')[0]
                if (edit) {
                  if (span.innerHTML !== edit.text) {
                    let text = span.innerHTML
                    if (text.indexOf('&nbsp;') === 0) text = text.substring(6) //Strip off that extra space on the front which is used to give the user a space from the left to start type ing in the ____ blank
                    //ToDo insert the before and after indicator as well as the elementId???

                    addOrUpdateEdit({
                      editSegmentId: edit.editSegmentId,
                      personId: props.personId,
                      firstName: props.editorName && props.editorName.firstName,
                      lastName: props.editorName && props.editorName.lastName,
                      chapterId: edit.chapterId,
                      elementId: edit.elementId,
                      languageId: isTranslation ? isTranslation.languageId : workSummary.languageId_current,
                      editSegmentTypeId: 0, //This will be filled in on the server side by the type entered below
                      text,
                      type: 'ADDLISTITEM',
                      addListItemSequence: edit.addListItemSequence,
                      authorTextSnapshot: '',
                    }, () => setTimeout(() => editorService.setCursorPositionByRecallAddListItem(edit.elementId, 1), 1000))
                  }
                }
              }
            }
          } else {
            let segment = segments && segments.length > 0 && segments.filter(m => m.elementId === Number(previousId))[0]
            let existEdit = edits && edits.length > 0 && edits.filter(m => m.elementId === Number(previousId) && m.personId === props.personId)[0]
            //We still need to send the edit although the text doesn't look like it changed because the edit exists already so it needs to be deleted from EditSegment db table because the editor might have changed the edit back.
            if (span && segment && (span.innerHTML !== segment.text || (span.innerHTML === segment.text && existEdit))) {
              let authorTextSnapshot = segments && segments.length > 0 && segments.filter(m => m.elementId === Number(span.id))[0]
              if (authorTextSnapshot) authorTextSnapshot = authorTextSnapshot.text
              event.stopPropagation()
              event.preventDefault()

              addOrUpdateEdit({
                editSegmentId: existEdit && existEdit.editSegmentId,
                editSegmentTypeId: 0, //This will be filled in on the server side by the type entered below
                personId: props.personId,
                firstName: props.editorName && props.editorName.firstName,
                lastName: props.editorName && props.editorName.lastName,
                chapterId: props.chapterId,
                elementId: Number(span.id),
                languageId: isTranslation ? isTranslation.languageId : workSummary.languageId_current,
                type: 'TEXT',
                text: span.innerHTML,
                authorTextSnapshot,
                addListItemSequence: editorService.getNextAddListItemSequence(span, existEdit),
                comment: existEdit && existEdit.comment,
              }, props.editorName)
            }
            handleSetChosenSegment(span)
          }
          //if (spanElement.id !== spanId)  //Don't do this since an ADDTAB span has the same elementId as the following sentence spanId
          editorService.setCurrentElementSpan(span, handleSetCurrentElement)  //This is for the parent page, EditReviewView
          savedCursorPosition = editorService.saveCursorLocation(document.getElementById('editorDiv'))
        }
        saveRevision()
        changeCounts()
      } else {
        event.stopPropagation()
        event.preventDefault()
        return false
      }
      //BACKSPACE or DELETE before a selection is cut out.
    } else if (selectionChildren && selectionChildren.length > 0 && (event.which === 8 || event.which === 46)) {  //Backspace is 8, Delete is 46
      //editorService.spliceRemainingEndsFromSelectDelete(event, selectionChildren)  //ToDo Use this again if it is necessary to bring together two sentences. Or create a merge/highlight function to bring two disparate spans together.
      updateChangeCounts()
      saveRevision()
      return false
    } else if (event.which === 8) {  //Backspace
      const tabParagraphOrList = editorService.isFirstPositionOfParagraphOrList(spanElement, true, chosenSegment)  //event.shiftKey is set to true since backspace is like a backwards TAB thing
      //If the element has a preceding tab element (span or paragraph) OR if there isn't a preceding tab element but the cursor is in the first child in the first position
      if (tabParagraphOrList) {
        event.preventDefault()
        event.stopPropagation()
        editorService.adjustTab({
          chapterId: props.chapterId,
          event,
          tabParagraphOrList,
          currentElement: spanElement,
          addOrUpdateEdit,
          isAuthor,
          getNextId,
          edits,
          personId: props.personId,
          editorName: props.editorName,
          getEditSegments: props.getEditSegments,
          workId: workSummary.workId,
          tabsData: props.tabsData,
          listLevelGeneral,
          addChapterListLevels,
          chosenSegment: props.chosenSegment,
          editLanguageId,
          responseEdit: props.responseEdit,
          setDeleteParagraphBreak: props.handleSetDeleteParagraphBreak,
        })
        saveRevision()
        updateChangeCounts()
      }
      //If the typing is happening in a parent-like element (P, UL or OL), then get a span started and return that as the new element
      //But first try to put the cursor in the first child of the LI. But if there are not any children, then add a new span.
    } else {
      if (spanElement && spanElement.nodeName !== 'SPAN') {
        let pointerElement = spanElement
        if (spanElement.nodeName === 'UL' || spanElement.nodeName === 'OL') {
          pointerElement = spanElement.firstChild //which should be an LI
        }
        if (pointerElement && pointerElement.nodeName === 'LI') {
          let setSpan = editorService.getListItemFirstChildWithId(pointerElement)
          if (!setSpan) {
            let setSpan = document.createElement('span')
            setSpan.id = getNextId()
            setSpan.setAttribute('style', 'font-family: Calibri; border-radius: 3px;')
            setSpan.setAttribute('data-type', 'TEXT')
            setSpan.innerHTML = '&nbsp;'
            if (pointerElement.firstChild) {
              pointerElement.insertBefore(setSpan, pointerElement.firstChild)
            } else {
              pointerElement.append(setSpan)
            }
            setSpan.focus()
            handleSetCurrentElement(setSpan)
            handleSetChosenSegment(setSpan)
            setCursorPosition(setSpan, setSpan, 0, 0)
            savedCursorPosition = editorService.saveCursorLocation(document.getElementById('editorDiv'))
          }
          saveRevision()
        }
      }
    }
  }

  const getSelectionText = () => {
    let text = "";
    if (window.getSelection) {
      text = window.getSelection().toString();
    } else if (document.selection && document.selection.type !== "Control") {
      text = document.selection.createRange().text;
    }
    return text;
  }

  const handleKeyUp = (event) => {
    let spanElement = event.target
    if (!spanElement || spanElement.id === '1' || spanElement.id === 'editorDiv') {
      spanElement = savedCursorPosition && savedCursorPosition.newElement ? savedCursorPosition.newElement : null
    }
    androidEnterKey.push(event.nativeEvent.code + ' - ' + event.nativeEvent.key + ' - ' + event.keyCode)
    // event.stopPropagation()  I believe that if these are on then the typing will not work.
    // event.preventDefault()
    savedCursorPosition = editorService.saveCursorLocation(document.getElementById('editorDiv'))
    props.setChosenMoveEdit() //Reset any moveEdit icons that could have been emphasized in the editor for display

    if (event.which === 27) { //ESCAPE key
      setContextMenu({show: false})
    }

    if (event.which !== 8) backspaceNodes = []
    if (event.which !== 46) deleteKeyNodes = []

    //8 is backspace
    if (event.which === 8) {
      //If a backspace is used consecutively to move through a P node and then into the leftside span node, join the two adjoining spans
      //The editor should be restricted by the segment which is contentEditable only
      if (isAuthor) {
        if (editorService.combineSpansIfBackspacing(backspaceNodes, savedCursorPosition, clearBackspaceNodes)) updateChangeCounts()
      } else {
        isTextChanged = true
      }
      saveRevision()
      updateChangeCounts()

      //Delete key
    } else if (event.which === 46) {
      //If a delete is used consecutively to move through a P node and then into the rightside span node, join the two adjoining spans
      //The editor should be restricted by the segment which is contentEditable only
      if (isAuthor) {
        if (editorService.combineSpansIfDeleteKeying(deleteKeyNodes, savedCursorPosition, clearDeleteKeyNodes)) updateChangeCounts()
      } else {
        isTextChanged = true
      }
      saveRevision()
      updateChangeCounts()

      //End and Home buttons; Arrows: left, up, right, down as well as pageDown, pageUp, End, Home
    } else if (event.which === 37 || event.which === 38 || event.which === 39 || event.which === 40 || event.which === 33 || event.which === 34 || event.which === 35 || event.which === 36) {
      if (event.shiftKey) return  //Keep going since this is a selection (highlight) move

      let isLeftArrow = event.which === 37
      let isRightArrow = event.which === 39
      let isEndKey = event.which === 35;
      let isHomeKey = event.which === 36;
      let isPageUpKey = event.which === 33;
      let isPageDownKey = event.which === 34;
      if (isPageUpKey || isPageDownKey) {
        event.stopPropagation()
        event.preventDefault()
      }

      if (isLeftArrow && editorService.getCursorPosition(event.target) === 0) {
        const previousSpan = editorService.useTabToPreviousSentence(event.target)
        handleSetCurrentElement(previousSpan)
        setCursorPosition(previousSpan, previousSpan, 0, 0)
        isTextChanged = editorService.checkSentenceForChange({ spanElement: event.target, segments, edits, editorName: props.editorName, isAuthor, addOrUpdateEdit, personId: props.personId, workSummary, editLanguageId, updateChangeCounts })
        if (isTextChanged) {
          isSurgicalTextChange =true
          event.target.style.backgroundColor = backgroundColors.editPending
          const tabViewElement = document.querySelector(`[id="${event.target.id + '~tabView'}"][data-type="TEXT"]`)
          if (tabViewElement) {
            tabViewElement.contentEditable = 'true'
            tabViewElement.style.backgroundColor = backgroundColors.editPending
            //tabViewElement.contentEditable = 'false'
          }
          isTextChanged = false
        }
      } else if (isRightArrow && !isAuthor) {
        const {atEnd} = isCursorAtStartOrEnd(event.target)
        if (atEnd) {
          const nextSpan = editorService.useTabToNextSentence(event.target)
          handleSetCurrentElement(nextSpan)
          isTextChanged = editorService.checkSentenceForChange({ spanElement: event.target, segments, edits, editorName: props.editorName, isAuthor, addOrUpdateEdit, personId: props.personId, workSummary, editLanguageId, updateChangeCounts })
          if (isTextChanged) {
            isSurgicalTextChange = true
            event.target.style.backgroundColor = backgroundColors.editPending
            const tabViewElement = document.querySelector(`[id="${event.target.id + '~tabView'}"][data-type="TEXT"]`)
            if (tabViewElement) {
              tabViewElement.contentEditable = 'true'
              tabViewElement.style.backgroundColor = backgroundColors.editPending
              tabViewElement.contentEditable = 'false'
            }
            isTextChanged = false
          }
        }
      }
      const spanArrowKeyResult = editorService.spanArrowKey(chosenSegment, chosenHTMLSegment, saveRevision, isEndKey, isHomeKey)
      //I'm not sure what this code is for. But it might be keeping us back from having the previous sentence updated. isEndKey will make the cursor jump, I believe, to the next one accidentally? It should only be the right arrow that should go to the next sentence
      if (!isAuthor && spanArrowKeyResult) {
        if (spanArrowKeyResult.spanId === chosenSegment[chosenSegment.length - 1] && chosenSegment[chosenSegment.length - 1].id) {
          return
        }
      }

      if (isAuthor && spanElement && spanElement.nodeName === 'P') {
        let paragraph = spanElement
        let previousElementId = paragraph.id
        //Check for any textnodes that have new text to be processed
        for (let p = 0; p < paragraph.children.length; p++) {
          if (paragraph.children[p].nodeName === 'SPAN' && paragraph.children[p].innerHTML.replace(/&nbsp;/g, '').length === 0) {
            let editorDiv = document.getElementById('editorDiv')
            const {newOuterHtml, lastNewId} = sentenceService.delineateSentences(previousElementId, paragraph.children[p].textContent, getNextId)
            editorDiv.innerHTML = editorDiv.innerHTML.replace(paragraph.children[p].textContent, newOuterHtml);
            previousElementId = lastNewId
          } else if (paragraph.children[p].id) {
            previousElementId = paragraph.children[p].id
          }
        }
      }

      if (spanArrowKeyResult) {
        const {spanId, spanHTML} = spanArrowKeyResult
        let newChosenId = chosenSegment[chosenSegment.length - 1] && chosenSegment[chosenSegment.length - 1].id
        if (spanId && Number(newChosenId) > 0) {
          let span = event.target //document.querySelector(`span[id="${spanId}"][data-type="TEXT"]`) //There are edits with this elementId that are not TEXT, such as tab changes
          //if (!span) span = document.querySelector(`span[id="${spanId}"][data-type="ADDPARAGRAPHSENTENCE"]`) 
          if (isTextChanged) {
            if (isAuthor) {
              editorService.processAuthorTextChanges(segments, event.target, getNextId) //I traded out newChosenId for event.target
            } else {
              let existEdit = edits && edits.length > 0 && edits.filter(m => m.elementId === Number(spanId) && m.personId === props.personId && m.type === span.dataset.type)[0]

              addOrUpdateEdit({
                editSegmentId: existEdit && existEdit.editSegmentId,
                personId: props.personId,
                firstName: props.editorName && props.editorName.firstName,
                lastName: props.editorName && props.editorName.lastName,
                chapterId: props.chapterId,
                languageId: isTranslation ? isTranslation.languageId : workSummary.languageId_current,
                elementId: spanId,
                editSegmentTypeId: 0, //This will be filled in on the server side by the type entered below
                previousElementId: span && span.previousElementId,
                type: 'TEXT',
                text: spanHTML,
                authorTextSnapshot: span && span.innerHTML,
                comment: existEdit && existEdit.comment,
                subSequence: existEdit && existEdit.subSequence,
                deleteInSeries: existEdit && existEdit.deleteInSeries,
              })
            }
            saveRevision()
            isTextChanged = false
          }

          handleSetChosenSegment(span)
          if (isTextChanged) updateChangeCounts()
          isTextChanged = false
        }
      }

      //CTRL + Z
    } else if (event.ctrlKey && (event.key === 'z' || event.key === 'Z')) {
      undo()

      //CTRL + B
    } else if (event.ctrlKey && (event.key === 'b' || event.key === 'B')) {
      editorService.setFormatStyleHtml('bold', getNextId)
      updateChangeCounts()
      saveRevision()

      //CTRL + I
    } else if (event.ctrlKey && (event.key === 'i' || event.key === 'I')) {
      editorService.setFormatStyleHtml('italic', getNextId)
      updateChangeCounts()
      saveRevision()

      //CTRL + U
    } else if (event.ctrlKey && (event.key === 'u' || event.key === 'U')) {
      editorService.setFormatStyleHtml('underline', getNextId)
      updateChangeCounts()
      saveRevision()

      //CTRL + S  (save)
    } else if (event.ctrlKey && (event.key === 's' || event.key === 'S')) {
      if (isAuthor) {
        saveByButtonPress()
        return
      }

      //TAB
    } else if (!(event.which === 9 && !isAuthor)) {
      //If the current node that the user is in is NOT a span but a new textnode,
      //  Make a new segment and
      //  Record the new chosenSegment
      //  Pick up any text changes such as a new sentence out of a controlled segment (including multiple sentences in the new textnode that might have just been picked up.
      //else
      //  set isTextChanged = true
      //  NOTE: This was causing the cursor to be lost and a setCursorLocation was choking on the node not being valid.
      // let range = window.getSelection().getRangeAt(0)
      // let parentElement = range.startContainer.parentElement
      // if (!(parentElement.nodeName === 'SPAN' && !isNaN(parentElement.id))) {
      //   editorService.processAuthorNewTextNode(range, chosenSegment, handleSetChosenSegment, getNextId)
      //   savedCursorPosition = editorService.saveCursorLocation(document.getElementById('editorDiv'))
      //   skipRestoreCursorOnce = true
      // } else {
      //   isTextChanged = true
      // }
      if (spanElement && spanElement.dataset && (spanElement.dataset.type === 'ADDSENTENCE' || spanElement.dataset.type === 'ADDPARAGRAPHSENTENCE' || spanElement.dataset.type === 'ADDLISTITEM')) {
        //Take out the beginning &nbsp; as many times as it might exist
        const hasOneCharacter = hasOnlyOneCharacter(spanElement.innerHTML)
        if (hasOneCharacter) {
          // while (spanElement.innerHTML.indexOf('&nbsp;') === 0 || spanElement.innerHTML.indexOf(' ') === 0) {
          //   spanElement.innerHTML = spanElement.innerHTML.indexOf('&nbsp;') === 0 ? spanElement.innerHTML.substring(6) : spanElement.innerHTML.indexOf(' ') === 0 ? spanElement.innerHTML.substring(1) : spanElement.innerHTML
          // }
          spanElement.innerHTML = hasOneCharacter
          moveCursorRightOneCharacter(spanElement)
        }
      }
    } else {
      //This could be the author or the editor since we give the author the convenience of adding list items for themselves.
      if (spanElement.dataset && spanElement.dataset.type === 'ADDLISTITEM') {
        const hasOneCharacter = hasOnlyOneCharacter(spanElement.innerHTML)
        if (hasOneCharacter) {
          spanElement.innerHTML = hasOneCharacter
          moveCursorRightOneCharacter(spanElement)
        }
      } else {
        //Check to make sure that the chosenSegment has a valid entry in it
        //It is possible that the user clicked into a paragraph or even editorDiv, but it moves the cursor inside a span.
        let latestSegment = chosenSegment[chosenSegment.length - 1]
        //let element = document.querySelectorAll(`[id="${chosenSegment.id}"][data-type="TEXT"]`)[0]
        if (latestSegment && Number(latestSegment.id) === Number(spanElement.id) && !latestSegment.type && spanElement.nodeName === 'SPAN' && spanElement.dataset.type === 'TEXT') {
          let item = {
            id: spanElement.id,
            type: spanElement.dataset.type,
            addListItemSequence: spanElement.dataset.addListItemSequence ? spanElement.dataset.addListItemSequence : ''
          }
          handleSetChosenSegment(chosenSegment.concat(item))
          saveRevision()
        }
      }
      updateChangeCounts()
    }

    if (skipRestoreCursorOnce) { //This is used for the tabbing to the next sentence and setting the cursor in place when it is the editor
      skipRestoreCursorOnce = false
      //} else if (spanElement && spanElement.id !== 'editorDiv') {
      //editorService.restoreCursorLocation(document.getElementById('editorDiv'), savedCursorPosition)
    }
    androidEnterKey = []
  }

  const hasOnlyOneCharacter = (innerHTML) => {
    const remainingText = innerHTML.replace(/&nbsp;/g, ''). replace(/_/g,'').replace(/ /g, '')
    if (remainingText.length === 1) return remainingText
    return false
  }

  const moveCursorRightOneCharacter = (spanElement) => {
    const range = document.createRange();
    const selection = window.getSelection();
    const textNode = spanElement.childNodes[0]; // Assume the text is in the first child node
    const currentOffset = selection.focusOffset;
    range.setStart(textNode, currentOffset + 1);
    range.setEnd(textNode, currentOffset + 1);
    selection.removeAllRanges();
    selection.addRange(range);
    spanElement.focus();
  }

  const saveRevision = () => {
    let newRevisions = [...revisions]
    const editorDiv = document.getElementById("editorDiv")
    if (editorDiv) {
      newRevisions.push(editorDiv.innerHTML)
      setRevisions(newRevisions)
    }
  }

  const undo = () => {
    let newRevisions = [...revisions]
    const editorDiv = document.getElementById("editorDiv")
    editorDiv.focus()
    if (revisions.length > 0) {
      editorDiv.innerHTML = newRevisions[newRevisions.length - 1]
    } else {
      editorDiv.innerHTML = ''
    }
    if (editorDiv.innerHTML === '') {
      isAuthor
        ? editorService.setSegments('editorDiv', segments, workSummary, edits, isAuthor, props.personId, props.editorName, props.tabsData, getNextId, chapterListLevels, addChapterListLevels, listLevelGeneral, chosenTab)
        : editorService.setSegmentsWithEdits('editorDiv', [...segments], edits, props.personId, workSummary, props.editorName, props.tabsData, getNextId, chapterListLevels, addChapterListLevels, listLevelGeneral, chosenTab)
    }
    newRevisions.pop()
    setRevisions(newRevisions)
    updateChangeCounts()
  }

  const moveCursor = (spaces = 1, savedCursorPosition) => {
    return {
      start: savedCursorPosition.start + 1,
      end: savedCursorPosition.end + 1,
      newElement: savedCursorPosition.newElement
    }
  }

  const setFormatChoice = (formatType, fontValue = '') => {
    editorService.setFormatStyleHtml(formatType, saveRevision, getNextId, fontValue)
    editorService.restoreCursorLocation(document.getElementById('editorDiv'), moveCursor(1, savedCursorPosition))
    updateChangeCounts()
  }

  const setParagraphAlign = (formatValue) => {
    editorService.setParagraphTextAlign(formatValue, chosenSegment)
    editorService.restoreCursorLocation(document.getElementById('editorDiv'), savedCursorPosition)
    updateChangeCounts()
  }

  const setParagraphIndentChoice = (indentType, value) => {
    editorService.setParagraphIndent(indentType, value, chosenSegment, chosenSegment)
    editorService.restoreCursorLocation(document.getElementById('editorDiv'), savedCursorPosition)
    updateChangeCounts()
  }

  const setListChoice = (listType) => {
    let elementData = chosenSegment[chosenSegment.length - 1]
    let currentElement = document.getElementById(elementData.id)
    editorService.setListHtml(currentElement, listType, saveRevision, getNextId, props.listLevelGeneral, handleSetChosenSegment, savedCursorPosition)
    setCursorPosition()
    editorService.restoreCursorLocation(document.getElementById('editorDiv'), moveCursor(1, savedCursorPosition))
    updateChangeCounts()
    saveRevision()
  }

  const preventDragDrop = event => {
    event.preventDefault()
    return false
  }

  const saveWebsiteLink = (websiteLink, linkDisplayText) => {
    editorService.restoreCursorLocation(document.getElementById('editorDiv'), savedCursorPosition)
    editorService.addWebsiteEntryElement(websiteLink, linkDisplayText)
    setIsOpenLinkEntry(false)
    updateChangeCounts()
  }

  const handleDownloadChoice = (choice, event) => {
    event.preventDefault()
    event.stopPropagation()
    let htmlString = document.getElementById('editorDiv').innerHTML
    let wordConversion = {
      htmlString,
      personId: props.personId,
      workId: workSummary.workId,
      languageId: workSummary.languageId || 1,
      conversionType: choice
    }
    let getWorkChoice = props.downloadOptions.filter(m => m.value === choice)[0]
    localStorage.setItem('workDownloadReady', JSON.stringify(getWorkChoice))
    setShowDownloadReady(true)
    props.downloadWork(wordConversion)
  }

  const closeModalDownloadDocx = () => {
    //This is necessary because the download is refreshing the page and losing local state. So we accumulate a localStorage, a global variable and a local state.
    //workDownloadReady is the conversion type which is also the file extension.
    props.deleteFile(props.personId, workSummary.workId, workDownloadReady.value, workSummary.languageId || 1) //This will trigger the WORK_DOWNLOAD_READY by setting it to blank which will close this modal dialog.
    localStorage.setItem('workDownloadReady', null)
    workDownloadReady = null
    setShowDownloadReady(false)
  }

  const handleFontNameChoice = (fontValue) => {
    setFormatChoice('fontName', fontValue)
    updateChangeCounts()
  }

  const handleFontSizeChoice = (fontValue) => {
    setFormatChoice('fontSize', fontValue)
    updateChangeCounts()
  }

  const handleFontColorChoice = (colorValue) => {
    setFormatChoice('fontColor', colorValue)
    updateChangeCounts()
  }

  const handlePasteAuthor = (event) => {
    // event.stopPropagation()
    //savedCursorPosition = editorService.saveCursorLocation(document.getElementById('editorDiv'))
    let data = event.clipboardData || window.clipboardData
    let pasteHtml
    let pastePlain
    let isHtml = false
    //When the Word-copied HTML comes through it gets broken up wrong.
    // for(let i = 0; i < data.items.length; i++) {
    // 	if (data.items[i].type === 'text/html') {
    //     isHtml = true
    //     pasteHtml = data.getData('text/html')
    //   }
    // }
    // if (isHtml && !pasteHtml) {
    //pasteHtml = data.getData('text/html')
    // } else {
    pastePlain = data.getData('text/plain')
    // }
    event.preventDefault()
    if (pasteHtml) {
      if (event.target.nodeName === 'LI' || event.target.parentElement.nodeName === 'LI') {
        editorService.setPastedHtmlAuthorIntoList(event.target, pasteHtml, chosenSegment, getNextId, savedCursorPosition)
      } else {
        editorService.setPastedHtmlAuthorIntoSentences(event.target, pasteHtml, chosenSegment, getNextId, savedCursorPosition)
      }
    } else {
      if (event.target.nodeName === 'LI' || event.target.parentElement.nodeName === 'LI') {
        editorService.setPastedPlainAuthorIntoList(event.target, pastePlain, chosenSegment, getNextId, savedCursorPosition)
      } else {
        editorService.setPastedPlainAuthorNotList(event.target, pastePlain, chosenSegment, getNextId, savedCursorPosition)
      }
    }
    let saveSegments = editorService.saveSegmentsToDB(segments, props.chapterId, getNextId)
    props.addOrUpdateSegments(props.personId, saveSegments)
    setSaveWorkSpaceTime(new Date())
    editorService.restoreCursorLocation(document.getElementById('editorDiv'), savedCursorPosition)
    saveRevision()
  }

  const handlePasteEditor = (event) => {
    event.stopPropagation()
    event.preventDefault()
    savedCursorPosition = editorService.saveCursorLocation(document.getElementById('editorDiv'))
    editorService.setPastedHtmlEditor(event, currentElement)
    editorService.restoreCursorLocation(document.getElementById('editorDiv'), savedCursorPosition)
    saveRevision()
  }

  const clearDeleteKeyNodes = () => {
    deleteKeyNodes = []
  }

  const clearBackspaceNodes = () => {
    backspaceNodes = []
  }

  const closeContextMenu = () => {
    setContextMenu(initialContextMenu)
  }

  const handleSetEditChosen = (elementId, label) => {
    unHighlightChosenSegments()
    let element
    if (label.indexOf('Sentence edit') > -1) {
      element = document.querySelector(`span[id="${elementId}"][data-type="TEXT"]`)
      if (element) {
        element.style.backgroundColor = backgroundColors.currentFocus
        handleSetCurrentElement(element)
        handleSetChosenSegment(element)
        scrollDocumentToMatch(element, element.id)
        //Include the tabView
        let tabViewElement = document.querySelector(`span[id="${elementId}~tabView"][data-type="TEXT"]`)
        if (tabViewElement) tabViewElement.style.backgroundColor = backgroundColors.currentFocus
      }
    } else if (label.indexOf('Move sentences') > -1) {
      element = document.querySelectorAll(`img[data-span-id="${elementId}"][data-type="MOVE"]`)
      for (let i = 0; i < element.length; i++) {
        if (i === 0) {
          handleSetCurrentElement(element[i])
          handleSetChosenSegment(element[i])
          scrollDocumentToMatch(element[i], element[i].dataset.spanId)
        }
        element[i].style.backgroundColor = backgroundColors.currentFocus
      }
      //Include the tabView
      element = document.querySelectorAll(`img[data-span-id="${elementId}~tabView"][data-type="MOVE"]`)
      for (let i = 0; i < element.length; i++) {
        element[i].style.backgroundColor = backgroundColors.currentFocus
      }
    } else {
      let dataType
      if (label.indexOf('Add paragraph break') > -1) {
        dataType = 'ADDPARAGRAPH'
      } else if (label.indexOf('Delete paragraph break') > -1) {
        dataType = 'DELETEPARAGRAPH'
      } else if (label.indexOf('Add sentence') > -1) {
        dataType = 'ADDSENTENCE'
      } else if (label.indexOf('Delete sentence') > -1) {
        dataType = 'DELETESENTENCE'
      } else if (label.indexOf('Add list item') > -1) {
        dataType = 'ADDLISTITEM'
      // } else if (label.indexOf('Add list') > -1) {
      //   dataType = 'ADDLIST'  Not yet implemented
      } else if (label.indexOf('Delete list item') > -1) {
        dataType = 'DELETELISTITEM'
      } else if (label.indexOf('Re-order list items') > -1) {
        dataType = 'REORDERLISTITEMS'
      } else if (label.indexOf('Add tab') > -1) {
        dataType = 'ADDTAB'
      } else if (label.indexOf('Delete tab') > -1) {
        dataType = 'DELETETAB'
      } else if (label.indexOf('Move list item left') > -1) {
        dataType = 'LISTLEVELMINUS'
      } else if (label.indexOf('Move list item right') > -1) {
        dataType = 'LISTLEVELPLUS'
      }

      element = document.querySelector(`img[id="${elementId}"][data-type="${dataType}"]`)
      if (!element) element = document.querySelector(`img[data-span-id="${elementId}"][data-type="${dataType}"]`)
      if (element) {
        element.style.backgroundColor = backgroundColors.currentFocus
        //Include the tabView
        let tabViewElement = document.querySelector(`img[id="${elementId}~tabView"][data-type="${dataType}"]`)
        if (!tabViewElement) tabViewElement = document.querySelector(`img[data-span-id="${elementId}~tabView"][data-type="${dataType}"]`)
        if (tabViewElement) tabViewElement.style.backgroundColor = backgroundColors.currentFocus
        handleSetCurrentElement(element)
        handleSetChosenSegment(element)
        scrollDocumentToMatch(element, element.id)
      }
    }
    setEditChosen(elementId)
  }

  const scrollDocumentToMatch = (paramElement, paramElementId) => {
    let elementId = paramElementId ? paramElementId : paramElement ? paramElement.id : chosenSegment && chosenSegment.length > 0 ? chosenSegment[chosenSegment.length - 1] && chosenSegment[chosenSegment.length - 1].id : ''
    if (!elementId || elementId === '0') {
      setEntryError('Please choose a sentence before scrolling view locations.')
    } else {
      const editorElement = document.querySelectorAll(`[id="${elementId}"]`)[0]  //[data-type="TEXT"]
      const tabViewElement = document.querySelectorAll(`[id="${elementId + '~tabView'}"]`)[0] //[data-type="TEXT"]
      if (tabViewElement) tabViewElement.scrollIntoView({behavior: "smooth", block: "center"});
      if (editorElement) setTimeout(() => editorElement.scrollIntoView({behavior: "smooth", block: "center"}), 500);
    }
  }

  const getEditsCount = () => {
    let editCount = currentElement && edits && edits.length > 0 && edits.filter(m => Number(m.elementId) === Number(currentElement.id) && (m.type === 'TEXT' || m.type === 'ADDSENTENCE'))
    return editCount ? editCount.length : 0
  }

  const setEditorDivStyle = () => {
    if (!(!isMobile || (isMobile && (chosenTab === props.personId || chosenTab == '0')))) {
      return styles.hideEditorDiv
    } else if (isMobile) {
      return styles.editorDivLeftMobile
    } else {
      return styles.editorDivLeft
    }
  }

  return (
    <div className={isMobile ? styles.containerMobile : styles.container}>
      {isAuthor &&
        <div className={styles.buttonRow}>
          {/*{workSummary.isHomework && !workSummary.isHomeworkSubmitted && !localHomeworkSubmitDate &&*/}
          {/*  <ButtonWithIcon label={<div className={styles.lineHeight}>Submit<br/><div className={styles.smallText}>Homework</div></div>}*/}
          {/*                  icon=  {'checkmark0'} onClick={handlePenspringHomeworkOpen}/>*/}
          {/*}*/}
          {/*{workSummary.isHomework && (workSummary.isHomeworkSubmitted || localHomeworkSubmitDate) &&*/}
          {/*  <TextDisplay label={'Homework submitted'} text={<DateMoment date={workSummary.homeworkSubmittedDate || localHomeworkSubmitDate}*/}
          {/*                                                              format={'D MMM  h:mm a'} minusHours={0}/>} nowrap={true}/>*/}
          {/*}*/}

          {/*{workSummary.isDistributableAssignment && !workSummary.publishedDate && !localDistributeSubmitDate &&*/}
          {/*  <ButtonWithIcon label={<div className={styles.lineHeight}>Publish<br/><div className={styles.smallText}>Assignment</div></div>}*/}
          {/*                  icon={'earth'} onClick={handlePenspringDistributeOpen}/>*/}
          {/*}*/}
          {/*{workSummary.isDistributableAssignment && (workSummary.publishedDate || localDistributeSubmitDate) &&*/}
          {/*  <TextDisplay label={'Published assignment'}text={<DateMoment date={workSummary.publishedDate || localDistributeSubmitDate}*/}
          {/*                                                               format={'D MMM  h:mm a'} minusHours={6}/>} nowrap={true}/>*/}
          {/*}*/}
        </div>
      }
      {isMobile &&
        <div className={styles.rowMobileTools}>
          <div className={styles.sentenceEdits} onClick={() => props.setIsOpenSlideOut(true)}>
            <div className={styles.smallText}>CURRENT</div>
            <div className={styles.smallText}>SENTENCE</div>
            <div className={styles.row}>
              <div className={styles.smallText}>EDITS</div>
              <div className={styles.editCount}>{getEditsCount()}</div>
            </div>
          </div>
          <EditorDivFormatControlsMobile 
            setFormatChoice={setFormatChoice}
            changeCounts={changeCounts}
            setParagraphAlign={setParagraphAlign}
            setListChoice={setListChoice}
            undo={undo}
            isAuthor={isAuthor}
            setParagraphIndentChoice={setParagraphIndentChoice}
            setIsOpenLinkEntry={setIsOpenLinkEntry}
            workSummary={workSummary}
            saveWorkSpaceTime={saveWorkSpaceTime}
            isOpenLinkEntry={isOpenLinkEntry}
            handleSetEditChosen={handleSetEditChosen}
            handleDownloadChoice={handleDownloadChoice}
            handleFontNameChoice={handleFontNameChoice}
            editChosen={editChosen}
            editOptions={props.editOptions}
            tabViewElement={document.getElementById('tabView')}
            scrollDocumentToMatch={scrollDocumentToMatch}
            saveByButtonPress={saveByButtonPress}
            isTranslation={isTranslation} 
            personId={props.personId} />

          <ToggleBoardMobile isAuthor={isAuthor}
            clearAllEditTypes={props.clearAllEditTypes}
            editTrackChanges={props.editTrackChanges} setEditTrackChanges={props.setEditTrackChanges}
            keepCommentOn={props.keepCommentOn} setKeepCommentOn={props.setKeepCommentOn}
            moveSentences={props.moveSentences} setMoveSentences={props.setMoveSentences}
            addParagraphSentence={props.addParagraphSentence} setAddParagraphSentence={props.setAddParagraphSentence}
            addSentence={props.addSentence} setAddSentence={props.setAddSentence}
            deleteSentence={props.deleteSentence} setDeleteSentence={props.setDeleteSentence}
            addListItem={props.addListItem} setAddListItem={props.setAddListItem}
            deleteListItem={props.deleteListItem} setDeleteListItem={props.setDeleteListItem}
            reorderListItems={props.reorderListItems} setReorderListItems={props.setReorderListItems}
            addParagraphBreak={props.addParagraphBreak} setAddParagraphBreak={props.setAddParagraphBreak}
            deleteParagraphBreak={props.deleteParagraphBreak} setDeleteParagraphBreak={props.setDeleteParagraphBreak}
            showEditorFullText={props.showEditorFullText} setShowEditorFullText={props.setShowEditorFullText}
            moveTranslationToEditor={props.moveTranslationToEditor} setMoveTranslationToEditor={props.setMoveTranslationToEditor}
            goToNextSentence={props.goToNextSentence} setGoToNextSentence={props.setGoToNextSentence}
            hasListStructure={props.hasListStructure}
            isTranslation={isTranslation}
            personConfig={props.personConfig} />

          <div className={classes(styles.tabPage, styles.row)}>
            <TabPage 
              tabsData={tabsData}
              onClick={handleEditorTabChosen}
              navClose={tabNav}
              navText={navText}
              chosenTab={chosenTab}
              showZeroCount={true}
              editOptions={props.editOptions}
              handleSetEditChosen={handleSetEditChosen}
              editChosen={editChosen}
              editorName={props.editorName}
              userPersonId={props.personId}
              showListAfterQuantity={6}/>
          </div>
          <div className={styles.editListChoice}>
            <EditListChoice editOptions={props.editOptions} handleSetEditChosen={handleSetEditChosen} editChosen={editChosen} scrollDocumentToMatch={scrollDocumentToMatch} />
          </div>
          {chosenTab === workSummary.authorPersonId && workSummary.authorPersonId === props.personId && 
            <EditorSaveButton changeCounts={changeCounts} label={'UPDATE'} saveWorkSpaceTime={saveWorkSpaceTime} saveByButtonPress={saveByButtonPress} addClassName={styles.editorSaveButton}/>
          }
        </div>
      }
      <div className={styles.rowEditReview}>
        <div className={isMobile ? '' : styles.showEditorDiv}>
          {!isMobile &&
            <EditorDivFormatControls 
              setFormatChoice={setFormatChoice}
              changeCounts={changeCounts}
              setParagraphAlign={setParagraphAlign}
              setListChoice={setListChoice}
              undo={undo}
              isAuthor={isAuthor}
              setParagraphIndentChoice={setParagraphIndentChoice}
              setIsOpenLinkEntry={setIsOpenLinkEntry}
              workSummary={workSummary}
              saveWorkSpaceTime={saveWorkSpaceTime}
              isOpenLinkEntry={isOpenLinkEntry}
              handleSetEditChosen={handleSetEditChosen}
              handleDownloadChoice={handleDownloadChoice}
              handleFontNameChoice={handleFontNameChoice}
              editChosen={editChosen}
              editOptions={props.editOptions}
              tabViewElement={document.getElementById('tabView')}
              scrollDocumentToMatch={scrollDocumentToMatch}
              saveByButtonPress={saveByButtonPress}
              isTranslation={isTranslation} personId={props.personId} />
          }
          <div className={setEditorDivStyle()}>
            <div id="editorDiv"
              className={isMobile ? styles.editorDivMobile : styles.editorDiv}
              contentEditable={workSummary.authorPersonId === props.personId ? 'true' : 'false'}
              spellCheck={'true'}
              onKeyUp={handleKeyUp}
              onKeyDown={handleKeyDOWN}
              onMouseUp={(event) => handleMouseUp(event, true)}
              onDragStart={preventDragDrop}
              onPaste={isAuthor ? handlePasteAuthor : handlePasteEditor}
              // onTouchStart={handleTouchStart}
              // onTouchEnd={handleTouchEnd}
            />
          </div>
          </div>
        {!isMobile &&
          <div className={styles.flexShrink}>
            {props.showEditorFullText && (personConfig.showEditorFullText || props.showEditorFullText) &&
              <EditorFullTextView 
                edits={edits}
                handleEditorTabChosen={handleEditorTabChosen}
                tabNav={tabNav}
                tabsData={tabsData}
                navText={navText}
                chosenTab={chosenTab}
                handleKeyDOWNEditor={handleKeyDOWNEditor}
                handleMouseUp={handleMouseUp}/>
            }
          </div>
        }
      </div>
      <div className={isMobile && props.personId !== chosenTab ? styles.showTabView : styles.hideTabView}>
        <div className={styles.tabInstructions}>Changes cannot be made in this view</div>
        <div id="tabView" contentEditable={false}
             onKeyDown={handleKeyDOWNEditor}
             onMouseUp={(event) => handleMouseUp(event, false)} />
      </div>
      {contextMenu.show &&
        <AuthorContextMenu 
          {...props}
          x={contextMenu.x}
          y={contextMenu.y}
          handleSetCurrentElement={handleSetCurrentElement}
          closeContextMenu={closeContextMenu}
          setContextMenu={setContextMenu}
          initialContextMenu={initialContextMenu}/>
      }
      <WebsiteLinkEntry isOpen={isOpenLinkEntry} onSave={saveWebsiteLink} onCancel={() => setIsOpenLinkEntry(false)}/>
      <MessageModal 
        show={showInstructions} handleClose={() => setShowInstructions(false)}
        heading={`Editor Instructions`}
        explain={props.instructionText}
        onClick={() => setShowInstructions(false)}/>
      <MessageModal 
        show={showDownloadReady}
        handleClose={closeModalDownloadDocx}
        heading={`Download as ${workDownloadReady && workDownloadReady.title}`}
        explain={`Microsoft&reg; Word&reg; &nbsp;&nbsp;&nbsp;&nbsp;${workSummary && workSummary.title}`}
        downloadFilePath={`../wordDocs/${workSummary && workSummary.title}${workSummary && workSummary.workId && workSummary.workId.substring(0, 6)}${workSummary && workSummary.languageId || 1}.${workDownloadReady && workDownloadReady.value}`}
        onClick={closeModalDownloadDocx} isConfirmType/>
      <MessageModal 
        displayTempMessage
        explain={entryError}
        setEntryError={setEntryError}
        handleClose={() => setEntryError('')}
        heading={`Entry Error!`}
        onClick={() => setEntryError('')}/>
      <MessageModal
        show={showAddParagraphSentenceInsideList} handleClose={() => setShowAddParagraphSentenceInsideList(false)}
        heading={``}
        explain={`Adding a paragraph and sentence should not be used on an outline. Use 'Add List Item' instead.`}
        onClick={() => setShowAddParagraphSentenceInsideList(false)} />

      
    </div>
  );
}

export default AuthoringEditor

