import * as types from './actionTypes.js';
import * as loggedIn from './logged-in';
import * as works from './works';
//import * as workFileTreeSubContents from './work-file-tree-sub-contents';
import * as contacts from './contacts';
import * as workFilter from './work-filter.js';
import * as contactFilter from './contact-filter.js';
import * as languageList from './language-list';
import * as genreList from './genre-list';
import * as groupTypes from './group-types';
import * as declineIdleList from './decline-idle-list';
import * as colorsEditor from './colors-editor';
import * as workStatusList from './work-status-list';
import * as editSeverityList from './edit-severity-list';
import * as personConfig from './person-config';
import * as editorInviteResponse from './editor-invite-response';
import * as editorInvitePending from './editor-invite-pending';
import { apiHost } from '../api_host.js';

export function loginError(error) {
	return { error, type: types.LOGGED_FAILED };
}

export function setLoggedSuccessfully(person) {
	return dispatch => dispatch({ type: types.LOGGED_SUCCESSFULLY, payload: person })
}

export function setLoggedIn(isLoggedIn) {
	return dispatch => dispatch({ type: types.LOGGED_IN_SET, payload: isLoggedIn })
}

export function logout() {
	return { type: types.LOGGED_OUT };
}

export function loginRequest(email, password) {
	const user = { email: email, password: password };
	return { user, type: types.LOGIN_ATTEMPT };
}

export function forgotPassword(emailAddress, phone) {
	emailAddress = emailAddress ? emailAddress : 'empty';
	phone = phone ? phone : 'empty';

	return dispatch =>
		fetch(`${apiHost}ebi/person/penspring/password/reset/${emailAddress}/${phone}`, {
			method: 'post',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
		})
}

export function forgotUsername(emailAddress, phone) {
	emailAddress = emailAddress ? emailAddress : 'empty';
	phone = phone ? phone : 'empty';

	return dispatch =>
		fetch(`${apiHost}ebi/person/penspring/username/reminder/${emailAddress}/${phone}`, {
			method: 'post',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
		})
}


export function setResetPasswordResponse(password, runFunction) {
	return dispatch =>
		fetch(`${apiHost}ebi/person/resetPassword/${password}`, {
			method: 'post',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json();
				} else {
					throw new Error('Invalid Login');
				}
			})
			.then(response => {
				dispatch({ type: types.PASSWORD_RESET_COMPLETE, payload: response });
				if (response === 'Ok') runFunction()
			})
}

export function login(userData, inviteResponse, salta, sendTo = '/myWorks', authLogin) { //Only sendTo if this is a salta function to get to a specific document as a student or teacher.
	return dispatch =>
		fetch(`${apiHost}ebi/person/login/penspring`, {
			method: 'post',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
			body: JSON.stringify(userData),
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json();
				} else {
					throw new Error('Invalid Login');
				}
			})
			.then(response => {
				if (response.loginPerson.fullName === "MATCHING EMAIL ADDRESS FOUND") {
					dispatch({ type: types.LOGIN_MATCHING_RECORD, payload: "MATCHING EMAIL ADDRESS FOUND" });
				} else if (response.loginPerson.fullName === "NOTVALID") {
					dispatch({ type: types.LOGGED_FAILED });
				} else if (salta === 'salta') {
					localStorage.setItem("authToken", JSON.stringify(response.token).replace(/"/g, ''))
					window.location = sendTo ? sendTo : '/myWorks';
				} else {
					authLogin && authLogin(response.loginPerson);
					localStorage.setItem("authToken", JSON.stringify(response.token).replace(/"/g, ''))
					localStorage.setItem("person", JSON.stringify(response.loginPerson))
					dispatch(initRecords(response.loginPerson, sendTo, inviteResponse));
				}
			}
		)
		.catch(() => {
			dispatch({ type: types.LOGGED_FAILED });
		})
}

export function initRecords(loginPerson, sendTo = '', inviteResponse = '') {
	return dispatch => {
		let personId = loginPerson.personId;
		dispatch({ type: types.LOGGED_SUCCESSFULLY, payload: loginPerson });
		dispatch(loggedIn.setLoggedIn(true));
		// dispatch(contacts.getContacts(personId));
		// dispatch(works.init(personId));
		// dispatch(workFileTreeSubContents.getMyWorks(personId));
		// dispatch(works.initWorkIdCurrent(personId));
		// dispatch(groups.init(personId));
		// dispatch(groups.initGroupsIdCurrent(personId));
		// dispatch(personConfig.init(personId));
		// dispatch(workFilter.init(personId));
		// dispatch(contactFilter.init(personId));
		// dispatch(langu\ageList.getLanguages());
		// dispatch(genreList.init());
		// dispatch(groupTypes.init());
		// dispatch(declineIdleList.init());
		// dispatch(colorsEditor.init());
		// dispatch(workStatusList.init());
		// dispatch(editSeverityList.init());
		// dispatch(editorInvitePending.getInvitesPending(personId)); //dispatch(worksTreeData.init(personId))
		if (inviteResponse && inviteResponse.inviteCode) dispatch(editorInviteResponse.getEditorInviteWorkAssign(personId, inviteResponse));
		if (sendTo) window.location = sendTo;
	}
}
