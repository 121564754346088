import React, {useState} from 'react';
import {useNavigate} from 'react-router';
import globalStyles from '../../utils/globalStyles.module.css';
import styles from './GroupMemberUpdateView.module.css';
import classes from 'classnames';
import InputText from '../../components/InputText';
import OneFJefFooter from '../../components/OneFJefFooter';

//This page is different out of necessity because a group member can have its data manipulated by the teacher.  But be aware that them
//  PersonGroupAssign table has its own copy of the first name, last name, email address, phone, and internal member id.  The user can
//  change their own name for their own record on Penspring and continue to use Penspring at their own discretion.  This is particularly
//  helpful, too, in class arrangements when the names of the people need to be hidden from other students. An arbitrary nickname can be given.

function GroupMemberUpdateView(props) {
  const {group} = props;
  const navigate = useNavigate()

  const [isUserComplete, setIsUserComplete] = useState(false)
  const [errorEmailAddress, setErrorEmailAddress] = useState('')
  const [errorFirstName, setErrorFirstName] = useState('')
  const [user, setUser] = useState(props.member ? props.member : {
    firstName: '',
    lastName: '',
    memberId: '',
    emailAddress: '',
    phone: '',
  })

  const changeUser = (event) => {
    const field = event.target.name;
    user[field] = event.target.value;
    field === "firstName" && setErrorFirstName('')
    (field === "emailAddress" || field === "phone") && setErrorEmailAddress('')
    setUser(user)
  }

  const handleEnterKey = (event) => {
      event.key === "Enter" && processForm();
  }

  const validateEmail = (email) => {
      var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
      return re.test(email);
  }

  const validatePhone = (phone) => {
      return stripPhoneFormatAndPrefix(phone).length === 10;
  }

  const stripPhoneFormatAndPrefix = (phone) => {
      phone = phone && phone.replace(/\D+/g, "");
      if (phone && phone.indexOf('1') === 0) { //if 1 is in the first place, get rid of it.
          phone = phone.substring(1);
      }
      return phone;
  }

  const processForm = (event) => {
    const {updateGroupMember, personId, group} = props;
    let hasError = false;
    // event.preventDefault();
    // event.stopPropagation();

    if (!user.firstName) {
        hasError = true;
        setErrorFirstName("First name required.")
    }

    if (!user.emailAddress && !user.phone) {
        hasError = true;
        setErrorEmailAddress("Please enter an email address or a phone number for text messaging.")
    } else if (user.emailAddress && !validateEmail(user.emailAddress)) {
        hasError = true;
        setErrorEmailAddress("Email address appears to be invalid.")
    }

    if (user.phone && !validatePhone(user.phone)) {
        hasError = true;
        setErrorEmailAddress("The phone number should be ten digits long.")
    }

    if (!hasError) {
        updateGroupMember(personId, group.groupId, user);
        navigate(`/assignmentDashboard`)
    }
  }

  return (
      <div className={styles.container}>
          <div className={styles.titleLine}>
              <span className={globalStyles.pageTitle}>Group Members</span>
          </div>
          <div className={styles.row}>
              <span className={styles.subTitle}>{group && group.groupName}</span>
          </div>
          <div>
              <div className={styles.formLeft}>
                  <InputText
                      size={"medium"}
                      name={"firstName"}
                      label={"First name"}
                      value={user.firstName || ''}
                      onChange={changeUser}
                      onEnterKey={handleEnterKey}
                      error={errorFirstName} />
                  <InputText
                      size={"medium"}
                      name={"lastName"}
                      label={"Last name"}
                      value={user.lastName || ''}
                      onEnterKey={handleEnterKey}
                      onChange={changeUser}/>
                  <InputText
                      size={"medium"}
                      name={"memberId"}
                      label={"Internal id (optional)"}
                      value={user.memberId || ''}
                      onEnterKey={handleEnterKey}
                      onChange={changeUser}/>
                  <hr />
                  <InputText
                      size={"medium-long"}
                      name={"emailAddress"}
                      label={"Email address"}
                      value={user.emailAddress || ''}
                      onChange={changeUser}
                      onEnterKey={handleEnterKey}
                      error={errorEmailAddress} />
                  <div className={styles.orPhone}>Or enter cell phone for text messaging:</div>
                  <InputText
                      size={"medium-short"}
                      name={"phone"}
                      label={"Text message phone number"}
                      value={user.phone || ''}
                      onChange={changeUser}
                      onEnterKey={handleEnterKey}
                      instructions={``} />
                  <hr />
              </div>
              <div className={classes(styles.rowRight)}>
                  <button className={styles.button} onClick={(event) => processForm(event)}>
                      Submit
                  </button>
              </div>
          </div>
          <OneFJefFooter />
      </div>
  );
}

export default GroupMemberUpdateView;
