import React, {useState} from 'react';
import styles from './WorkAddOriginatingEditor.module.css';
import InputText from '../InputText';
import { formatPhoneNumber } from '../../utils/numberFormat.js';
import Icon from '../Icon'
import GoogleLogo from '../../assets/GoogleLogo.png'
import EditorInviteGoogleContacts from '../EditorInviteGoogleContacts/EditorInviteGoogleContacts'
import EditorInviteContactList from '../EditorInviteContactList/EditorInviteContactList'
import Required from '../Required'
import classes from 'classnames'

export default (props) => {
	const { work, setWork, handleAuthor, fileType, authorFirstNameError, setAuthorEmailAddressError, authorEmailAddressError, 
					setAuthorPhoneError, authorPhoneError, authorTextError, personId, contacts } = props;

	const [googleContactsOpen, setGoogleContactsOpen] = useState(false)
	const [penspringContactsOpen, setPenspringContactsOpen] = useState(false)
	const [authorEntryOpen, setAuthorEntryOpen] = useState(false)
	const [isValidateEmailAddress, setIsValidateEmailAddress] = useState(true)

	const handleFormatPhone = () => {
		if (work?.originatingEditor?.authorPhone && ('' + work?.originatingEditor?.authorPhone).replace(/\D/g, '').length !== 10) {
			setAuthorPhoneError(`The phone number entered is not 10 digits`);
		} else if (work && work.originatingEditor && formatPhoneNumber(work?.originatingEditor?.authorPhone)) {
			const newAuthor = { ...work.originatingEditor, authorPhone: formatPhoneNumber(work?.originatingEditor?.authorPhone) }
			setWork({ ...work, originatingEditor: newAuthor })
			setAuthorPhoneError('')
		}
	}

	const handleAuthorEmailAddress = (event) => {
		setIsValidateEmailAddress(validateEmailAddress(event.target.value))
		handleAuthor(event)
	}

	const validateEmailAddress = (emailAddress) => {
		if (!emailAddress) {
			setAuthorEmailAddressError('')
			return
		}
		let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ //eslint-disable-line
		return re.test(emailAddress)
	}

	const onBlurAuthorEmailAddress = (emailAddress) => {
		if (validateEmailAddress(emailAddress)) {
			setAuthorEmailAddressError('')
		} else {
			setAuthorEmailAddressError('Please check your entry. The format looks incomplete.')
		}
	}

	const handleSetChosenGoogleContact = (contact) => {
		const newAuthor = { 
			authorFirstName: contact.firstName,
			authorLastName: contact.lastName,
			authorEmailAddress: contact.emailAddress,
			authorPhone: '' 
		}
		validateEmailAddress(contact.emailAddress)
		setWork({ ...work, originatingEditor: newAuthor })
		setAuthorPhoneError('')
		setAuthorEmailAddressError('')
		setGoogleContactsOpen(false)
		setAuthorEntryOpen(true)
	}

	const handleSetChosenPenspringContact = (contact) => {
		const newAuthor = {
			authorFirstName: contact.firstName,
			authorLastName: contact.lastName,
			authorEmailAddress: contact.emailAddress,
			authorPhone: contact.phone
		}
		validateEmailAddress(contact.emailAddress)
		setWork({ ...work, originatingEditor: newAuthor })
		setAuthorPhoneError('')
		setAuthorEmailAddressError('')
		setPenspringContactsOpen(false)
		setAuthorEntryOpen(true)
	}

	const clearEditControls = () => {
		setWork({...work, originatingEditor: {}})
	}

	const handleSetGoogleContactsOpen = (value) => {
		clearEditControls()
		setGoogleContactsOpen(value)
		setPenspringContactsOpen(false)
		setAuthorEntryOpen(false)
	}

	const handleSetPenspringContactsOpen = (value) => { 
		clearEditControls()
		setPenspringContactsOpen(value)
		setGoogleContactsOpen(false)
		setAuthorEntryOpen(false)
	}

	const handleSetAuthorEntryOpen = (value) => { 
		clearEditControls()
		setAuthorEntryOpen(!authorEntryOpen)
		setPenspringContactsOpen(false)
		setGoogleContactsOpen(false)
	}

	return (
		<div style={{ marginTop: '10px', marginBottom: '0px' }}>
			<div className={styles.rowWrap} >
				<div className={styles.linkWhite}>
					I am the editor uploading someone else’s text to show before-and-after edits
				</div>
				<div className={styles.tinyInstructions}>When you are done editing, you can send the final text. A penspring link will be included to give the author the chance to pick and choose the changes.</div>
			</div>
			<div>
				<div className={styles.showAuthorInput}>
					<div className={styles.backgroundListChoice}>
						<div className={styles.textChooseAuthor}>
							Choose or enter an author
							<Required 
								setIf={true} 
								className={styles.requiredPosition}
								setWhen={work?.originatingEditor?.authorFirstName && work?.originatingEditor?.authorLastName && work?.originatingEditor?.authorEmailAddress} />
						</div>
						<div className={styles.row}>
							<div onClick={() => handleSetGoogleContactsOpen(!googleContactsOpen)} className={styles.menuItem} style={{ marginTop: '-2px' }} data-rh={'Choose from your Google contacts'} title={'Choose from your Google contacts'} >
								<img src={GoogleLogo} height={25} />
							</div>
							<div onClick={() => handleSetPenspringContactsOpen(!penspringContactsOpen)} className={styles.menuItem} data-rh={'Choose from contact list'}
								title={'Choose from contact list'}>
								<Icon pathName={'list3'} premium={true} superscript={'plus'} supFillColor={'forestgreen'} />
							</div>
							<div onClick={() => handleSetAuthorEntryOpen(!authorEntryOpen)} className={styles.menuItem} data-rh={'Enter the author directly'} title={'Enter the author directly'}>
								<Icon pathName={'user'} premium={true} superscript={'plus'} supFillColor={'forestgreen'} larger />
							</div>
						</div>
					</div>
				</div>
				<div className={googleContactsOpen ? styles.showAuthorInput : styles.hide}>
					<EditorInviteGoogleContacts {...props} 
						googleContactsOpen={googleContactsOpen} 
						onClick={handleSetChosenGoogleContact} />
				</div>
				<div className={penspringContactsOpen ? styles.showAuthorInput : styles.hide}>
					<EditorInviteContactList {...props} 
						penspringContactsOpen={penspringContactsOpen} 
						onClick={handleSetChosenPenspringContact} 
						personId={personId} 
						contacts={contacts} />
				</div>
				<div className={authorEntryOpen ? styles.showAuthorInput : styles.hide}>
					<InputText
						size={"medium"}
						name={"authorFirstName"}
						label={"First name"}
						value={work?.originatingEditor?.authorFirstName || ''}
						onChange={handleAuthor}
						inputClassName={styles.textWhite}
						required={true}
						whenFilled={work?.originatingEditor?.authorFirstName}
						error={authorFirstNameError} />
					<InputText
						size={"medium"}
						name={"authorLastName"}
						label={"Last name"}
						value={work?.originatingEditor?.authorLastName || ''}
						inputClassName={styles.textWhite}
						required={true}
						whenFilled={work?.originatingEditor?.authorLastName}
						onChange={handleAuthor} />
					<InputText
						size={"medium-long"}
						name={"authorEmailAddress"}
						label={"Email address"}
						value={work?.originatingEditor?.authorEmailAddress || ''}
						onChange={handleAuthorEmailAddress}
						onBlur={(event) => onBlurAuthorEmailAddress(event.target.value)}
						inputClassName={styles.textWhite}
						labelClassName={styles.textWhite}
						required={true}
						whenFilled={work?.originatingEditor?.authorEmailAddress && !authorEmailAddressError && isValidateEmailAddress}
						error={authorEmailAddressError} />
					<div className={styles.phonePosition}>
						<InputText
							size={"medium-short"}
							name={"authorPhone"}
							label={"Cell phone number (optional for text messages)"}
							value={work?.originatingEditor?.authorPhone || ''}
							onChange={handleAuthor}
							onBlur={handleFormatPhone}
							inputClassName={styles.textWhite}
							labelClassName={styles.textWhite}
							instructionClassName={styles.offWhite}
							instructions={``}
							error={authorPhoneError} />
					</div>
				</div>
				{fileType === 'BLANKFILE' && 
					<div className={styles.showAuthorInput}>
						<div className={classes(styles.linkWhite, styles.spaceTop)}>
							Choose a file above or paste in the author's text here:
						</div>
						<div id="authorText"
							contentEditable={'true'}
							spellCheck={'false'}
							className={styles.editorDiv}
						>
							{work?.originatingEditor?.authorText}
						</div>
						<div className={styles.alertMessage}>{authorTextError}</div>
					</div>
				}
			</div>
		</div>
	)
}
