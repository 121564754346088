import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router';
import { useAuth } from '../hooks/auth';
import jwtDecode from "jwt-decode";
import { connect } from 'react-redux';
import { selectMe } from '../store.js';
import * as actionLogin from '../actions/login.js';

const mapStateToProps = state => {
	return {
		me: selectMe(state),
	}
}

const bindActionsToDispatch = dispatch => ({
	initRecords: (person, sendTo) => dispatch(actionLogin.initRecords(person, sendTo)),
});

const storeConnector = connect(
	mapStateToProps,
	bindActionsToDispatch,
);

function RequireAuth(props) {
	const auth = useAuth();
	const location = useLocation();
	const me = props && props.me;

	useEffect(() => {
		const { exp } = jwtDecode(window.localStorage.getItem('authToken'));
		const isValidToken = new Date().getTime() < (exp * 1000);
		if (!(me && me.personId) && isValidToken) {
			let person = window.localStorage.getItem('person')
			person = JSON.parse(person);
			props.initRecords(person); //, location.pathname  don't call the myWorks page again. In fact, we need to just stay on the current page.
			auth.login(person);
		} else if (!auth.user) {
			return <Navigate to='/login' />
		}
	}, [])

	// async function sendToLogin(person) {
	// 	await auth.login(person);
	// 	return <Navigate to={location.pathname} />
	// }

	return props.children
}

export default storeConnector(RequireAuth);