import React from 'react';
import styles from './GroupSectionControl.module.css';
import classes from "classnames";
import Icon from '../Icon'
import MenuGroupRibbon from '../MenuGroupRibbon'
import MenuGroupPopup from '../MenuGroupPopup/MenuGroupPopup'
import MediaQuery from 'react-responsive';

function GroupSectionControl(props) {
   const {label, expanded, onClick, groupCount, noMenu} = props
    return (
      <div className={classes(styles.mainFolder, styles.row)}>
        <Icon pathName={expanded ? 'chevron_down' : 'chevron_right'} premium={true} className={styles.iconSmaller} cursor={'pointer'} onClick={onClick}/>
        <div className={classes(styles.backgroundFolder, styles.rowSpaceBetween)}>
          <div className={styles.row} onClick={onClick}>
            <Icon pathName={expanded ? 'folder_minus_inside' : 'folder_plus_inside'} premium={true} fillColor={'white'} className={styles.iconTree} cursor={'pointer'} />
            {label.indexOf('My Groups') > -1
              ? <div className={styles.row}>My Groups<div className={styles.count}>{groupCount || '0'}</div> and Contacts</div>
              : <div className={styles.row}>{label}<div className={styles.count}>{groupCount || '0'}</div></div>}
          </div>
          <MediaQuery minWidth={500}>
            <MenuGroupRibbon {...props}/>
          </MediaQuery>
          <MediaQuery maxWidth={500}>
            {!noMenu && <MenuGroupPopup {...props} menu={<MenuGroupRibbon {...props}/>}/>}
          </MediaQuery>
        </div>
      </div>
    )
}

export default GroupSectionControl