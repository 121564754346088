import React, { useState, useEffect } from 'react';
import {useNavigate} from 'react-router'
import styles from './WorkSummaryLine.module.css';
import MenuDocumentPopup from '../MenuDocumentPopup'
import WorkFileExplorerOptions from '../WorkFileExplorerOptions'
import SelectSingleDropDown from '../SelectSingleDropDown'
import classes from 'classnames'
import DateMoment from '../DateMoment'
import Icon from '../Icon'
import { useMediaQuery } from 'react-responsive';

function WorkSummaryLine(props) {
  const {
    personId,
    work = {},
    onClick=()=>{},
    //handlePenspringHomeworkOpen = () => {},
    nameLength = 50,
    //setGradebookScoreByPenspring = () => {},
    darkerBackground,
    titleWhite,
    addClassName,
    keyIndex,
    includeEditors,
    includeDates,
    showDocumentDetails,
    darkerLabel,
    maroonBackground,
    labelWhite,
    chosenWork,
    hideActionIcons,
    showExplorerOptions,
    noFocusColor,
    mineOrOthers,
    moveWorkToFolder,
    thisWorkFolderId,
    isMoveWorkId,
    deleteWorkFromFolder,
    hasParentFolderGroup,
    showEditLink,
    isWorkAddView,
    chapterCountLinkClick,
  } = props

  const navigate = useNavigate()
  const isMobile = useMediaQuery({ query: '(max-width: 870px)' })
  const [showTempControls, setShowTempControls] = useState(false)
  const [workAddViewChoice, setWorkAddViewChoice] = useState(chosenWork && chosenWork.workId)
  const [chosenChapter, setChosenChapter] = useState({})

  useEffect(() => {
    setChosenChapter(getChosenChapter(work?.workId))
  }, [work])

  useEffect(() => {
    setWorkAddViewChoice(chosenWork && chosenWork.workId)
  }, [chosenWork])

  //const [scores, setScores] = useState([]);

  // `const onBlurScore = (studentAssignmentResponseId, event) => {
  // 	setGradebookScoreByPenspring(personId, studentAssignmentResponseId, event.target.value);
  // }
  //
  // const handleScore = (studentAssignmentResponseId, event) => {
  // 	let newScores = [...scores];
  // 	newScores[studentAssignmentResponseId] = !event.target.value ? 'Empty' : event.target.value;
  // 	setScores(newScores);
  // }
  const workName = work && work.workName ? work.workName.length > nameLength ? work.workName.substring(0, nameLength) + '...' : work.workName : ''
  const isTranslation = work.languageIdAssignName && work.languageIdName !== work.languageIdAssignName

  const sendToEditReview = (event) => {
    event.stopPropagation()
    event.preventDefault()
    navigate(`/editReview/${work && work.workId}/${chosenChapter}/${work.languageIdAssign || 1}`)
  }

  const setBackgroundColor = () => {
    if (maroonBackground) {
      return styles.backgroundMaroon
    } else if (darkerBackground) {
      return styles.backgroundDarker
    } else if (showTempControls) {
      return styles.hoverColor
    }
  }

  const getChosenChapter = (workId) => {
    const storage = localStorage.getItem('chosenChapters')
    const chosenChapters = storage ? JSON.parse(storage) : {}
    if (chosenChapters && chosenChapters[workId]) {
      return chosenChapters[workId]
    }
    return work && work.chapters && work.chapters.length > 0 && work.chapters[0].chapterId
  }

  const getOptions = (work) => {
    return work.chapters.reduce((acc,m) => {
      let option = {
        id: m.chapterId,
        label: m.chapterNbr + ' - ' + m.name
      }
      return acc && acc.length > 0 ? acc.concat(option) : [option]
    }, [])
  }

  const handleSetChosenChapter = (event, workId) => {
    event.stopPropagation()
    const chapterId = event.target.value
    const storage = localStorage.getItem('chosenChapters')
    const chosenChapters = storage ? JSON.parse(storage) : {}
    chosenChapters[workId] = chapterId
    localStorage.setItem('chosenChapters', JSON.stringify(chosenChapters))
    setChosenChapter(chapterId)
  }

  const showDropDownList = (work) => {
    return (
      <div className={isMobile ? '' : styles.row}>
        {work?.chapters?.length > 1 &&
          <div>
            <SelectSingleDropDown
              id={`chapters${work.workId}`}
              label={''}
              noBlank
              zeroethLabel={work?.chapters[0].chapterNbr + ' - ' + work?.chapters[0].name}
              zeroethValue={work?.chapters[0].chapterId}
              value={chosenChapter}
              options={getOptions(work)}
              height={`medium-short`}
              labelClass={styles.textWhite}
              className={styles.selectList}
              includeDownArrowFirst
              onChange={(event) => handleSetChosenChapter(event, work.workId)} />
          </div>
        }
      </div>
    )
  }

  const showChapterCountLink = (work, workName) => {
    return (
      <div className={isMobile ? '' : styles.rowSpace}>
        {work?.chapters?.length > 1 &&
          <div className={styles.chapterCountLink} onClick={() => chapterCountLinkClick()}>
            &#x25BC; {work.chapters.length} chapters  {/*We put in a downarrow at the first because that list can be cut off on mobile or when it is longer than 290px for a long chapter name.*/}
          </div>
        }
      </div>
    )
  }

  return !(work && work.workId) ? null : (
    <div key={keyIndex} className={classes(setBackgroundColor(), addClassName)} onDoubleClick={sendToEditReview} >
      <div className={styles.container}>
        <div title={workName.length > nameLength ? workName : null} className={classes(styles.row, titleWhite ? styles.whiteText : '')}>
          {showEditLink && !isWorkAddView && 
            <a onClick={() => navigate(`/editReview/${work.workId}/${chosenChapter}/${work.languageId_current}`)}
              title={`View or edit document`} 
              className={styles.pencilIcon}>
              <Icon pathName={'pencil0'} premium={true} />
            </a>
          }
          {isWorkAddView &&
            <input type='checkbox' checked={workAddViewChoice === work.workId} onChange={() => { }} onClick={() => {onClick(); setWorkAddViewChoice(workAddViewChoice === work.workId ? '' : work.workid);}} />
          }
          <div className={isMobile ? '' : styles.rowSpace}>
            <div onClick={() => onClick()}>{workName}</div>
            {!chapterCountLinkClick && showDropDownList(work, workName)}
            {chapterCountLinkClick && showChapterCountLink(work, workName)}
          </div>
          {work.languageIdAssignName && work.languageIdName !== work.languageIdAssignName &&
            <div className={styles.smallerAndGray}>{` - (${work.languageIdName} to ${work.languageIdAssignName})`}</div>
          }
        </div>
        {showExplorerOptions &&
          <div className={isMobile ? styles.absoluteRightMobile : styles.absoluteRight}>
            <MenuDocumentPopup {...props} >
              <WorkFileExplorerOptions noFocusColor={noFocusColor}
                mineOrOthers={mineOrOthers}
                moveWorkToFolder={moveWorkToFolder}
                isMoveWorkId={isMoveWorkId}
                thisWorkFolderId={thisWorkFolderId}
                work={work}
                personId={personId}
                deleteWorkFromFolder={deleteWorkFromFolder}
                chosenWork={chosenWork}
                hasParentFolderGroup={hasParentFolderGroup}
                {...props} />
            </MenuDocumentPopup>
          </div>
        }
      </div>
      <div>
        {showDocumentDetails &&
          <div className={styles.rowTight}>
            <div title={'Word count'} className={styles.row}>
              <div className={maroonBackground || labelWhite ? styles.labelWhite : styles.label}>words</div>
              <div
                className={darkerLabel ? styles.countDark : styles.countWhite}>{work.wordCount || work.workWordCount || '0'}</div>
            </div>
            <div title={'Sentence count'} className={styles.row}>
              <div className={maroonBackground || labelWhite ? styles.labelWhite : styles.label}>sentences</div>
              <div
                className={darkerLabel ? styles.countDark : styles.countWhite}>{work.sentenceCount || work.workSentenceCount || '0'}</div>
            </div>
            {isTranslation &&
              <div title={'Translated sentences'} className={styles.row}>
                <div className={maroonBackground || labelWhite ? styles.labelWhite : styles.label}>translated</div>
                <div
                  className={darkerLabel ? styles.countDark : styles.countWhite}>{(work.editsProcessed + work.editsPending) || '0'}</div>
              </div>
            }
            {isTranslation &&
              <div title={'Pending translated sentences'} className={styles.row}>
                <div className={maroonBackground || labelWhite ? styles.labelWhite : styles.label}>pending</div>
                <div
                  className={darkerLabel ? styles.countDark : styles.countWhite}>{(work.sentenceCount - (work.editsProcessed + work.editsPending)) || '0'}</div>
              </div>
            }
            {!isTranslation &&
              <div title={'Edits processed'} className={styles.row}>
                <div className={maroonBackground || labelWhite ? styles.labelWhite : styles.label}>edits</div>
                <div
                  className={darkerLabel ? styles.countDark : styles.countWhite}>{work.editsProcessed || work.editsPending || '0'}</div>
              </div>
            }
            {!isTranslation &&
              <div title={'Edits pending'} className={styles.row}>
                <div className={maroonBackground || labelWhite ? styles.labelWhite : styles.label}>pending</div>
                <div className={darkerLabel ? styles.countDark : styles.countWhite}>{work.editsPending || '0'}</div>
              </div>
            }
            {includeEditors &&
              <div title={'Editors'} className={classes(styles.row, styles.editors)}>
                <div className={maroonBackground || labelWhite ? styles.labelWhite : styles.label}>editors assigned</div>
                <div
                  className={darkerLabel ? styles.countDark : styles.countWhite}>{(work.editorAssign && work.editorAssign.length) || '0'}</div>
              </div>
            }
            {work.activeDate && includeDates &&
              <div title={'Active date'} className={classes(styles.row, styles.editors)}>
                <div className={maroonBackground || labelWhite ? styles.labelWhite : styles.label}>active</div>
                <div className={darkerLabel ? styles.countDark : styles.countWhite}>
                  {!work.activeDate ? '- -' :
                    <DateMoment date={work.activeDate} label={''} format={'D MMM YYYY'} className={styles.date}
                                dateTextClass={styles.dateTextClass}/>}
                </div>
              </div>
            }
            {work.dueDate && includeDates &&
              <div title={'Due date'} className={classes(styles.row, styles.editors)}>
                <div className={maroonBackground || labelWhite ? styles.labelWhite : styles.label}>due</div>
                <div className={darkerLabel ? styles.countDark : styles.countWhite}>
                  {!work.dueDate ? '- -' :
                    <DateMoment date={work.dueDate} label={''} format={'D MMM YYYY'} className={styles.date}
                                dateTextClass={styles.dateTextClass}/>}
                </div>
              </div>
            }
            {work.originatingEditorPerson && work.originatingEditorPerson.fname &&
              <div>
                {work.originatingEditorPerson.personId !== personId &&
                  <div className={styles.row}>
                    <div className={maroonBackground || labelWhite ? styles.labelWhite : styles.label}>Author:</div>
                    <div className={darkerLabel ? styles.countDark : styles.countWhite}>
                      {work.workOwners && work.workOwners.length > 0 && work.workOwners[0].fname + ' ' + work.workOwners[0].lname}
                    </div>
                  </div>
                }
                {work.originatingEditorPerson.personId === personId &&
                  <div className={styles.row}>
                    <div className={styles.row}>
                      <div className={maroonBackground || labelWhite ? styles.labelWhite : styles.label}>
                        Originated by:
                      </div>
                      <div className={darkerLabel ? styles.countDark : styles.countWhite}>
                        {work.originatingEditorPerson.fname + ' ' + work.originatingEditorPerson.lname}
                      </div>
                    </div>
                    <div className={styles.row}>
                      <div className={maroonBackground || labelWhite ? styles.labelWhite : styles.label}>Author:</div>
                      <div className={darkerLabel ? styles.countDark : styles.countWhite}>
                        {work.workOwners && work.workOwners.length > 0 && work.workOwners[0].fname + ' ' + work.workOwners[0].lname}
                      </div>
                    </div>
                  </div>
                }
              </div>
            }
          </div>
        }
      </div>
    </div>
  )
}

export default WorkSummaryLine