import React, { useRef } from "react";
import styles from './AuthorContextMenu.module.css'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import SentenceEdits from '../SentenceEdits'

function AuthorContextMenu(props) {
  const {x, y, closeContextMenu} = props;

  const contextMenuRef = useRef(null)
  useOnClickOutside(contextMenuRef, closeContextMenu)

  return (
    <div className={styles.popup}>
      <div style={{top: `${y+10*1}px`, left: `${x}px`, position: 'absolute', zIndex: '20'}} ref={contextMenuRef} >
        <div className={styles.shadowBox}>
          <SentenceEdits
            flexOriginalTextHeight={true}
            isTranslation={props.isTranslation}
            translatedSentence={props.translatedSentence}
            getTranslation={props.getTranslation}
            setTabSelected={props.handleEditorTabChosen}
            chosenTab={props.chosenTab}
            currentElement={props.currentElement}
            handleSetCurrentElement={props.handleSetCurrentElement}
            getNextElement={props.getNextElement}
            saveEditorDivSegmentsPersistent={props.saveEditorDivSegmentsPersistent}
            setShowEditorFullText={props.setShowEditorFullText}
            setIsInitEdits={props.setIsInitEdits}
            handleSetChosenMoveEdit={props.handleSetChosenMoveEdit}
            handleSetChosenAddParagraphEdit={props.handleSetChosenAddParagraphEdit}
            getNextId={props.getNextId}
            {...props} />
          </div>
      </div>
    </div>
  )
}

export default AuthorContextMenu;