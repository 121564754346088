import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom'
import styles from './GroupSummaryLine.module.css';
import ContactSummaryLine from '../ContactSummaryLine'
import GroupFileExplorerOptions from '../GroupFileExplorerOptions'
import Icon from '../Icon';
import {guidEmpty} from "../../utils/GuidValidate";
import classes from 'join-classnames'
import { useMediaQuery } from "react-responsive"

const groupNameLength = 44

function GroupSummaryLine(props) {
  const {
    personId,
    groups,
    keyIndex,
    setChosenGroup,
    chosenGroup = {},
  } = props

  const navigate = useNavigate()
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' })
  const [peopleExpanded, setPeopleExpanded] = useState([])

  useEffect(() => {
    let newPeopleExpanded = localStorage.getItem("peopleExpanded")
    if (newPeopleExpanded && personId !== guidEmpty) {
      newPeopleExpanded = newPeopleExpanded.split(",")
      setPeopleExpanded(newPeopleExpanded)
    }
  }, [])

  const togglePeopleExpanded = (groupId) => {
    let newPeopleExpanded = localStorage.getItem("peopleExpanded")
    if (newPeopleExpanded) newPeopleExpanded = newPeopleExpanded.split(",")
    if (newPeopleExpanded && newPeopleExpanded.length > 0 && newPeopleExpanded.indexOf(groupId) > -1) {
      newPeopleExpanded.splice(newPeopleExpanded.indexOf(groupId), 1)
    } else {
      newPeopleExpanded = newPeopleExpanded && newPeopleExpanded.length > 0 ? newPeopleExpanded.concat(groupId) : [groupId]
    }
    localStorage.setItem("peopleExpanded", newPeopleExpanded.toString());
    setPeopleExpanded(newPeopleExpanded)
  }

  const getIsPeopleExpanded = (groupId) => {
    return peopleExpanded && peopleExpanded.length > 0 && peopleExpanded.indexOf(groupId) > -1
  }
  
  return (
    <div className={groups && groups.length > 0 ? styles.container : styles.hidden} key={keyIndex}>
      {groups && groups.length > 0 && groups.map((m, i) => {
        return (
          <div className={styles.row} onDoubleClick={() => navigate(`/groupContacts/${m.groupId}`)} key={i}>
            <div onClick={() => togglePeopleExpanded(m.groupId)} className={styles.link}>
              <Icon pathName={getIsPeopleExpanded(m.groupId) ? 'chevron_down' : 'chevron_right'} premium={true}
                    className={styles.iconSmaller} cursor={'pointer'}/>
            </div>
            <div key={i + 'groupSummary'}
                onClick={() => chosenGroup && chosenGroup.groupId === m.groupId ? setChosenGroup('') : setChosenGroup(m)}
                className={styles.groupSummary}>
              <div className={classes(isMobile ? styles.rowWidthMobile : styles.rowWidth, styles.darkerBackground)}>
                <div className={styles.row}>
                  <div className={styles.groupName}
                      title={m.groupName && m.groupName.length > groupNameLength ? m.groupName : ''}
                      onClick={() => togglePeopleExpanded(m.groupId)}>
                    {m.groupName && m.groupName.length > groupNameLength ? m.groupName.substring(0, groupNameLength) + '...' : m.groupName}
                  </div>
                  <div className={styles.count}>{m.people && m.people.length}</div>
                </div>
                <div>
                  <GroupFileExplorerOptions personId={personId}
                                            group={m}
                                            deleteGroup={props.deleteGroup}
                                            setChosenGroup={setChosenGroup}
                                            mineOrOthers={props.mineOrOthers}/>
                </div>
              </div>
              <div className={getIsPeopleExpanded(m.groupId) ? styles.show : styles.hide}>
                {m.people && m.people.length > 0 && m.people.map((f, i) => 
                  <div className={styles.lineIndent} key={i + 'summary'}>
                    <ContactSummaryLine contact={f} nameLength={150} keyIndex={i + 'line'}/>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      )}
    </div>
  )
}

export default GroupSummaryLine