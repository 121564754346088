import React, {useState} from 'react';
import {useNavigate} from 'react-router'
import styles from './LoginView.module.css';
import InputText from '../../components/InputText';
import LogoSlogan from '../../components/LogoSlogan';
import ButtonWithIcon from '../../components/ButtonWithIcon';
import MessageModal from '../../components/MessageModal';
import {Link} from 'react-router-dom';
import OneFJefFooter from '../../components/OneFJefFooter';

function ForgotPasswordView(props) {
	let navigate = useNavigate()

	const [isShowingModal, setIsShowingModal] = useState(false);
	const [errorPhone, setErrorPhone] = useState('');
	const [phone, setPhone] = useState('');


	const changeUser = (event) => {
		const field = event.target.name;
		setPhone(event.target.value);
		field === "phone" && setErrorPhone('');
	}

	const handleEnterKey = (event) => {
		event.key === "Enter" && processForm();
	}

	const processForm = (event) => {
		const {forgotPassword} = props;
		event && event.preventDefault();
		let hasError = false;

		if (!phone) {
			setErrorPhone("Please enter your cell number.");
			hasError = true;
		} else if (phone && ('' + phone).replace(/\D/g, '').length !== 10) {
			hasError = true
			setErrorPhone(`The phone number entered is not 10 digits`)
		}

		if (!hasError) {
			forgotPassword('', phone);
			setIsShowingModal(true)
		}
	}

	const handleMessageClose = () => {
		setIsShowingModal(false)
		navigate('/login')
	}

	return (
		<section className={styles.forgotUsernameContainer}>
			<LogoSlogan/>
			<div className={styles.moreRight}>
				<div className={styles.resetPassword}>
					Request to Reset Password
				</div>
				<div>
					<InputText
						size={"medium"}
						name={"phone"}
						value={phone}
						onChange={changeUser}
						onEnterKey={handleEnterKey}
						placeholder={"cell number"}
						autoComplete={'on'}
						error={errorPhone}/>
				</div>
				<div className={styles.submitButton}>
					<ButtonWithIcon icon={'checkmark0'} label={'Submit'} onClick={processForm}/>
				</div>
			</div>
			<div className={styles.moreTop}>
				<Link to={`/login/createNew`} className={styles.createAccount}>Create a new account?</Link>
			</div>
      <MessageModal show={isShowingModal} handleClose={() => setIsShowingModal(false)} heading={`Password Reset`}
                    explain={"A link has been sent to your cell phone.\n\nYou will be redirected to the login page now."}
                    onClick={handleMessageClose}/>
			<OneFJefFooter smallWidth/>
		</section>
);
}

export default ForgotPasswordView;
