import React, { useState, useEffect } from 'react';
import styles from './EditReviewView.module.css';
import * as editorService from '../../services/editor-dom'
import SentenceEdits from '../../components/SentenceEdits';
import ToggleBoard from '../../components/ToggleBoard';
import OneFJefFooter from '../../components/OneFJefFooter';
import Checkbox from '../../components/Checkbox';
import AuthoringEditor from '../../components/AuthoringEditor';
import MessageModal from '../../components/MessageModal';
import backgroundColors from "../../utils/backgroundColors";
import { useMediaQuery } from 'react-responsive';

const initialContextMenu = {
	show: false,
	x: 0,
	y: 0
}

let newId = ''

/*
    TRANSLATION:  If this is a translation of the native language of this work, the following will happen:
    1. isTranslation prop will be set to true.
    2. The contentEditable will not be editable.
    3. Clicking on a sentence will open up the left panel.
        a.  If the sentence has NOT been translated, the api for the translator (bing or google) will be hit and a translation will be waiting
        b.  If it has been translated, the previous api translation would have been saved to be presented again if a change needs to be made without having to make the api call again.
        c.  The user can then choose the translation which will then go into the single editor.  Or the user can make their own translation.
    4. The left panel setting for going previous and next will be set by force so that the user can continue to translate
         each sentence one at a time.

    EDITING NATIVE LANGUAGE
    1. When leaving the current sentence
        a. The previous sentence will be checked for change.
            i. If a different sentence with its hrefId is detected, that sentence only is sent to the webapi
            ii. On the server-side, if it is the author, the sentence will be applied directly to the chapterText and new chapterText will be sent background
                  If it is the editor, the editDetail record will be saved and the editDetails record as a whole will be sent back (to include any other editors' edits completed since the last refresh)

    VIEWS
    1. The main view (left)
         a. The author will see red text (edited text) and red icons for comments, break changes, moved sentence(s) and new image(s).
              i. The author can change freely and his right menu will accumulate his blue changes (text and icons) until commit again.
              ii. The left will save to authorChapterText in the chapter record and override chapterText for the author until commit (even between sessions).
         b. The editor will not see any red. (The purpose is for the editor to have a clean view and flow for undistracted editing.)
    2. The right side will contain a comparison full-view of the author and the editor (but only one of those users at a time, of course).
         a. The author will see her original text plus any of her current edits (not yet committed) in blue - text or icons.
             i. The edits kept in place during the session (before commit) will be available to be reversed.
             ii. The author needs to click on the submit icon (floppy disk) in order to commit the edit Details.
                 If a commit was not done in a session, those edits will still be displayed.  That gives the author a chance to reverse them easily.
         b. The editor will see all of their own edits in red (text or icons) but no one else's.  They have the chance to reverse them out.
         c. The editor will see ALL edits in the author's tab if they care to see what others have done.
         d. Any user in any other user's full-view can click on an icon or a sentence in order to agree, disagree, or block-vote.
         e. Any edits that belong to the current user will find in the pop-down tool options that they can reverse the edit (delete the edit detail record).

    TOP TOOLS
    1. Mobile
        a. Only three icons and the drop-down caret will be displayed. (Plus the floppy disk for saving for the user)
        b. The pop-down menu will have the edit modes, search, and bookmark
    2. Tablet and Desktop
        a. All tools will be displayed.  There will not be a pop-down menu.

    hrefIds
    1. The writer side has its HrefIds changed from ~! to ~^ so that the functions through the process will highlight the right-side and not the left.  But those two ID types need to be managed throughout the processes.
*/

function EditReviewView(props) {
	const {editLanguageId, workSummary, personId, segments, isDraftView, toggleDraftView, isTranslation, getTranslation,
    isAuthor, chapterListLevels, addChapterListLevels, listLevelGeneral, edits } = props;  //bookmarkOptions, bookmarks, editorColors, editReview={}
  let {tabsData} = props;
  //tabsData = tabsData && tabsData.length > 0 && tabsData.filter(m => m.languageId === Number(editLanguageId))
	const isMobile = useMediaQuery({ query: '(max-width: 870px)' })

  //If this is a new document, then include the Save button for the author, but if the author leaves the page with or without
  //  saving, then save the chapterText and process it for hrefId-s anyway.
	const [isInit, setIsInit] = useState(false);
	const [chosenTab, setChosenTab] = useState(workSummary.authorPersonId);
	const [isOpenSlideOut, setIsOpenSlideOut] = useState(false);
	// const [arraySearchTextFound, setArraySearchTextFound] = useState([])
  // const [pointerBookmark, setPointerBookmark] = useState(-1) //Need to start with -1 since it is incremented by one before being sent to the bookmark tool and it needs to start as 0
  // const [bookmarkChosen, setBookmarkChosen] = useState('') //this is an hrefI
  // const [isShowingDeleteModal, setIsShowingDeleteModal] = useState(false)
  // const [isShowingMissingBookmarkModal, setIsShowingMissingBookmarkModal] = useState(false)
  // const [isShowingMovedSentenceError, setIsShowingMovedSentenceError] = useState(false)
  // const [isShowingCutPasteMessage, setIsShowingCutPasteMessage] = useState(false)
  // const [isShowingImageUpload, setIsShowingImageUpload] = useState(false)
	// const [isShowingPenspringHomework, setIsShowingPenspringHomework] = useState(false)
  // const [searchChoice, setSearchChoice] = useState('')
  // const [isShowingUpdateContentModal, setIsShowingUpdateContentModal] = useState(false)
	// const [hideEditorsVersions, setHideEditorsVersions] = useState(!props.isAuthor || (props.tabsData && props.tabsData.tabs && props.tabsData.tabs.length > 1))
	// const [localHomeworkSubmitDate, setLocalHomeworkSubmitDate] = useState()
	// const [isShowingPenspringDistribute, setIsShowingPenspringDistribute] = useState(false)
	// const [localDistributeSubmitDate, setLocalDistributeSubmitDate] = useState()
  const [editTrackChanges, setEditTrackChanges] = useState(false)
  const [keepCommentOn, setKeepCommentOn] = useState(false)
	const [sidePanel, setSidePanel] = useState('268px')
	const [moveSentences, setMoveSentences] = useState(false)
	const [addParagraphBreak, setAddParagraphBreak] = useState(false)
	const [addParagraphSentence, setAddParagraphSentence] = useState(false)
	const [addSentence, setAddSentence] = useState(false)
	const [deleteSentence, setDeleteSentence] = useState(false)
	const [addListItem, setAddListItem] = useState(false)
	const [deleteListItem, setDeleteListItem] = useState(false)
	const [reorderListItems, setReorderListItems] = useState(false)
	const [deleteParagraphBreak, setDeleteParagraphBreak] = useState(false)
	const [disableEditorPopup, setDisableEditorPopup] = useState(true) //Notice that this is set to true since we are discontinuing this choice for now. Although it is probably necessary on mobile. ... unless there is a left side that slides in.
  const [showEditorFullText, setShowEditorFullText] = useState(false)
  const [moveTranslationToEditor, setMoveTranslationToEditor] = useState(false)
  const [goToNextSentence, setGoToNextSentence] = useState(false)
	const [currentElement, setCurrentElement] = useState()
	const [previousElement, setPreviousElement] = useState({elementId: '', backgroundColor: ''}) //This is to return the background color to what it used to be.
	const [isInitNewId, setIsInitNewId] = useState(false)
	const [isInitEdits, setIsInitEdits] = useState('') //This is for the case of DELETEPARAGRAPH when the editor is deleting their own paragraph in SentenceEdits and needs to recall the setSegmentsWithEdits
	const [moveSequence, setMoveSequence] = useState(1)
	const [contextMenu, setContextMenu] = useState(initialContextMenu);
  const [hasListStructure, setHasListStructure] = useState()
  const [localTranslation, setLocalTranslation] = useState(props.translatedSentence)
  const [showClickInstruction, setShowClickInstruction] = useState()
  const [hideClickInstruction, setHideClickInstruction] = useState(false)
	const [chosenSegment, setChosenSegment] = useState([{ id: '0', type: '', addListItemSequence: '' }])

	useEffect(() => {
		setLocalTranslation(props.translatedSentence)
		if (currentElement && props.personConfig.goToNextSentence) {
			setTimeout(() => {
				// const editorDivTemp = document.getElementById('editorDiv')
				// if (editorDivTemp) editorDivTemp.focus()
				const highlightSpan = document.getElementById(currentElement.id)
				const highlightSpanTabView = document.getElementById(currentElement.id + '~tabView')
				if (highlightSpan) highlightSpan.style.backgroundColor = 'yellow'
				if (highlightSpanTabView) highlightSpanTabView.style.backgroundColor = 'yellow'
			}, 300)
		}
	}, [props.translatedSentence])

	useEffect(() => {
		let hasListStructure = document.getElementsByTagName('ul')
		if (!hasListStructure) hasListStructure = document.getElementsByTagName('ol')
	}, [workSummary])

  //THIS IS A PROBLEM: This seemed to be causing the author's cursor to jump to the top when clicking on any sentence but only when an edit was present on the page - any edit so that hte edits object was causing trouble by letting this run - probably setSegmentViews.
	// useEffect(() => {
	// 	if (showEditorFullText && tabsData && tabsData.length > 0) {
	// 		let newChosenTab = chosenTab ? chosenTab : tabsData[0].id
	// 		editorService.setSegmentViews(newChosenTab, workSummary, segments, edits, isAuthor, props.personId, props.editorName, tabsData, getNextId, chapterListLevels, addChapterListLevels, listLevelGeneral, isTranslation)
  //     setChosenTab(newChosenTab)
	// 	}
	// }, [edits, tabsData, chosenTab, showEditorFullText, isAuthor, personId])

	useEffect(() => {
		localStorage.setItem('personConfig', JSON.stringify(props.personConfig))
    setKeepCommentOn(props.personConfig.keepCommentOn)
    setEditTrackChanges(props.personConfig.editDifferenceView)
		setDisableEditorPopup(props.personConfig.disableEditorPopup)
		setSidePanel(props.personConfig.showSidePanel)
    setShowEditorFullText(props.personConfig.showEditorFullText)
    setMoveTranslationToEditor(props.personConfig.moveTranslationToEditor)
    setGoToNextSentence(props.personConfig.goToNextSentence)
	}, [props.personConfig])

	useEffect(() => {
		if (!isInit && tabsData && tabsData.length > 0) {
			handleEditorTabChosen(workSummary.authorPersonId)
			setIsInit(true)
		}
	},[tabsData, isInit])

	useEffect(() => {
		if (isInitEdits === 'FORCE' && segments && segments.length > 0 && (isAuthor || (tabsData && tabsData.length > 0))) {
			setIsInitEdits('')
			setDeleteParagraphBreak(false)
			setAddParagraphBreak(false)
			setAddParagraphSentence(false)
			setAddSentence(false)
			setDeleteSentence(false)
			setAddListItem(false)
			//setDeleteListItem(false)
			setReorderListItems(false)
			setMoveSentences(false)
			editorService.setSegmentViews(chosenTab, workSummary, segments, edits, isAuthor, personId, props.editorName, tabsData, getNextId, chapterListLevels, addChapterListLevels, listLevelGeneral, isTranslation)
			editorService.restoreCursorLocation()
		}
	}, [edits, tabsData, segments])

	useEffect(() => {
		if (!isInitNewId && segments && segments.length > 0) {
			setIsInitNewId(true)
			newId = initIdFromSegments(segments)
      editorService.setSegmentViews(chosenTab, workSummary, segments, edits, isAuthor, personId, props.editorName, tabsData, getNextId, chapterListLevels, addChapterListLevels, listLevelGeneral, isTranslation)
		}
	}, [segments])

  useEffect(() => {
		window.scrollTo(0, 0);
    let localHideClickInstruction = localStorage.getItem('hideClickInstruction')
    if (localHideClickInstruction) setHideClickInstruction(true)
  },[])

	const handleSetChosenSegment = (element) => {
		if (element) {
			let newChosen = (chosenSegment && chosenSegment.length > 0 && [...chosenSegment]) || []
			let cleanSpanId = element.id.indexOf('~tabView') > -1 ? element.id.substring(0, element.id.indexOf('~tabView')) : element.id
			const lastChosen = newChosen.length > 0 ? newChosen[newChosen.length - 1] : {}
			//Don't allow duplicates
			const matchLast = Number(lastChosen.id) === Number(cleanSpanId) 
				&& lastChosen.type === element.dataset.type 
				&& ((!element.dataset.addListItemSequence && !element.dataset.addListItemSequence) || lastChosen.addListItemSequence === element.dataset.addListItemSequence)
				&& ((!element.dataset.subsequence && !element.dataset.subsequence) || lastChosen.subSequence === element.dataset.subsequence)

			if (!matchLast) {
				let item = {
					id: cleanSpanId,
					type: element.dataset.type,
					addListItemSequence: element.dataset.addListItemSequence || '',
					subSequence: element.dataset.subsequence //This is for the ADDPARAGRAPHSENTENCE edit type. I know ... it is similar to addListItemSequence and not specifically named as addListItemSequence.
				}
				setChosenSegment(newChosen.concat(item).filter(m => m && m.id && m.id !== 'root')) //cut out the blank entries
			}
		}
	}

  const handleHideClickInstruction = () => {
    localStorage.setItem('hideClickInstruction', 'true')
    setHideClickInstruction(true)
  }

  const handleSetChosenMoveEdit = () => {
		let editMatch = edits && edits.length > 0 && edits.filter(m => m.type === 'MOVE')
		editMatch && editMatch.length > 0 && editMatch.forEach(edit => {
			editorService.unshowMoveIcons(edit)
		})
	}

	const handleSetChosenAddParagraphEdit = () => {
		let editMatch = edits && edits.length > 0 && edits.filter(m => m.type === 'ADDPARAGRAPH')
		editMatch && editMatch.length > 0 && editMatch.forEach(edit => {
			editorService.unshowAddParagraphIcons(edit)
		})
	}

	const handleSetChosenDeleteParagraphEdit = (elementId, clearLast) => {
		editorService.unshowDeleteParagraphIcons(clearLast ? '' : elementId)
	}

	const handleSetChosenAddParagraphSentenceEdit = () => {
		let editMatch = edits && edits.length > 0 && edits.filter(m => m.type === 'ADDPARAGRAPHSENTENCE')
		editMatch && editMatch.length > 0 && editMatch.forEach(edit => {
			editorService.unshowAddParagraphSentenceIcons(edit)
		})
	}

	const handleSetChosenAddSentenceEdit = () => {
		let editMatch = edits && edits.length > 0 && edits.filter(m => m.type === 'ADDSENTENCE')
		editMatch && editMatch.length > 0 && editMatch.forEach(edit => {
			editorService.unshowAddSentenceIcons(edit)
		})
	}

	const handleSetChosenDeleteSentenceEdit = () => {
		let editMatch = edits && edits.length > 0 && edits.filter(m => m.type === 'DELETESENTENCE')
		editMatch && editMatch.length > 0 && editMatch.forEach(edit => {
			editorService.unshowDeleteSentenceIcons(edit)
		})
	}

	const handleSetChosenAddListItemEdit = () => {
		let elements = document.querySelectorAll(`[data-type="ADDLISTITEM"]`)
		elements && elements.length > 0 && elements.forEach(m => {
			m.style.backgroundColor = backgroundColors.normal
			if (m.nodeName === 'IMG') {
				m.height = 22
			}
		})
	}

	const handleSetChosenDeleteListItemEdit = (elementId, clearLast) => {
		editorService.unshowDeleteListItemIcons(clearLast ? '' : elementId)
	}

	const handleSetChosenReorderListItemsEdit = (elementId, clearLast) => {
		editorService.unshowReorderListItemsIcons(clearLast ? '' : elementId)
	}

	const initIdFromSegments = (arr) => {
		let maxValue = 0;
		for(let i=0;i<arr.length;i++){
			if(arr[i].elementId > maxValue){
				maxValue = arr[i].elementId;
			}
		}
		return ++maxValue;
	}

	const getNextId = () => {
		return newId++
	}

	const saveEditorDivSegmentsPersistent = () => {
		let saveSegments = editorService.saveSegmentsToDB(segments, props.chapterId, getNextId)
		props.addOrUpdateSegments(props.personId, saveSegments)
	}

	const handleSetShowEditorFullText = (value) => {
		const {tabsData} = props;
		setShowEditorFullText(value)
		props.updatePersonConfig(personId, `ShowEditorFullText`, value)

		if (value) {
			if (chosenTab) {
				handleEditorTabChosen(chosenTab)
			} else {
				let currentTab = tabsData && tabsData.length > 0 && tabsData[0].id
				setChosenTab(currentTab)
				handleEditorTabChosen(currentTab)
			}
		}
	}

	const handleSetDisableEditorPopup = (value) => {
		setDisableEditorPopup(value)
		props.updatePersonConfig(personId, `DisableEditorPopup`, value)
	}

  const handleSetMoveTranslationToEditor = (value) => {
    setMoveTranslationToEditor(value)
    props.updatePersonConfig(personId, `MoveTranslationToEditor`, value)
  }

  const handleSetGoToNextSentence = (value) => {
    setGoToNextSentence(value)
    props.updatePersonConfig(personId, `GoToNextSentence`, value)
  }

  const handleEditorTabChosen = (newTab_personId) => {
		setChosenTab(newTab_personId)
		editorService.setSegmentViews(newTab_personId, workSummary, segments, edits, isAuthor, props.personId, props.editorName, tabsData, getNextId, chapterListLevels, addChapterListLevels, listLevelGeneral)
	}

	//let tabsFunction = isDraftView ? handleDraftTabs : handleEditorTabChosen  //eslint-disable-line
	let tabNav = isDraftView ? toggleDraftView : () => {}
	let navText = isDraftView && `Close Drafts`

  // const handleSaveNewBookmark = (newBookmarkName) => {
  //     const {personId, workSummary, saveNewBookmark, editReview} = props;
  //     saveNewBookmark(personId, props.chapterId, workSummary.languageId_current, editReview.sentenceChosen, newBookmarkName);
  // }
	//
  // const handleDeleteBookmark = () => {
  //   const {personId, workSummary, deleteBookmark} = props;
  //   deleteBookmark(personId, props.chapterId, workSummary.languageId_current, bookmarkChosen); //bookmarkChosen is the hrefId
  //   setIsShowingDeleteModal(false)
	//   setBookmarkChosen('');
  // }

	const handleSetEditTrackChanges = (value) => {
		setEditTrackChanges(value)
		props.updatePersonConfig(personId, `EditDifferenceView`, !props.personConfig.editDifferenceView)
	}

  const handleSetKeepCommentOn = (value) => {
    setKeepCommentOn(value)
    props.updatePersonConfig(personId, `KeepCommentOn`, !props.personConfig.keepCommentOn)
  }

	const handleClearAllEditTypes = () => {
		if (addParagraphSentence) handleSetAddParagraphSentence(false)
		if (addSentence) handleSetAddSentence(false)
		if (reorderListItems) handleSetReorderListItems(false)
		if (deleteListItem) handleSetDeleteListItem(false)
		if (addListItem) handleSetAddListItem(false)
		if (deleteSentence) handleSetDeleteSentence(false)
		if (addParagraphBreak) handleSetAddParagraphBreak(false)
		if (deleteParagraphBreak) handleSetDeleteParagraphBreak(false)
		if (moveSentences) handleSetMoveSentences(false)
	}

	const handleSetMoveSentences = (value) => {
		if (!value) {
			editorService.removeMoveEditIconsAll()
			setIsInitEdits('FORCE') //Just in case a move was not entirely completed and we need to get the orphan icons out of the text.
		}
		setMoveSentences(value)
		if (reorderListItems) handleSetReorderListItems(false)
		if (deleteListItem) handleSetDeleteListItem(false)
		if (addParagraphSentence) handleSetAddParagraphSentence(false)
		if (addSentence) handleSetAddSentence(false)
		if (deleteSentence) handleSetDeleteSentence(false)
		if (addListItem) handleSetAddListItem(false)
		if (addParagraphBreak) handleSetAddParagraphBreak(false)
		if (deleteParagraphBreak) handleSetDeleteParagraphBreak(false)
	}

	const handleSetAddParagraphSentence = (value) => {
		setAddParagraphSentence(value)
		if (reorderListItems) handleSetReorderListItems(false)
		if (deleteListItem) handleSetDeleteListItem(false)
		if (addListItem) handleSetAddListItem(false)
		if (addSentence) handleSetAddSentence(false)
		if (deleteSentence) handleSetDeleteSentence(false)
		if (addParagraphBreak) handleSetAddParagraphBreak(false)
		if (deleteParagraphBreak) handleSetDeleteParagraphBreak(false)
		if (moveSentences) handleSetMoveSentences(false)
	}

	const handleSetAddSentence = (value) => {
		setAddSentence(value)
		if (reorderListItems) handleSetReorderListItems(false)
		if (deleteListItem) handleSetDeleteListItem(false)
		if (addListItem) handleSetAddListItem(false)
		if (addParagraphSentence) handleSetAddParagraphSentence(false)
		if (deleteSentence) handleSetDeleteSentence(false)
		if (addParagraphBreak) handleSetAddParagraphBreak(false)
		if (deleteParagraphBreak) handleSetDeleteParagraphBreak(false)
		if (moveSentences) handleSetMoveSentences(false)
	}

	const handleSetDeleteSentence = (value) => {
		setCurrentElement(null) //We don't want to assume that the current sentence is the one we want to start with as far as deleting is concerned.
		setDeleteSentence(value)
		if (reorderListItems) handleSetReorderListItems(false)
		if (deleteListItem) handleSetDeleteListItem(false)
		if (addListItem) handleSetAddListItem(false)
		if (addParagraphSentence) handleSetAddParagraphSentence(false)
		if (addSentence) handleSetAddSentence(false)
		if (addParagraphBreak) handleSetAddParagraphBreak(false)
		if (deleteParagraphBreak) handleSetDeleteParagraphBreak(false)
		if (moveSentences) handleSetMoveSentences(false)
	}

	const handleSetAddListItem = (value) => {
		setAddListItem(value)
		if (reorderListItems) handleSetReorderListItems(false)
		if (deleteListItem) handleSetDeleteListItem(false)
		if (addParagraphSentence) handleSetAddParagraphSentence(false)
		if (addSentence) handleSetAddSentence(false)
		if (deleteSentence) handleSetDeleteSentence(false)
		if (addParagraphBreak) handleSetAddParagraphBreak(false)
		if (deleteParagraphBreak) handleSetDeleteParagraphBreak(false)
		if (moveSentences) handleSetMoveSentences(false)
	}

	const handleSetDeleteListItem = (value) => {
		setDeleteListItem(value)
		if (reorderListItems) handleSetReorderListItems(false)
		if (addListItem) handleSetAddListItem(false)
		if (addParagraphSentence) handleSetAddParagraphSentence(false)
		if (addSentence) handleSetAddSentence(false)
		if (deleteSentence) handleSetDeleteSentence(false)
		if (addParagraphBreak) handleSetAddParagraphBreak(false)
		if (deleteParagraphBreak) handleSetDeleteParagraphBreak(false)
		if (moveSentences) handleSetMoveSentences(false)
	}

	const handleSetReorderListItems = (value) => {
		setReorderListItems(value)
		if (deleteListItem) handleSetDeleteListItem(false)
		if (addListItem) handleSetAddListItem(false)
		if (addParagraphSentence) handleSetAddParagraphSentence(false)
		if (addSentence) handleSetAddSentence(false)
		if (deleteSentence) handleSetDeleteSentence(false)
		if (addParagraphBreak) handleSetAddParagraphBreak(false)
		if (deleteParagraphBreak) handleSetDeleteParagraphBreak(false)
		if (moveSentences) handleSetMoveSentences(false)
	}

	const handleSetAddParagraphBreak = (value) => {
		setAddParagraphBreak(value)
		if (reorderListItems) handleSetReorderListItems(false)
		if (deleteListItem) handleSetDeleteListItem(false)
		if (addParagraphSentence) handleSetAddParagraphSentence(false)
		if (addSentence) handleSetAddSentence(false)
		if (deleteSentence) handleSetDeleteSentence(false)
		if (addListItem) handleSetAddListItem(false)
		if (deleteParagraphBreak) handleSetDeleteParagraphBreak(false)
		if (moveSentences) handleSetMoveSentences(false)
	}

	const handleSetDeleteParagraphBreak = (value) => {
		setDeleteParagraphBreak(value)
		if (reorderListItems) handleSetReorderListItems(false)
		if (deleteListItem) handleSetDeleteListItem(false)
		if (addParagraphSentence) handleSetAddParagraphSentence(false)
		if (addSentence) handleSetAddSentence(false)
		if (deleteSentence) handleSetDeleteSentence(false)
		if (addListItem) handleSetAddListItem(false)
		if (addParagraphBreak) handleSetAddParagraphBreak(false)
		if (moveSentences) handleSetMoveSentences(false)
	}

	const handleSetSidePanel = (value) => {
		setSidePanel(value ? '268px' : '0px')
		props.updatePersonConfig(personId, `ShowSidePanel`, !props.personConfig.showSidePanel)
	}

	// const handleMissingBookmarkClose = () => setIsShowingMissingBookmarkModal(false)
	// const handleDeleteClose = () => setIsShowingDeleteModal(false)
	// const handleDeleteOpen = () => {
  //       if (!bookmarkChosen || bookmarkChosen === "- -") {
  //           setIsShowingMissingBookmarkModal(true)
  //           return;
  //       }
  //       setIsShowingDeleteModal(true)
  //   }

	// const handlePenspringHomeworkOpen = () => setIsShowingPenspringHomework(true)
	// const handlePenspringHomeworkClose = () => setIsShowingPenspringHomework(false)
	// const handlePenspringHomework = () => {
	// 		const {personId, workSummary, setPenspringHomeworkSubmitted} = props;
	// 		setPenspringHomeworkSubmitted(personId, workSummary.workId);
	// 		//saveByButtonPress();
	// 		handlePenspringHomeworkClose();
	// 		setLocalHomeworkSubmitDate(new Date())
	// }

	// const handlePenspringDistributeOpen = () => setIsShowingPenspringDistribute(true)
	// const handlePenspringDistributeClose = () => setIsShowingPenspringDistribute(false)
	// const handlePenspringDistribute = () => {
	// 		const {personId, workSummary, setPenspringDistributeSubmitted} = props;
	// 		setPenspringDistributeSubmitted(personId, workSummary.workId);
	// 		handlePenspringDistributeClose();
	// 		setLocalDistributeSubmitDate(new Date())
	// }

	const getNextElement = (direction = 'NEXT') => {
		//ToDo: Take the current element and go forward or backward - taking paragraph children into consideration
		if (direction === 'PREV') {

		} else {

		}
	}

	const handleSetCurrentElement = (element, savedCursorPosition) => {
    if (savedCursorPosition && (!element || element.id === '1' || element.id === 'editorDiv')) {
      element = savedCursorPosition && savedCursorPosition.newElement
    }
    //Be sure that the element is not a sub-span without an id which belongs to a penspring-span-segment with an id. Get the parent span with the id.
    let loop = 0
    while (element && element.nodeName === 'SPAN' && !element.id && loop < 7) {
      element = element.parentElement
      loop++
    }
    if (!element || (element.nodeName === 'SPAN' && !element.id)) return

    //replace element with the editorDiv version and take out tabView
    if (element && element.id && element.id.indexOf('tabView') > -1) {
			if (element.type === 'ADDPARAGRAPHSENTENCE') {
				element = document.querySelector(`${element.nodeName}[id="${element.id.replace('~tabView', '')}"][data-type="${element.dataset.type}"][data-subsequence="${element.dataset.subSequence}"]`)
			} else {
      	element = document.querySelector(`${element.nodeName}[id="${element.id.replace('~tabView', '')}"][data-type="${element.dataset.type}"]`)
			}
    }
		//1. If there is a previous element, set the background color back to what it was in the editor's window
		//2. For this newly chosen element
		//    a. Save it's current background color and the element Id
		//    b. Set the background color to yellow
		if (previousElement && previousElement.elementId) {
			let prev = document.querySelector(`[id="${previousElement.elementId + '~tabView'}"][data-type="TEXT"]`)
			if (prev) prev.style.backgroundColor = previousElement.backgroundColor
			prev = document.querySelector(`[id="${previousElement.elementId}"][data-type="TEXT"]`)
			if (prev) prev.style.backgroundColor = previousElement.backgroundColor
		}
		if (element && element.dataset && (element.dataset.type === 'TEXT' || element.dataset.type === 'REORDERLISTITEMS')) {
      const query = element.dataset.type === 'REORDERLISTITEMS' ? `[id="${element.id + '~tabView'}"][data-type="REORDERLISTITEMS"]` : `[id="${element.id + '~tabView'}"][data-type="TEXT"]`
			let elementSelected = document.querySelector(query)
			if (elementSelected) {
        if (Number(element.id) !== Number(previousElement.elementId)) {
          setPreviousElement({
            elementId: element.id,
            backgroundColor: elementSelected.style.backgroundColor,
            type: elementSelected.dataset && elementSelected.dataset.type
          })
        }
				elementSelected.style.backgroundColor = backgroundColors.currentFocus
			}
		}
    if (element) {
      if (!isAuthor && element.nodeName === 'SPAN') element.contentEditable = 'true'
      setCurrentElement(element)
			localStorage.setItem(`scrollCurrentElementId-${workSummary.workId}`, element && element.id) //Help ToDo: I'm not sure what this is doing here. It seems that the item name should just be the scrollCurrentElementId without the additioal id on it. Ohterwise, you are going to get many entries and who knows how to get the latest scrollCurrentElementId?
    }
    return element
	}

	return (
		<div id={'mainPage'} className={styles.editReviewPage}>
			<div className={styles.rowEditReview}>
				<SentenceEdits
					handleSetChosenSegment={handleSetChosenSegment}
					isTranslation={isTranslation}
					isOpenSlideOut={isOpenSlideOut}
					setIsOpenSlideOut={setIsOpenSlideOut}
					localTranslation={localTranslation}
					setLocalTranslation={setLocalTranslation}
					getTranslation={getTranslation}
					setTabSelected={handleEditorTabChosen}
					chosenTab={chosenTab}
					currentElement={currentElement}
					handleSetCurrentElement={handleSetCurrentElement}
					getNextElement={getNextElement}
					saveEditorDivSegmentsPersistent={saveEditorDivSegmentsPersistent}
					setIsInitEdits={setIsInitEdits}
					handleSetChosenMoveEdit={handleSetChosenMoveEdit}
					handleSetChosenAddParagraphEdit={handleSetChosenAddParagraphEdit}
					handleSetChosenDeleteParagraphEdit={handleSetChosenDeleteParagraphEdit}
					handleSetChosenAddParagraphSentenceEdit={handleSetChosenAddParagraphSentenceEdit}
					handleSetChosenAddSentenceEdit={handleSetChosenAddSentenceEdit}
					handleSetChosenDeleteSentenceEdit={handleSetChosenDeleteSentenceEdit}
					handleSetChosenAddListItemEdit={handleSetChosenAddListItemEdit}
					handleSetChosenDeleteListItemEdit={handleSetChosenDeleteListItemEdit}
					handleSetChosenReorderListItemsEdit={handleSetChosenReorderListItemsEdit}
					getNextId={getNextId}
					contextMenu={contextMenu}
					setContextMenu={setContextMenu}
					initialContextMenu={initialContextMenu}
					editLanguageId={editLanguageId}
					addParagraphSentence={addParagraphSentence} setAddParagraphSentence={!isAuthor && handleSetAddParagraphSentence}
					addSentence={addSentence} setAddSentence={!isAuthor && handleSetAddSentence}
					deleteSentence={deleteSentence} setDeleteSentence={handleSetDeleteSentence}
					addListItem={addListItem} setAddListItem={handleSetAddListItem}
					deleteListItem={deleteListItem} setDeleteListItem={handleSetDeleteListItem}
					reorderListItems={reorderListItems} setReorderListItems={handleSetReorderListItems}
					addParagraphBreak={addParagraphBreak} setAddParagraphBreak={!isAuthor && handleSetAddParagraphBreak}
					deleteParagraphBreak={deleteParagraphBreak} setDeleteParagraphBreak={!isAuthor && handleSetDeleteParagraphBreak}
					editTrackChanges={editTrackChanges} setEditTrackChanges={handleSetEditTrackChanges}
					keepCommentOn={keepCommentOn} setKeepCommentOn={handleSetKeepCommentOn}
					moveSentences={moveSentences} setMoveSentences={!isAuthor && handleSetMoveSentences}
					showEditorFullText={showEditorFullText} setShowEditorFullText={handleSetShowEditorFullText}
					moveTranslationToEditor={moveTranslationToEditor} setMoveTranslationToEditor={handleSetMoveTranslationToEditor}
					goToNextSentence={goToNextSentence} setGoToNextSentence={handleSetGoToNextSentence}
					hasListStructure={hasListStructure}
					isMobile={isMobile}
					{...props} />

		    <div className={styles.editorPanel}>
		      <AuthoringEditor 
						currentElement={currentElement}
						handleSetChosenSegment={handleSetChosenSegment}
						handleSetCurrentElement={handleSetCurrentElement}
						chosenSegment={chosenSegment}
						// setChosenSegment={setChosenSegment}  //The handle version are sent up above. It might be safer to send the handle version.
						// setCurrentElement={setCurrentElement}
						getNextId={getNextId}
						chosenTab={chosenTab}
						deleteParagraphBreak={deleteParagraphBreak}
						setDeleteParagraphBreak={handleSetDeleteParagraphBreak}
						addParagraphSentence={addParagraphSentence}
						setAddParagraphSentence={handleSetAddParagraphSentence}
						addSentence={addSentence}
						setAddSentence={handleSetAddSentence}
						deleteSentence={deleteSentence}
						setDeleteSentence={handleSetDeleteSentence}
						addListItem={addListItem}
						setAddListItem={handleSetAddListItem}
						deleteListItem={deleteListItem}
						setDeleteListItem={handleSetDeleteListItem}
						reorderListItems={reorderListItems}
						setReorderListItems={handleSetReorderListItems}
						addParagraphBreak={addParagraphBreak}
						setAddParagraphBreak={handleSetAddParagraphBreak}
						moveSentences={moveSentences}
						setMoveSentences={handleSetMoveSentences}
						setMoveSequence={setMoveSequence}
						clearAllEditTypes={handleClearAllEditTypes}
						setIsInitEdits={setIsInitEdits}
						setChosenMoveEdit={handleSetChosenMoveEdit}
						setChosenAddParagraphEdit={handleSetChosenAddParagraphEdit}
						setChosenDeleteParagraphEdit={handleSetChosenDeleteParagraphEdit}
						setChosenAddParagraphSentenceEdit={handleSetChosenAddParagraphSentenceEdit}
						setChosenAddSentenceEdit={handleSetChosenAddSentenceEdit}
						setChosenDeleteSentenceEdit={handleSetChosenDeleteSentenceEdit}
						setChosenAddListItemEdit={handleSetChosenAddListItemEdit}
						setChosenDeleteListItemEdit={handleSetChosenDeleteListItemEdit}
						setChosenReorderListItemsEdit={handleSetChosenReorderListItemsEdit}
						editTrackChanges={editTrackChanges} 
						setEditTrackChanges={handleSetEditTrackChanges}
						keepCommentOn={keepCommentOn} 
						setKeepCommentOn={handleSetKeepCommentOn}
						showEditorFullText={showEditorFullText}
						setShowEditorFullText={handleSetShowEditorFullText}
						moveTranslationToEditor={moveTranslationToEditor}
						setMoveTranslationToEditor={handleSetMoveTranslationToEditor}
						goToNextSentence={goToNextSentence} 
						setGoToNextSentence={handleSetGoToNextSentence}
						handleEditorTabChosen={handleEditorTabChosen}
						tabNav={tabNav}
						navText={navText}
						getNextElement={getNextElement}
						isTranslation={isTranslation}
						localTranslation={localTranslation}
						getTranslation={getTranslation}
						setTabSelected={handleEditorTabChosen}
						saveEditorDivSegmentsPersistent={saveEditorDivSegmentsPersistent}
						contextMenu={contextMenu}
						setContextMenu={setContextMenu}
						initialContextMenu={initialContextMenu}
						disableEditorPopup={disableEditorPopup}
						setHasListStructure={setHasListStructure}
						tabsData={tabsData}
						workSummary={workSummary}
						editLanguageId={editLanguageId}
						setShowClickInstruction={setShowClickInstruction}
						setIsOpenSlideOut={setIsOpenSlideOut}
						{...props} />
		    </div>
			</div>

      {!isMobile && 
				<div className={styles.rowSpace}>
					<ToggleBoard 
						isAuthor={isAuthor} sidePanel={sidePanel} setSidePanel={handleSetSidePanel}
						editTrackChanges={editTrackChanges} setEditTrackChanges={handleSetEditTrackChanges}
						keepCommentOn={keepCommentOn} setKeepCommentOn={handleSetKeepCommentOn}
						moveSentences={moveSentences} setMoveSentences={!isAuthor && handleSetMoveSentences}
						moveSequence={moveSequence} 
						addParagraphSentence={addParagraphSentence} setAddParagraphSentence={!isAuthor && handleSetAddParagraphSentence}
						addSentence={addSentence} setAddSentence={!isAuthor && handleSetAddSentence}
						deleteSentence={deleteSentence} setDeleteSentence={handleSetDeleteSentence}
						addListItem={addListItem} setAddListItem={handleSetAddListItem}
						deleteListItem={deleteListItem} setDeleteListItem={handleSetDeleteListItem}
						reorderListItems={reorderListItems} setReorderListItems={handleSetReorderListItems}
						addParagraphBreak={addParagraphBreak} setAddParagraphBreak={!isAuthor && handleSetAddParagraphBreak}
						deleteParagraphBreak={deleteParagraphBreak} setDeleteParagraphBreak={!isAuthor && handleSetDeleteParagraphBreak}
						disableEditorPopup={disableEditorPopup} setDisableEditorPopup={handleSetDisableEditorPopup}
						showEditorFullText={showEditorFullText} setShowEditorFullText={handleSetShowEditorFullText}
						moveTranslationToEditor={moveTranslationToEditor} setMoveTranslationToEditor={handleSetMoveTranslationToEditor}
						goToNextSentence={goToNextSentence} setGoToNextSentence={handleSetGoToNextSentence}
						hasListStructure={hasListStructure}
						isTranslation={isTranslation}
						setShowClickInstruction={setShowClickInstruction} />
				</div>
			}
      <MessageModal show={showClickInstruction && !hideClickInstruction} handleClose={() => setShowClickInstruction(false)} heading={`After Choosing an Edit Type...`}
                    explainJsx={
                      <div>
                        Click on a sentence where you want to make the edit.<br/><br/>
                        <Checkbox
                          label={`Got it! Don't show this message again`}
                          position={`before`}
                          checked={hideClickInstruction}
                          onClick={handleHideClickInstruction} />
                      </div>
                    }
                    onClick={() => setShowClickInstruction(false)} />

		  {/*    <MessageModal show={isShowingPenspringHomework} handleClose={handlePenspringHomeworkClose} heading={`Submit this homework?`}*/}
		  {/*       explain={`Are you sure you want to submit this homework?  The teacher will be able to see your penspring file with your assignment in eCademyApp.`} isConfirmType={true}*/}
		  {/*       onClick={handlePenspringHomework} />*/}
		  {/*    <MessageModal show={isShowingPenspringDistribute} handleClose={handlePenspringDistributeOpen} heading={`Distribute this homework?`}*/}
		  {/*       explain={`Are you sure you want to distribute this homework?  The assignment will be copied to each student as if they were the oroginal authors.  You will become an editor to each assignment.  You will be able to see the penspring file in the eCademyApp gradebook when the student completes and submits their work.`}*/}
			{/*			 isConfirmType={true} onClick={handlePenspringDistribute} />*/}
    </div>
	)
}

export default EditReviewView;

// {isShowingLoadingModal &&
//     <LoadingModal handleClose={handleLoadingModalClose} loadingText={`Loading`} isLoading={fetchingRecord && !fetchingRecord.chapterText && !fetchingRecord.editDetails} />
// }

//value={chapterText}


// <Media query="(max-width: 799px)">
//   {matches =>
//     matches ? (
//       <p>The document is less than 600px wide.</p>
//     ) : (
//       <p ref={ref => {testThis = ref}}>The document is GREATER than 600px wide.</p>
//     )
//   }
// </Media>

//<Media query="(max-width: 599px)" ren der={() => <p>The document is less than 600px wide.</p>} />


//dangerouslySetInnerHTML={{__html: chapterTextWriterSide}}


//Scoring an assignment
		//score: '', part of the score function that isn't working due to the cursor location management or EditorDiv.
		//import InputText from '../../components/InputText';  part of the score function that isn't working due to the cursor location management or EditorDiv.

		//Due to the restoreSelection in the EditorDiv, this became a big problem as it was not yet possible to keep the user control in the editor control.  Not good. Not worth losing the coordinated cursor in editorDiv
		// onBlurScore = (event) => {
		// 		const {setGradebookScoreByPenspring, personId, workSummary } = props;
		// 		setGradebookScoreByPenspring(personId, workSummary.studentAssignmentResponseId, event.target.value);
		// }
		//
		// handleScore = (event) => {
		// 		setState({ score: event.target.value, isScoreUpdateOnly: true });
		// 		event.stopPropagation();
		// 		event.preventDefault();
		// }
		//
		// {/*!workSummary.isHomework
		// 		? ''
		// 		: workSummary.canEditScore || workSummary.accessRoleName === 'Facilitator' || true
		// 				? <div className={styles.inputBox}>
		// 							<InputText size={"super-short"}
		// 									label={`score  (${workSummary.totalPoints})`}
		// 									value={score || (workSummary && workSummary.score) || ''}
		// 									numberOnly={true}
		// 									onChange={(event) => handleScore(event)}
		// 									onBlur={(event) => onBlurScore(event)} />
		// 					</div>
		// 				: <TextDisplay label={`Score (${workSummary.totalPoints})`} text={workSummary.score || '- -'} />
		// */}



//<div className={styles.lineHeight}>Save<br/><div className={styles.smallText}><span>auto: </span><DateMoment date={saveWorkSpaceTime} format={'h:mm:ss a'} className={styles.buttonTimeDisplay}/></div></div>
//I took out the auto save message from the button since I was having trouble with chapterText bleeding over into the next document while making use of ComponentWillUnmount.  There were errors.
