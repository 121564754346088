import React, {useState, useEffect} from 'react'
import styles from './MyWorksView.module.css'
import * as guid from '../../utils/GuidValidate.js'
import WorkFilter from '../../components/WorkFilter'
import FileTreeSubContents from '../../components/WorkFileTreeSubContents'
import DocumentSectionControl from '../../components/DocumentSectionControl'
import Icon from '../../components/Icon'
import SelectSingleDropDown from '../../components/SelectSingleDropDown'
import Checkbox from '../../components/Checkbox'
import OneFJefFooter from '../../components/OneFJefFooter'
import classes from 'classnames'
import { useMediaQuery } from "react-responsive"

const searchDocumentCount = 10  //Until the search controls are shown - but either mine or others have to be greater than this number

function MyWorksView(props) {
	const {
		personId,
		fileTreeExplorer = [],
		updateFilterByField,
		clearFilters,
		filterScratch,
		savedFilterIdCurrent,
		workFilterOptions,
		updateSavedSearch,
		updateFilterDefaultFlag,
		deleteSavedSearch,
		chooseSavedSearch,
		saveNewSavedSearch,
		fetchingRecord,
		group,
		setPenspringHomeworkSubmitted,
		getMyWorks,
		assignWorkToWorkFolder,
		addWorkFolder,
		deleteWorkFolder,
		removeMemberByWorkFolderId,
		removeEditorByAuthor,
		setGradebookScoreByPenspring,
		toggleExpanded,
		setPenspringDistributeSubmitted,
		deleteWorkFromFolder,
    groups,
	} = props

	const isMobile = useMediaQuery({ query: '(max-width: 500px)' })

  const [chosenWork, setChosenWork] = useState('')
  const [chosenFolder, setChosenFolder] = useState('')
	const [workFolderMineExpanded, setWorkFolderMineExpanded] = useState(props.personConfig && props.personConfig.workFolderMineExpanded)
	const [workFolderOthersExpanded, setWorkFolderOthersExpanded] = useState(props.personConfig && props.personConfig.workFolderOthersExpanded)
	const [workFolderId, setWorkFolderId] = useState()
	const [showSearchControls, setShowSearchControls] = useState(false)
	const [isMoveWorkId, setIsMoveWorkId] = useState()
  const [showDocumentDetails, setShowDocumentDetails] = useState(true)
  const [showTempControls, setShowFolderControls] = useState(false)
	const [fileTreeExpanded, setFileTreeExpanded] = useState()

  useEffect(() => {
    let showDetails = localStorage.getItem('showDocumentDetails')
    if (showDetails == 'true') setShowDocumentDetails(true)
		let newExpanded = localStorage.getItem("fileTreeExpanded")
		if (newExpanded) newExpanded = newExpanded.split(",")
		setFileTreeExpanded(newExpanded)
	}, [])

	useEffect(() => {
		let hasInitFileTreeExpanded = localStorage.getItem("hasInitFileTreeExpanded")
		if (!hasInitFileTreeExpanded) {
			setTimeout(() => handleToggleAllExpanded(true), 1000)
		}
	}, [fileTreeExplorer])

  const handleSetShowDocumentDetails = () => {
    setShowDocumentDetails(!showDocumentDetails)
    localStorage.setItem('showDocumentDetails', !showDocumentDetails)
  }

  const handleSetChosenWork = (value) => {
    setChosenWork(value)
    setChosenFolder('')
  }

  const handleSetChosenFolder = (value) => {
    setChosenFolder(value)
    setChosenWork('')
  }

  const hasChosenWorkInFolder = (workFolderId, mineOrOthers) => {
		if (!chosenWork) return false
		let foundMatch = false
		for (let i = 0; i < fileTreeExplorer.length; i++) {
			if (fileTreeExplorer[i].workFolderId === workFolderId) {
				foundMatch = fileTreeExplorer[i].files && fileTreeExplorer[i].files.length > 0 && fileTreeExplorer[i].files.filter(f => f.workId.toLowerCase() === chosenWork.workId.toLowerCase())[0]
				if (foundMatch) break
			}
			if (fileTreeExplorer[i].subFolders && fileTreeExplorer[i].subFolders.length > 0) foundMatch = searchForWorkInFolder(fileTreeExplorer[i].subFolders, workFolderId, fileTreeExplorer[i].mineOrOthers)
			if (foundMatch) break
		}
		return foundMatch
	}

	const searchForWorkInFolder = (subFolders, workFolderId, mineOrOthers) => {
		let foundMatch = false
		for (let i = 0; i < subFolders.length; i++) {
			if (subFolders[i].workFolderId === workFolderId) {
				foundMatch = subFolders[i].files && subFolders[i].files.length > 0 && subFolders[i].files.filter(f => f.workId.toLowerCase() === chosenWork.workId.toLowerCase())[0]
				if (!!foundMatch) break
			}
			if (subFolders[i].subFolders && subFolders[i].subFolders.length > 0) foundMatch = searchForWorkInFolder(subFolders[i].subFolders, workFolderId, subFolders[i].mineOrOthers)
			if (!!foundMatch) break
		}
		return foundMatch
	}

	const moveWorkToFolder = (type, workFolderId) => {
		if (type === 'end') {
      props.assignWorkToWorkFolder(personId, isMoveWorkId, workFolderId)
    }
		setIsMoveWorkId(type === 'start' ? chosenWork.workId : null)
	}

	const toggleSearchControls = () => setShowSearchControls(!showSearchControls)

	const handleToggleAllExpanded = (expandAll) => {
		const {personId, updatePersonConfig} = props
		toggleAllExpandedLocal(expandAll)
		updatePersonConfig(personId, 'WorkFolderMineExpanded', expandAll)
		updatePersonConfig(personId, 'WorkFolderOthersExpanded', expandAll)
		setWorkFolderMineExpanded(expandAll)
		setWorkFolderOthersExpanded(expandAll)
	}

	const getWorkFolderIds = (folders, workFolderIds = []) => {
		folders && folders.length > 0 && folders.forEach(folder => {
			workFolderIds.push(folder.workFolderId);
			if (folder.subFolders && folder.subFolders.length > 0) {
				getWorkFolderIds(folder.subFolders, workFolderIds);
			}
		});

		return workFolderIds;
	}

	const handleUpdatePersonConfig = (field) => {
		const {updatePersonConfig, personId} = props
		if (field === 'WorkFolderMineExpanded') {
			updatePersonConfig(personId, 'WorkFolderMineExpanded', !workFolderMineExpanded)
			setWorkFolderMineExpanded(!workFolderMineExpanded)
		}
		if (field === 'WorkFolderOthersExpanded') {
			updatePersonConfig(personId, 'WorkFolderOthersExpanded', !workFolderOthersExpanded)
			setWorkFolderOthersExpanded(!workFolderOthersExpanded)
		}
	}

	const fileLength = () => {
		let mineLength = (fileTreeExplorer && fileTreeExplorer.filter(m => m.mineOrOthers === 'Mine').length) || 0
		let othersLength = (fileTreeExplorer && fileTreeExplorer.filter(m => m.mineOrOthers === 'Others').length) || 0
		return mineLength > othersLength ? mineLength : othersLength
	}

	let myWorkCount = 0
	let othersWorkCount = 0

	const getMyWorkCount = (workFolder = []) => {
		workFolder.filter(m => m.mineOrOthers === 'Mine').forEach(m => {
			myWorkCount += m.files && m.files.length
			if (m.subFolders && m.subFolders.length) getMyWorkCount(m.subFolders)
		})
		return myWorkCount
	}

	const getOthersWorkCount = (workFolder = []) => {
		workFolder.filter(m => m.mineOrOthers === 'Others').forEach(m => {
			othersWorkCount += m.files && m.files.length
			if (m.subFolders && m.subFolders.length) getOthersWorkCount(m.subFolders)
		})
		return othersWorkCount
	}

  const hasMineSubFolders = () => {
    let subfolders = fileTreeExplorer && fileTreeExplorer.length > 0 && fileTreeExplorer.filter(m => m.mineOrOthers === 'Mine' && m.folderName)
    return subfolders && subfolders.length > 0
  }

	const toggleAllExpandedLocal = (expandAll) => {
		let newExpanded = ''
		if (expandAll) {
			let newExpandedArray = getWorkFolderIds(fileTreeExplorer)
			if (newExpandedArray && newExpandedArray.length > 0) {
				newExpanded = newExpandedArray.join(",")
			}
		}
		localStorage.setItem("fileTreeExpanded", newExpanded);
		localStorage.setItem("hasInitFileTreeExpanded", true);
		setFileTreeExpanded(newExpanded.split(","))
	}

	const toggleExpandedLocal = (workFolderId, forceExpanded) => {
		let newExpanded = localStorage.getItem("fileTreeExpanded")
		if (newExpanded) newExpanded = newExpanded.split(",")
		if (!forceExpanded && newExpanded && newExpanded.length > 0 && newExpanded.indexOf(workFolderId) > -1) {
			newExpanded.splice(newExpanded.indexOf(workFolderId), 1)
		} else {
			newExpanded = newExpanded && newExpanded.length > 0 ? newExpanded.concat(workFolderId) : [workFolderId]
		}
		localStorage.setItem("fileTreeExpanded", newExpanded.toString());
		localStorage.setItem("hasInitFileTreeExpanded", true);
		setFileTreeExpanded(newExpanded)
	}

	const isExpanded = (workFolderId) => {
		return fileTreeExpanded && fileTreeExpanded.length > 0 && fileTreeExpanded.indexOf(workFolderId) > -1
	}

	return (
		<div className={styles.container}>
			<div className={styles.mainDiv}>
				{fileLength() > searchDocumentCount &&
					<hr/>
				}
				{fileLength() > searchDocumentCount &&
					<div onClick={toggleSearchControls} className={classes(styles.menuItem, styles.row)}>
						<Icon pathName={'magnifier'} premiuim={true} className={styles.icon}/>
						<div className={styles.link}>{showSearchControls ? `hide search controls` : `show search controls`}</div>
					</div>
				}
				{group &&
					<div className={styles.subTitle}>{`For group: `} {group && group.groupName}</div>
				}
				{showSearchControls &&
					<div className={styles.menuItem}>
						<div>
							<SelectSingleDropDown
								id={`filter`}
								label={'Saved searches'}
								value={savedFilterIdCurrent ? savedFilterIdCurrent : ''}
								options={workFilterOptions}
								error={''}
								height={`medium`}
								onChange={(event) => chooseSavedSearch(personId, event.target.value)}/>
						</div>
						{guid.isGuidNotEmpty(savedFilterIdCurrent) &&
							<div>
								<div className={classes(styles.row, styles.marginLessTop)}>
									<a onClick={() => updateSavedSearch(personId, savedFilterIdCurrent)} className={styles.linkStyle}>
										<Icon pathName={`floppy_disk`} className={styles.image}/>
									</a>
									<a onClick={() => deleteSavedSearch(personId, savedFilterIdCurrent)} className={styles.linkStyle}>
										<Icon pathName={`garbage_bin`} className={styles.image}/>
									</a>
									<a onClick={() => clearFilters(personId)} className={styles.linkStyle}>
										<Icon pathName={`document_refresh`} className={styles.image}/>
									</a>
									<Checkbox
										id={`filterDefault`}
										label={`Default`}
										labelClass={styles.labelCheckbox}
										position={`before`}
										checked={filterScratch.defaultFlag}
										onClick={() => updateFilterDefaultFlag(personId, savedFilterIdCurrent, !filterScratch.defaultFlag)}
										className={styles.checkbox}/>
								</div>
							</div>
						}
						<WorkFilter 
							personId={personId} workFilter={filterScratch} className={styles.workFilter}
							updateFilterByField={updateFilterByField}
							clearFilters={clearFilters} saveNewSavedSearch={saveNewSavedSearch}
							savedSearchOptions={workFilterOptions}/>
					</div>
				}
				{/*<Loading loadingText={`Loading`} isLoading={fetchingRecord && fetchingRecord.works} />*/}
				{/*!fetchingRecord.works &&*/}
				<div className={classes(styles.menuItem, styles.rowSpaceBetween)}>
          <div className={styles.row}>
            <div className={styles.link} onClick={() => handleToggleAllExpanded(true)}>{`expand all`}</div>
            <div className={styles.divider}> |</div>
            <div className={styles.link} onClick={() => handleToggleAllExpanded(false)}>{`collapse all`}</div>
          </div>
          <div>
            <Checkbox
              id={`showDocumentDetails`}
              label={`Show document details`}
              labelClass={styles.link}
              position={`before`}
              checked={showDocumentDetails}
              onClick={handleSetShowDocumentDetails}
              className={styles.checkbox}/>
          </div>
				</div>
				<div className={isMobile ? styles.backgroundMobile : styles.background}>
          <DocumentSectionControl 
						expanded={workFolderMineExpanded == undefined || !!workFolderMineExpanded}
						label={'My Documents'}
						workCount={getMyWorkCount(fileTreeExplorer)}
						personId={personId}
						mineOrOthers={'Mine'}
						chosenWork={chosenWork} setChosenWork={handleSetChosenWork}
						chosenFolder={chosenFolder} setChosenFolder={handleSetChosenFolder}
						moveWorkToFolder={moveWorkToFolder}
						workFolderId={workFolderId}
						isMoveWorkId={isMoveWorkId}
						hasChosenWorkInFolder={hasChosenWorkInFolder}
						onClick={() => handleUpdatePersonConfig('WorkFolderMineExpanded')}
						deleteWork={props.deleteWork}
						addWorkFolder={addWorkFolder} hasMineSubFolders={hasMineSubFolders()}/>

          <div className={workFolderMineExpanded == undefined || !!workFolderMineExpanded ? styles.show : styles.hide}>
            <FileTreeSubContents 
							fileTreeExplorer={fileTreeExplorer.filter(m => m.mineOrOthers === 'Mine')}
							fullFileTreeExplorer={fileTreeExplorer}
							isParentExpanded={workFolderMineExpanded == undefined || !!workFolderMineExpanded}
							assignWorkToWorkFolder={assignWorkToWorkFolder}
							toggleExpanded={toggleExpanded} personId={personId} mineOrOthers={'Mine'}
							toggleExpandedLocal={toggleExpandedLocal}
							isExpanded={isExpanded}
							//setPenspringHomeworkSubmitted={setPenspringHomeworkSubmitted}
							//setGradebookScoreByPenspring={setGradebookScoreByPenspring}
							getMyWorks={getMyWorks} fetchingRecord={fetchingRecord}
							hasChosenWorkInFolder={hasChosenWorkInFolder}
							chosenWork={chosenWork} setChosenWork={handleSetChosenWork}
							chosenFolder={chosenFolder} setChosenFolder={handleSetChosenFolder}
							showTempControls={showTempControls} setShowFolderControls={setShowFolderControls}
							setPenspringDistributeSubmitted={setPenspringDistributeSubmitted}
							isMoveWorkId={isMoveWorkId} moveWorkToFolder={moveWorkToFolder}
							deleteWorkFromFolder={deleteWorkFromFolder}
							deleteWork={props.deleteWork}
							deleteWorkFolder={deleteWorkFolder}
							removeMemberByWorkFolderId={removeMemberByWorkFolderId}
							removeEditorByAuthor={removeEditorByAuthor}
							addWorkFolder={addWorkFolder}
							showDocumentDetails={showDocumentDetails}
							groups={groups}/>
          </div>
          <DocumentSectionControl 
						expanded={workFolderOthersExpanded == undefined || !!workFolderOthersExpanded}
						label={'Documents Shared with Me'}
						workCount={getOthersWorkCount(fileTreeExplorer)}
						personId={personId}
						mineOrOthers={'Others'}
						chosenWork={chosenWork} setChosenWork={handleSetChosenWork}
						chosenFolder={chosenFolder} setChosenFolder={handleSetChosenFolder}
						moveWorkToFolder={moveWorkToFolder}
						workFolderId={workFolderId}
						isMoveWorkId={isMoveWorkId}
						hasChosenWorkInFolder={hasChosenWorkInFolder}
						onClick={() => handleUpdatePersonConfig('WorkFolderOthersExpanded')}
						deleteWork={props.deleteWork}
						addWorkFolder={addWorkFolder}/>

          <div className={workFolderOthersExpanded == undefined || !!workFolderOthersExpanded ? styles.show : styles.hide}>
            <FileTreeSubContents 
							isWorkAddView={false} //This is for the workAddView where a penspring file might need to be chosen for adding a new chapter
							fileTreeExplorer={fileTreeExplorer.filter(m => m.mineOrOthers === 'Others')}
							isParentExpanded={workFolderOthersExpanded == undefined || !!workFolderOthersExpanded}
							toggleExpanded={toggleExpanded} 
							toggleExpandedLocal={toggleExpandedLocal}
							isExpanded={isExpanded}
							personId={personId} 
							mineOrOthers={'Others'}
							setPenspringHomeworkSubmitted={setPenspringHomeworkSubmitted}
							getMyWorks={getMyWorks} 
							fetchingRecord={fetchingRecord}
							hasChosenWorkInFolder={hasChosenWorkInFolder}
							setGradebookScoreByPenspring={setGradebookScoreByPenspring}
							chosenWork={chosenWork} 
							setChosenWork={handleSetChosenWork}
							chosenFolder={chosenFolder} 
							setChosenFolder={handleSetChosenFolder}
							showTempControls={showTempControls} 
							setShowFolderControls={setShowFolderControls}
							setPenspringDistributeSubmitted={setPenspringDistributeSubmitted}
							isMoveWorkId={isMoveWorkId} 
							moveWorkToFolder={moveWorkToFolder}
							deleteWorkFromFolder={deleteWorkFromFolder}
							deleteWork={props.deleteWork}
							deleteWorkFolder={deleteWorkFolder}
							removeMemberByWorkFolderId={removeMemberByWorkFolderId}
							removeEditorByAuthor={removeEditorByAuthor}
							addWorkFolder={addWorkFolder}
							showDocumentDetails={showDocumentDetails}
							groups={groups}/>
          </div>
        </div>
      </div>
			<OneFJefFooter/>
		</div>
	)
}

export default MyWorksView

