import {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import globalStyles from '../../utils/globalStyles.module.css'
import styles from './GroupContactsView.module.css'
import classes from 'classnames'
import MessageModal from '../../components/MessageModal'
import JoinLinkGroup from '../../components/JoinLinkGroup'
import TextDisplay from '../../components/TextDisplay'
import DateMoment from '../../components/DateMoment'
import Checkbox from '../../components/Checkbox'
import ContactSummaryLine from '../../components/ContactSummaryLine'
import Icon from '../../components/Icon'
import InvitesPending from '../../components/InvitesPending'
import OneFJefFooter from '../../components/OneFJefFooter'
import {guidEmpty} from '../../utils/GuidValidate'
import ButtonWithIcon from '../../components/ButtonWithIcon/ButtonWithIcon'
import ContactsAddChoice from '../../components/ContactsAddChoice/ContactsAddChoice'

function GroupWorksView(props) {
  const { personId, group = { groupJoinInviteCode: false, contacts: [] }, editorInvitePending, groupId } = props
  const navigate = useNavigate()
  
  const [showDeleteGroupMessage, setShowDeleteGroupMessage] = useState(false)
  const [deleteContactMessage, setDeleteContactMessage] = useState('') //This will actually be a contact record reather than just a boolean since we need to pass that chosen contact along until it is confirmed to be deleted.
	const [pendingGroupInvites, setPendingGroupInvites] = useState([])

  useEffect(() => {
		setPendingGroupInvites(editorInvitePending && editorInvitePending.length > 0 && editorInvitePending.filter(m => m.groupId === groupId))
	},[editorInvitePending])

  const handleDeleteGroup = () => {
    setShowDeleteGroupMessage(false)
    props.deleteGroup(personId, group.groupId);
    navigate(`-1`)
  }

  const handleDeleteContact = () => {
    const contact = deleteContactMessage
    props.assignContactToGroup(personId, contact.personId, group.groupId, contact.firstName, contact.lastName, contact.photo, contact.emailAddress, true)
    setDeleteContactMessage(false)
  }

	return (
		<section className={styles.container}>
      <div className={styles.mainDiv}>
          <div className={styles.titleLine}>
            <span className={globalStyles.pageTitle}>Group Contacts</span>
          </div>
        <div className={styles.formLeft}>
          <div className={styles.row}>
            <TextDisplay label={`Group name`} text={group.groupName} />
            <Icon pathName={'pencil0'}
                  premium={true}
                  fillColor={'#2485a2'}
                  className={styles.pencilEdit}
                  onClick={() => navigate(`/groupAddNew/${group.groupId}`)}/>
            <span className={styles.editGroup} onClick={() => navigate(`/groupAddNew/${group.workFolderId}/${group.groupId}`)}>
              edit
            </span>
            <Icon pathName={'trash2'}
                  premium={true} fillColor={'red'}
                  className={styles.deleteGroupIcon}
                  onClick={() => setShowDeleteGroupMessage(true)}/>
            <span className={styles.deleteGroup} onClick={() => setShowDeleteGroupMessage(true)}>
              delete
            </span>
          </div>
          <TextDisplay label={`Internal id`} text={group.internalId} noDisplayIfBlank/>
          <TextDisplay label={`Description`} text={group.description} noDisplayIfBlank/>
          {props.groupId !== guidEmpty && !props.groupId && <DateMoment date={group.entryDate} label={'Entry date'} format={'D MMM YYYY'} className={styles.date}/>}
        </div>
        <hr/>
        <JoinLinkGroup 
          personId={personId}
          toggleGroupJoinLink={props.toggleGroupJoinLink} 
          groupId={group.groupId} 
          groupOpenJoinLink={group.groupJoinInviteCode} 
          runFunction={() => props.getGroupContactsWorks(personId, group.groupId)} />
        <hr/>
        {!(group.contacts && group.contacts.length > 0) &&
          <div className={classes(styles.topSpace, styles.noDocuments)}>
            <hr/>
            This group does not have any contacts assigned.
          </div>
        }
        <div className={styles.topSpace}>
          <div className={styles.row}>
            <div>Contacts for this group:</div>
            <div className={classes(styles.leftSpace, styles.row)}>
              <ContactsAddChoice groupId={groupId} label={`Add people to this group:`}/>
              <div className={styles.buttonWidth}>
                <ButtonWithIcon icon={'checkmark0'} onClick={() => navigate(-1)} label={'Done'} submitSuccess={false} />
              </div>
            </div>
          </div>
          {pendingGroupInvites && pendingGroupInvites.length > 0 && <hr/>}
          {pendingGroupInvites && pendingGroupInvites.length > 0 &&
            <InvitesPending invites={pendingGroupInvites}
                            personId={personId}
                            groupId={groupId}
                            deleteInvite={props.deleteInvite}
                            acceptInvite={props.acceptInvite}
                            resendInvite={props.resendInvite}
                            getInvitesPending={props.getInvitesPending}
                            sendFirstNotifications={props.sendFirstNotifications}/>
          }

          <hr/>
          {group.contacts && group.contacts.length > 0 && group.contacts.map((m, i) =>
            <div className={styles.row} key={i + 'chosen'}>
              <Checkbox
                label={``}
                checked={true}
                onClick={() => setDeleteContactMessage(m)}/>
              <ContactSummaryLine contact={m} nameLength={150} keyIndex={i + 'line'}/>
            </div>
          )}
        </div>
      </div>
			<OneFJefFooter/>
      <MessageModal show={showDeleteGroupMessage} handleClose={() => setShowDeleteGroupMessage(false)} heading={`Delete this Group?`}
                    explain={`Are you sure you want to delete this group?  All documents will be removed from the group members' lists.`}
                    isConfirmType={true}
                    onClick={handleDeleteGroup}/>
      <MessageModal show={deleteContactMessage} handleClose={() => setDeleteContactMessage('')} heading={`Delete this Contact?`}
        explain={`Are you sure you want to delete this contact?`}
        isConfirmType={true}
        onClick={handleDeleteContact} />
		</section>
	)
}

export default GroupWorksView
