import React, { useState, useEffect, useRef } from 'react';
import styles from './SentenceEdits.module.css';
import * as editorService from '../../services/editor-dom'
import * as editListStructure from "../../services/edit-list-structure";
import MessageModal from '../MessageModal';
import OriginalTextAndComment from '../OriginalTextAndComment';
import CommentAndTranslationSaveInfo from '../CommentAndTranslationSaveInfo';
import EditHistorySegment from '../EditHistorySegment';
import EditorNameDateSentence from '../EditorNameDateSentence';
import EditActionOptions from '../EditActionOptions';
import WorkNameDisplay from '../WorkNameDisplay';
import Icon from '../Icon';
import {doSort} from '../../utils/sort.js';
import backgroundColors from '../../utils/backgroundColors.js'
import { guidEmpty } from '../../utils/GuidValidate.js'
import { useMediaQuery } from 'react-responsive';
import JoinLinkGroup from '../../components/JoinLinkGroup'

let previousCurrentElement
let isFirstElement = true

function SentenceEdits(props) {
  const {
    personId,
    currentElement,
    handleSetCurrentElement,
    authorPersonId,
    tabsData = [],
    segments = [],
    edits = [],
    personConfig,
    setTabSelected,
    responseEdit,
    isTranslation,
    localTranslation,
    setLocalTranslation,
    workSummary,
    addOrUpdateEdit,
    addOrUpdateSegments,
    setShowEditorFullText,
    getNextId,
    isDraftReview,
    editSegmentHistory,
    editLanguageId,
    handleSetChosenSegment,
    isOpenSlideOut,
  } = props;

  const isMobile = useMediaQuery({ query: '(max-width: 870px)' })
  const isAuthor = authorPersonId === personId
  let isEditsInit = false
  let originalSentenceText = ''
  const segment = currentElement && segments && segments.length > 0 && segments.filter(m => Number(m.elementId) === Number(currentElement.id) && m.type === 'TEXT')[0]
  const existTranslation = currentElement && edits && edits.length > 0 && edits.filter(m => m.personId === personId && m.elementId === Number(currentElement.id) && m.type === 'TEXT')[0]
  const [isShowDeleteConfirm, setIsShowDeleteConfirm] = useState(false)
  const [isShowAcceptConfirm, setIsShowAcceptConfirm] = useState(false)
  const [isShowTranslationBlankError, setIsShowTranslationBlankError] = useState(false)
  const [isShowCommentMessage, setIsShowCommentMessage] = useState(false)
  const [isShowingLegend, setIsShowingLegend] = useState(false)
  const [deleteIndex, setDeleteIndex] = useState(0)
  const [keepHistoryOn, setKeepHistoryOn] = useState(false)
  const [showHistory, setShowHistory] = useState(false)
  const [keepAutoNextOn, setKeepAutoNextOn] = useState(false)
  const [keepEditDifferenceOn, setKeepEditDifferenceOn] = useState(false)
  const [editsSorted, setEditsSorted] = useState([])
  const [comment, setComment] = useState('')
  const [translation, setTranslation] = useState()
  const [showInstructions, setShowInstructions] = useState(false)
  const [isShowAllDeleteSentencesInSeries, setIsShowAllDeleteSentencesInSeries] = useState(false)

  const containerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!isFirstElement && !!containerRef.current && !containerRef.current.contains(event.target)) {
        props.setIsOpenSlideOut(false)
      }
      isFirstElement = false
    }
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') { // ESC key
        props.setIsOpenSlideOut(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, [props.isOpenSlideOut]);


  useEffect(() => {
    if (personConfig.historySentenceView) {
      setKeepHistoryOn(true);
    }
    if (personConfig.nextSentenceAuto) {
      setKeepAutoNextOn(true);
    }
    if (personConfig.editDifferenceView) {
      setKeepEditDifferenceOn(true);
    }
  }, [personConfig]);

  useEffect(() => {
    //if (isMobile && !isOpenSlideOut && currentElement && currentElement.nodeName === 'SPAN') return
    if (currentElement) { //!isEditsInit && edits && edits.length > 0 &&
      let currentElementId = currentElement.id
        ? currentElement.id.replace('~tabView', '')
        : currentElement.dataset && currentElement.dataset.spanId
          ? currentElement.dataset.spanId.replace('~tabView', '')
          : ''
      // let sorted = currentElement.dataset && !!currentElement.dataset.editSegmentId //A new list item can have an editSegmentId of '0' so we need to be sure to check this for no value
      //   ? edits && edits.length > 0 && edits.filter(m => Number(m.editSegmentId) === Number(currentElement.dataset.editSegmentId))
      //   : edits && edits.length > 0 && edits.filter(m => Number(m.elementId) === Number(currentElementId) && m.type === currentElement.dataset.type)
      let sorted = edits && edits.length > 0 && edits.filter(m => Number(m.elementId) === Number(currentElementId) && (m.type === currentElement.dataset.type || (!m.type && !currentElement.dataset.type)))
      if (sorted && sorted.length > 0) {
        if (sorted[0].type === 'ADDPARAGRAPHSENTENCE') {
          setEditsSorted(doSort(sorted, { sortField: 'subSequence', isAsc: true, isNumber: true}))
        } else if (sorted[0].type === 'ADDLISTITEM') {
            setEditsSorted(doSort(sorted, { sortField: 'addListItemSequence', isAsc: true, isNumber: true }))
        } else if (sorted[0].type === 'DELETESENTENCE' && isShowAllDeleteSentencesInSeries) {
          sorted = edits?.filter(m => m.type === 'DELETESENTENCE' && sorted[0].type === m.type && Number(m.deleteInSeries) === Number(sorted[0].deleteInSeries))          
          setEditsSorted(sorted)
        } else {
          setEditsSorted(doSort(sorted, { sortField: 'firstName', isAsc: true, isNumber: false}))
        }
      } else {
        setEditsSorted([])
      }
      isEditsInit = true
    }
  }, [edits, currentElement, isShowAllDeleteSentencesInSeries])

  useEffect(() => {
    //if (isMobile && !isOpenSlideOut && currentElement && currentElement.nodeName === 'SPAN') return
    if (isTranslation && currentElement) {
      setTranslation('')
      if (!existTranslation) {
        const savedCursorPosition = editorService.saveCursorLocation(document.getElementById('editorDiv'))
        props.getTranslation(personId, workSummary.workId, isTranslation.languageId, currentElement.id, props.chapterId, currentElement.innerHTML.replace('&nbsp;', ''))
        setTimeout(() => editorService.restoreCursorLocation(document.getElementById('editorDiv'), savedCursorPosition), 300)
      } else {
        setLocalTranslation('')
        setTranslation(currentElement.innerHTML)
      }
      currentElement.style.backgroundColor = backgroundColors.currentFocus
      let nextSpanTabView = document.querySelector(`span[id="${currentElement.id}~tabView"][data-type="TEXT"]`)
      if (nextSpanTabView) nextSpanTabView.style.backgroundColor = backgroundColors.currentFocus
    }
  }, [currentElement])

  useEffect(() => {
    if (personConfig.moveTranslationToEditor && !existTranslation) setTranslation(localTranslation)
  },[localTranslation])

  useEffect(() => {
    //if (isMobile && !isOpenSlideOut && currentElement && currentElement.nodeName === 'SPAN') return
    if (currentElement && (!previousCurrentElement || !(Number(previousCurrentElement.id) === Number(currentElement.id) && previousCurrentElement.dataset && currentElement.dataset && previousCurrentElement.dataset.type === currentElement.dataset.type))) {
      previousCurrentElement = currentElement
      // let hasUserEdit = currentElement && edits && edits.length > 0 && edits.filter(m => Number(m.elementId) === Number(currentElement.id) && m.personId === personId && m.type === currentElement.dataset.type)[0];
      // newEditText = hasUserEdit ? hasUserEdit.text : (isTranslation ? '' : currentElement && currentElement.innerHTML);
      // //setComment('')  //This is calling a setState in a useEffect
      let currentElementId
      if (currentElement && currentElement.dataset && currentElement.dataset.type === 'MOVE') {
        let editSegment = edits && edits.length > 0 && edits.filter(m => m.editSegmentId === Number(currentElement.dataset.editSegmentId))[0]
        if (editSegment) currentElementId = editSegment.elementId
      } else {
        currentElementId = currentElement.id
      }
      let edit = edits && edits.length > 0 && edits.filter(m => Number(m.elementId) === Number(currentElementId) && m.type === currentElement.dataset.type)[0]
      setComment(edit && edit.comment)
    }
  }, [segments, edits, currentElement])

  useEffect(() => {
    setTimeout(setTabViewSentenceHighlight, 10)
  }, [editSegmentHistory])

  const setTabViewSentenceHighlight = () => {
    if (currentElement) {
      let elementTabView = document.querySelector(`span[id="${currentElement.id + '~tabView'}"][data-type="TEXT"]`)
      if (elementTabView) elementTabView.style.backgroundColor = backgroundColors.currentFocus
    }
  }

  const retranslate = () => {
    props.getTranslation(personId, workSummary.workId, isTranslation.languageId, currentElement.id, props.chapterId, currentElement.innerHTML.replace('&nbsp;',''))
  } 

  const handleNextOrPrevButton = (direction) => {
    let nextSpan
    setComment('')
    setTranslation('')
    if (currentElement) {
      const existEdit = edits && edits.length > 0 && edits.filter(m => Number(m.elementId) === Number(currentElement.id) && m.type === 'TEXT')[0]
      currentElement.style.backgroundColor = existEdit ? backgroundColors.editPending : backgroundColors.normal
      let currentElementTabView = document.querySelector(`span[id="${currentElement.id + '~tabView'}"][data-type="TEXT"]`)
      if (currentElementTabView) currentElementTabView.style.backgroundColor = existEdit ? backgroundColors.editPending : backgroundColors.normal
    }
    if (direction === 'PREV') {
      nextSpan = editorService.useTabToPreviousSentence(currentElement)
    } else {
      nextSpan = editorService.useTabToNextSentence(currentElement)
    }
    handleSetChosenSegment(currentElement)
    handleSetCurrentElement(currentElement)
  }

  const handleAcceptTranslation = () => {
    setTranslation(localTranslation)
  }

  const sendResponseEdit = (edit, responseType, listItemElementIds, addListItemNextParentElementId) => {
    if (currentElement.dataset.type === 'MOVE') {
      props.handleSetChosenMoveEdit(edit.editSegmentId)
    } else if (currentElement.dataset.type === 'ADDPARAGRAPH') {
      props.handleSetChosenAddParagraphEdit(currentElement.dataset.spanId)
    } else if (currentElement.dataset.type === 'DELETEPARAGRAPH') {
      props.handleSetChosenDeleteParagraphEdit(currentElement.dataset.spanId)
    }
    if (responseType === 'RejectEdit' || responseType === 'DeleteEdit') {
      if (edit && currentElement.dataset.type === 'MOVE') editorService.removeMoveIcons(edit) //I added currentElement.dataset.type === 'MOVE' since just removing Move Icons for any type of edit didn't make sense unless there is some problem with orphan move icons around.
    }   
    const runFunction = () => {
      setTimeout(() => props.getWorkSegments(personId, edit.workId, edit.chapterId, edit.languageId), 500)
      setTimeout(() => props.getEditSegments(personId, edit.workId, edit.chapterId, edit.editLanguageId), 500)
    }
    responseEdit(edit, responseType, "", listItemElementIds, runFunction, addListItemNextParentElementId)
  }

  const getEditHistoryCount = () => {
    let segment = currentElement && segments && segments.length > 0 && segments.filter(m => Number(m.elementId) === Number(currentElement.id))[0]
    return segment && segment.editHistoryCount ? segment.editHistoryCount : 0
  }

  const handleAcceptEdit = (editSegment) => {
    if (editSegment.type === 'DELETEPARAGRAPH') {
      let nextElement = currentElement.nextElementSibling ? currentElement.nextElementSibling : currentElement.previousElementSibling ? currentElement.previousElementSibling : currentElement.parentElement
      handleSetCurrentElement(nextElement)
      props.handleSetChosenDeleteParagraphEdit(editSegment.elementId)
      editorService.authorAcceptDeleteParagraph(currentElement)
      props.saveEditorDivSegmentsPersistent()
      sendResponseEdit(editSegment, 'AcceptEdit')

    } else if (editSegment.type === 'ADDPARAGRAPH') {
      handleSetCurrentElement(currentElement)
      props.handleSetChosenAddParagraphEdit(editSegment.elementId)
      editorService.authorAcceptAddParagraph(currentElement, props.getNextId)
      props.saveEditorDivSegmentsPersistent()
      sendResponseEdit(editSegment, 'AcceptEdit')

    } else if (editSegment.type === 'ADDPARAGRAPHSENTENCE') {
      //The new segments are created directly into the database in this case. So we aren't going to try to save them to the interface and then do a persistent DB update. That is too complex and potentially dirty.
      // props.handleSetChosenAddParagraphSentenceEdit(editSegment.elementId)
      // let newSpan = editorService.authorAcceptAddParagraphSentence(currentElement, props.getNextId, editSegment)
      // handleSetCurrentElement(newSpan)
      // props.saveEditorDivSegmentsPersistent()
      sendResponseEdit(editSegment, 'AcceptEdit')

    } else if (editSegment.type === 'ADDSENTENCE') {
      props.handleSetChosenAddSentenceEdit(editSegment.elementId)
      let newSpan = editorService.authorAcceptAddSentence(currentElement, props.getNextId, editSegment)
      handleSetCurrentElement(newSpan)
      props.saveEditorDivSegmentsPersistent()
      sendResponseEdit(editSegment, 'AcceptEdit')

    } else if (editSegment.type === 'DELETESENTENCE') {
      let nextElement = currentElement.nextElementSibling ? currentElement.nextElementSibling : currentElement.previousElementSibling ? currentElement.previousElementSibling : currentElement.parentElement
      handleSetCurrentElement(nextElement)
      props.handleSetChosenDeleteSentenceEdit(editSegment.elementId)
      editorService.authorAcceptDeleteSentence(currentElement, props.getNextId)
      props.saveEditorDivSegmentsPersistent()
      sendResponseEdit(editSegment, 'AcceptEdit')

    } else if (editSegment.type === 'ADDLISTITEM') {
      //The new segments are created directly into the database in this case. So we aren't going to try to save them to the interface and then do a persistent DB update. That is too complex and potentially dirty.
      // props.handleSetChosenAddListItemEdit(editSegment.elementId)
      // let newSpan = editorService.authorAcceptAddListItem(currentElement, editSegment.text, props.getNextId)
      // handleSetCurrentElement(newSpan)
      // props.saveEditorDivSegmentsPersistent()
      const anchorElement = document.querySelector(`span[id="${editSegment.elementId}"][data-type="TEXT"]`)
      const addListItemNextParentElementId = editorService.getAddListItemNextParentElement(anchorElement)
      sendResponseEdit(editSegment, 'AcceptEdit', null, addListItemNextParentElementId)

    } else if (editSegment.type === 'DELETELISTITEM') {
      let nextElement = currentElement.nextElementSibling ? currentElement.nextElementSibling : currentElement.previousElementSibling ? currentElement.previousElementSibling : currentElement.parentElement
      handleSetCurrentElement(nextElement.firstChild)
      props.handleSetChosenDeleteListItemEdit(editSegment.elementId)
      const listItemElementIds = editorService.authorAcceptDeleteListItem(currentElement, getNextId)
      props.saveEditorDivSegmentsPersistent()
      sendResponseEdit(editSegment, 'AcceptEdit', listItemElementIds)

    } else if (editSegment.type === 'REORDERLISTITEMS') {
      props.handleSetChosenReorderListItemsEdit(editSegment.elementId)
      editorService.authorAcceptReorderListItems(editSegment, segments, props.chapterId, getNextId, personId, addOrUpdateSegments)
      props.saveEditorDivSegmentsPersistent()
      sendResponseEdit(editSegment, 'AcceptEdit')

    } else if (editSegment.type === 'MOVE') {
      let focusElement = document.getElementById(editSegment.startElementId)
      handleSetCurrentElement(focusElement)
      props.handleSetChosenMoveEdit(editSegment.editSegmentId)
      sendResponseEdit(editSegment, 'AcceptEdit')
      let newSegments = editorService.moveSentencesInSegments([...segments], editSegment)
      //I took tabsData out of the sixth parameter below and put in personId directly. Is this supposed to be the chosenTab of tabsData?s
      editorService.setSegments('editorDiv', newSegments, props.workSummary, props.edits, isAuthor, personId, props.editorName, tabsData, getNextId, props.chapterListLevels, props.addChapterListLevels, props.listLevelGeneral, props.chosenTab)
      props.saveEditorDivSegmentsPersistent()

    } else if (editSegment.type === 'ADDTAB') {
      //Adding a ADDTAB, sets the text-indent property to 36pt. Additional ADDTAB edits increment the margin-left which leads to a block indent. This is WORD convention.
      //There may be more than one ADDTAB for a given paragraph. Other ADDTAB edits should not be automatically deleted as "other" edits but "additional" and "incremental" edits.
      let paragraphElement = document.querySelector(`p[id="${editSegment.elementId}"]`)
      handleSetCurrentElement(paragraphElement)
      editorService.insertAuthorsAcceptedTab(paragraphElement)
      sendResponseEdit(editSegment, 'AcceptEdit')
      props.saveEditorDivSegmentsPersistent()

    } else if (editSegment.type === 'DELETETAB') {
      let spanElement = document.querySelectorAll(`[id="${editSegment.elementId}"][data-type="TEXT"]`)[0]
      handleSetCurrentElement(spanElement)
      //spanElement.remove()
      sendResponseEdit(editSegment, 'AcceptEdit')
      props.saveEditorDivSegmentsPersistent()

    } else if (editSegment.type === 'LISTLEVELMINUS' || editSegment.type === 'LISTLEVELPLUS') {
      let element = document.querySelectorAll(`[id="${editSegment.elementId}"][data-type="TEXT"]`)[0]
      handleSetCurrentElement(element)
      let elementListItem = element.parentElement
      editListStructure.setListLevel({
        currentElement: element,
        elementListItem,
        direction: editSegment.type === 'LISTLEVELMINUS' ? 'MINUS' : 'PLUS',
        chapterId: workSummary ? props.chapterId && props.chapterId !== guidEmpty ? props.chapterId : workSummary.chapterOptions && workSummary.chapterOptions[0].chapterId : guidEmpty,
        getNextId: props.getNextId,
        edits,
        chapterListLevels: props.chapterListLevels,
        listLevelGeneral: props.listLevelGeneral,
        addChapterListLevels: props.addChapterListLevels,
      })
      sendResponseEdit(editSegment, 'AcceptEdit')
      props.saveEditorDivSegmentsPersistent()


    } else {
      if (editSegment.type === 'DELETESENTENCE') {
        let nextElement = currentElement.nextElementSibling ? currentElement.nextElementSibling : currentElement.previousElementSibling ? currentElement.previousElementSibling : currentElement.parentElement
        handleSetCurrentElement(nextElement.firstChild)
        currentElement.remove()
      } else if (!!editSegment.text) { //This could be accepting a comment-only edit - so let's not blank out the text
        handleSetCurrentElement(currentElement)
        currentElement.innerHTML = editSegment.text
      }
      if (isAuthor) {
        currentElement.style.backgroundColor = 'white'
      }

      if (isAuthor || props.chosenTab === editSegment.personId) { //Set the editor full text view, too, if it is the author or the tabview is the same as the editor
        let editorElement = document.querySelectorAll(`[id="${editSegment.elementId + '~tabView'}"][data-type="TEXT"]`)[0]
        if (editorElement) {
          if (editSegment.type === 'DELETESENTENCE') {
            editorElement.remove()
          } else if (!!editSegment.text) { //This could be accepting a comment-only edit - so let's not blank out the text
            editorElement.innerHTML = editSegment.text
          }
        }
      }

      if (isAuthor) {
        sendResponseEdit(editSegment, 'AcceptEdit')
        props.saveEditorDivSegmentsPersistent()
      } else {
        addOrUpdateEdit({
          personId: personId,
          firstName: props.editorName && props.editorName.firstName,
          lastName: props.editorName && props.editorName.lastName,
          chapterId: workSummary ? props.chapterId && props.chapterId !== guidEmpty ? props.chapterId : workSummary.chapterOptions && workSummary.chapterOptions[0].chapterId : guidEmpty,
          languageId: isTranslation ? isTranslation.languageId : workSummary.languageId_current,
          elementId: currentElement.id,
          editSegmentTypeId: 0, //This will be filled in on the server side by the type entered below
          type: editSegment.type,
          text: editSegment.text,
          authorTextSnapshot: editSegment.authorTextSnapshot,
          comment: editSegment.comment,
        })
      }
    }
    sendResponseEdit(editSegment, 'VoteUp') //There is a VoteUp for the author as well in addition to the AcceptEdit
    props.setContextMenu({show: false})
  }

  const handleSaveTranslation = () => {
    const {personId, isTranslation} = props;
    if (isTranslation && !translation) {
      handleBlankOpen();
    } else {
      if (isAuthor) {
        if (segment) {
          let segment = segments && segments.length > 0 && segments.filter(m => m.elementId === Number(currentElement.id) && m.type === currentElement.dataset.type)[0]
          let edit = edits && edits.length > 0 && edits.filter(m => Number(m.elementId) === Number(currentElement.id) && m.type === currentElement.dataset.type)[0]
          if (segment) {
            props.addOrUpdateCommentOrSentence(personId, comment, workSummary.workId, segment.chapterId, segment.elementId, editLanguageId, isAuthor, (edit && edit.editSegmentId) || 0, 'TEXT', translation)
          }
          const addSpace = translation && translation.lastIndexOf('&nbsp;') === translation.length - 6 ? '' : '&nbsp;'
          currentElement.innerHTML = translation + addSpace
        }
      } else {
        let existEdit = edits && edits.length > 0 && edits.filter(m => m.elementId === Number(currentElement.id) && m.personId === props.personId && m.type === currentElement.dataset.type)[0]
        addOrUpdateEdit({
          isAuthor,
          personId: personId,
          chapterId: workSummary ? props.chapterId && props.chapterId !== guidEmpty ? props.chapterId : workSummary.chapterOptions && workSummary.chapterOptions[0].chapterId : guidEmpty,
          elementId: currentElement.id,
          languageId: isTranslation ? isTranslation.languageId : workSummary.languageId_current,
          editLanguageId: isTranslation ? isTranslation.languageId : workSummary.languageId_current,
          editSegmentTypeId: 0, //This will be filled in on the server side by the type entered below
          firstName: props.editorName && props.editorName.firstName,
          lastName: props.editorName && props.editorName.lastName,
          type: 'TEXT',
          text: translation,
          authorTextSnapshot: segment.text,
          comment: comment ? comment : existEdit && existEdit.comment ? comment : '',
        })
        const addSpace = translation && translation.lastIndexOf('&nbsp;') === translation.length - 6 ? '' : '&nbsp;'
        currentElement.innerHTML = translation + addSpace
      }

      if (isTranslation && personConfig.goToNextSentence) {
        let nextSpan = editorService.useTabToNextSentence(currentElement)
        if (nextSpan) {
          handleSetCurrentElement(nextSpan)
          nextSpan.style.backgroundColor = backgroundColors.currentFocus
          let nextSpanTabView = document.querySelector(`span[id="${nextSpan.id}~tabView"][data-type='TEXT']`)
          if (nextSpanTabView) nextSpanTabView.style.backgroundColor = backgroundColors.currentFocus
        }
      }
      setTranslation('')
      setIsShowCommentMessage(false)
    }
    props.setContextMenu({show: false})
  }

  const handleAllDeletes = () => {
    setIsShowDeleteConfirm(false)
    if (currentElement.dataset.type === 'ADDPARAGRAPHSENTENCE' && editsSorted && editsSorted.length > 0) {
      let firstEdit = editsSorted[0]
      const runFunction = () => {
        setTimeout(() => props.getEditSegments(personId, firstEdit.workId, firstEdit.chapterId, firstEdit.editLanguageId), 500)
      }
      props.deleteAllAddParagraphSentence(editsSorted, runFunction)

    } else if (currentElement.dataset.type === 'DELETESENTENCE' && editsSorted && editsSorted.length > 0) {
      editsSorted?.forEach(m => {
        responseEdit(m, isAuthor ? 'RejectEdit' : 'DeleteEdit')
      });
      setTimeout(() => props.getEditSegments(personId, editsSorted[0].workId, editsSorted[0].chapterId, editsSorted[0].editLanguageId), 500)
      setIsShowAllDeleteSentencesInSeries(false)
    } else {
      editsSorted?.forEach(m => {
        sendResponseEdit(m, isAuthor ? 'RejectEdit' : 'DeleteEdit')
      });
    }
  }

  const handleAllAccept = () => {
    setIsShowAcceptConfirm(false)
    if (editsSorted && editsSorted.length > 0) {
      let firstEdit = editsSorted[0]
      const runFunction = () => {
        setTimeout(() => props.getWorkSegments(personId, firstEdit.workId, firstEdit.chapterId, firstEdit.languageId), 500)
        setTimeout(() => props.getEditSegments(personId, firstEdit.workId, firstEdit.chapterId, firstEdit.editLanguageId), 500)
      }
      if (editsSorted[0].type === 'ADDPARAGRAPHSENTENCE') {
        props.acceptAllAddParagraphSentence(editsSorted, runFunction)
      } else if (editsSorted[0].type === 'ADDLISTITEM') {
        const anchorElement = document.querySelector(`span[id="${editsSorted[0].elementId}"][data-type="TEXT"]`)
        const addListItemNextParentElementId = editorService.getAddListItemNextParentElement(anchorElement)
        props.acceptAllAddListItem(editsSorted, addListItemNextParentElementId, runFunction)
      } else if (editsSorted[0].type === 'DELETESENTENCE') {
        editsSorted?.forEach(m => {
          responseEdit(m, 'AcceptEdit')
        });
        runFunction()
        setIsShowAllDeleteSentencesInSeries(false)
      }
    }
  }

  const handleFullTextView = (tabPersonId) => {
    setShowEditorFullText(true)
    setTabSelected(null, tabPersonId)
  }

  const handleBlankClose = () => {
    setIsShowTranslationBlankError(false);
  }
  const handleBlankOpen = () => {
    setIsShowTranslationBlankError(true);
  }
  const handleCommentSave = () => {
    let existEdit = edits && edits.length > 0 && edits.filter(m => m.elementId === Number(currentElement.id) && m.personId === props.personId && m.type === currentElement.dataset.type)[0]
    if (!existEdit && !comment) return //Don't send a new edit when the comment is blank.
    if (isAuthor) {
      props.addOrUpdateCommentOrSentence(personId, comment, workSummary.workId, props.chapterId, Number(currentElement.id), editLanguageId, isAuthor, (existEdit && existEdit.editSegmentId) || 0, currentElement.dataset.type);
    } else {
      let existSegment = segments && segments.length > 0 && segments.filter(m => m.elementId === Number(currentElement.id))[0]

      //We still need to send the edit although the text doesn't look like it changed because the edit exists already so it needs to be deleted from EditSegment db table because the editor might have changed the edit back.
      addOrUpdateEdit({
        editSegmentId: (existEdit && existEdit.editSegmentId) || 0,
        personId: props.personId,
        chapterId: workSummary ? props.chapterId && props.chapterId !== guidEmpty ? props.chapterId : workSummary.chapterOptions && workSummary.chapterOptions[0].chapterId : guidEmpty,
        elementId: Number(currentElement.id),
        languageId: isTranslation ? isTranslation.languageId : workSummary.languageId_current,
        editSegmentTypeId: 0, //This will be filled in on the server side by the type entered below
        firstName: props.editorName && props.editorName.firstName,
        lastName: props.editorName && props.editorName.lastName,
        type: currentElement.dataset.type,
        text: editorService.isTextEqual(currentElement.innerHTML, existSegment.text) ? '' : currentElement.innerHTML, //This could be a comment only
        authorTextSnapshot: segment.text,
        comment,
      })

    }
    currentElement.style.backgroundColor = backgroundColors.editPending
    setIsShowCommentMessage(false)
    setComment('')
  }

  const handleCommentOpen = () => {
    setIsShowCommentMessage(!isShowCommentMessage)
    if (isAuthor) {
      let segment = segments.filter(m => Number(m.elementId) === Number(currentElement.id))[0]
      if (segment) {
        setComment(segment.comment || '')
      }
    } else {
      let hasUserEdit = currentElement && edits && edits.length > 0 && edits.filter(m => Number(m.elementId) === Number(currentElement.id) && m.personId === personId && m.type === currentElement.dataset.type)[0]
      if (hasUserEdit) setComment(hasUserEdit.comment || '')
    }
  }

  const handleEditIconLegendClose = () => {
    setIsShowingLegend(false);
  }

  const handleEditIconLegendOpen = () => {
    setIsShowingLegend(true)
  }

  const getEditsCount = () => {
    let editCount = currentElement && edits && edits.length > 0 && edits.filter(m => Number(m.elementId) === Number(currentElement.id))
    return editCount ? editCount.length : 0
  }

  const isEditorOwner = () => {
    if (editsSorted?.length > 0) {
      return editsSorted[0].personId === personId
    }
  }

  const getEditorColor = (personId, withoutSymbol) => {
    let editorColor = (tabsData.filter(m => m.id === personId)[0] && tabsData.filter(m => m.id === personId)[0].editorColor) || '#ff6600'
    if (editorColor && withoutSymbol) editorColor = editorColor.replace('#', '')
    return editorColor
  }

  const getVoteUpCount = (edit) => {
    return edit?.editVotes?.filter(m => m.voteUpFlag) && edit?.editVotes?.filter(m => m.voteUpFlag).length > 0 ? edit?.editVotes?.filter(m => m.voteUpFlag).length : ''
  }

  const getVoteDownCount = (edit) => {
    return edit?.editVotes?.filter(m => m.voteDownFlag) && edit?.editVotes?.filter(m => m.voteDownFlag).length > 0 ? edit?.editVotes?.filter(m => m.voteDownFlag).length : ''
  }

  const getVoteTrollCount = (edit) => {
    return edit?.editVotes?.filter(m => m.voteTrollFlag) && edit?.editVotes?.filter(m => m.voteTrollFlag).length > 0 ? edit?.editVotes?.filter(m => m.voteTrollFlag).length : ''
  }

  const handleDeleteEdit = (editSegmentId) => {
    let segment = segments.filter(m => Number(m.elementId) === Number(currentElement.id) && m.type === currentElement.dataset.type)[0]
    let editSegment = edits?.filter(m => m.editSegmentId === editSegmentId)[0]
    if (currentElement.dataset.type === 'DELETEPARAGRAPH') {  //ToDo what about the full text editor view:
      props.handleSetChosenDeleteParagraphEdit(currentElement.dataset.spanId)
      currentElement.remove()
    } else if (currentElement.dataset.type === 'ADDPARAGRAPH') {  //ToDo what about the full text editor view:
      props.handleSetChosenAddParagraphEdit(currentElement.dataset.spanId)
      currentElement.remove()
    } else if (currentElement.dataset.type === 'MOVE') {
      props.handleSetChosenMoveEdit(editSegmentId)
      currentElement.remove()
    } else if (currentElement.dataset.type === 'ADDTAB'
      || currentElement.dataset.type === 'DELETETAB'
      || currentElement.dataset.type === 'LISTLEVELMINUS'
      || currentElement.dataset.type === 'LISTLEVELPLUS') {
      currentElement.remove()
    } else {
      if (segment) {
        currentElement.innerHTML = segment.text
        let otherEdits = edits && edits.length > 0 && edits.filter(m => m.elementId === currentElement.id && m.personId !== personId)
        if (!otherEdits || otherEdits.length === 0) {
          currentElement.style.backgroundColor = 'white'
        }
      }
    }
    sendResponseEdit(editSegment, 'DeleteEdit')
    props.setContextMenu({show: false})
    props.setIsInitEdits('FORCE')
  }

  const handleSetShowHistory = () => {
    if(currentElement && !showHistory) props.getEditSegmentHistory(personId, props.chapterId, currentElement.id, editLanguageId)
      setShowHistory(!showHistory)
  }

  const cleanDifferentEditText = (differenceEditText) => {
    let regex = "/<(.|\n)*?>/";
    differenceEditText = differenceEditText && differenceEditText.replace(regex, "")
      .replace(/<br>/g, "")
      //.replace(/<[^>]*>/g, ' ')
      .replace(/\s{2,}/g, ' ')
      .replace(/&nbsp;/g, ' ')
      .trim()

    return differenceEditText
  }

  const countDeleteSentencesInSeries = (edit) => {
    if (currentElement && edit && edit.deleteInSeries && edit.deleteInSeries !== "0") {
      const editsDeleteSentences = edits?.filter(m => m.type === 'DELETESENTENCE' && edit.type === m.type && Number(m.deleteInSeries) === Number(edit.deleteInSeries))
      return editsDeleteSentences?.length
    }
  }

  if (currentElement && currentElement.id) {
    const elementId = currentElement.id.indexOf('tabView') ? currentElement.id.replace('~tabView', '') : currentElement.id
    const segment = segments && segments.length > 0 && segments.filter(m => m.elementId === Number(elementId) && m.type === 'TEXT')[0]
    const text = segment ? segment.text : ''
    if (text) originalSentenceText = editorService.cleanText(text)
  }

  return (
    <div className={!isMobile ? styles.sidePanel : isOpenSlideOut ? styles.slideOutOpen : styles.slideOut} ref={containerRef}>
      {!props.isMobile &&
        <WorkNameDisplay 
          workSummary={workSummary} 
          showLanguageOptions 
          editLanguageId={editLanguageId} 
          personId={personId} 
          isAuthor={isAuthor} 
          chapterId={props.chapterId}
          isTranslation={isTranslation}/>
      }
      {workSummary.originatingEditorPersonId === personId &&
        <JoinLinkGroup 
          personId={personId}
          toggleGroupJoinLink={props.toggleGroupJoinLink} 
          groupId={workSummary.groupId} 
          groupOpenJoinLink={workSummary.groupOpenJoinLink} 
          runFunction={() => props.getWorkEditReview(personId, workSummary.workId)} />
      }
      <div className={styles.topText}>
        {/* {!isDraftReview && !isMobile &&
          <div className={styles.row} onClick={() => setShowInstructions(true)}>
            <Icon pathName={'document0'} premium={true} fillColor={'white'}/>
            <div className={styles.instructions}>instructions</div>
          </div>
        } */}
        <div className={styles.rowCenter}>
          <div>{`edits: ${getEditsCount()}`}</div>
          <span className={styles.editsHistory}
                onClick={() => getEditHistoryCount() ? handleSetShowHistory(!showHistory) : {}}>
            {`history: ${getEditHistoryCount()}  ${showHistory ? '(on)' : '(off)'}`}
          </span>
        </div>
      </div>
      {props.isMobile &&
        <button className={styles.slideOutToggle} onClick={() => props.setIsOpenSlideOut(false)}>
          Close X
        </button>
      }
      <div className={styles.children}>
        {!isDraftReview &&
          <CommentAndTranslationSaveInfo 
            currentElement={currentElement}
            handleCommentOpen={handleCommentOpen}
            isTranslation={isTranslation}
            saveTranslation={handleSaveTranslation}
            translation={translation}
            setTranslation={setTranslation}
            personConfig={personConfig}
            handleNextOrPrevButton={handleNextOrPrevButton}
            handleEditIconLegendOpen={handleEditIconLegendOpen}
            isShowCommentMessage={isShowCommentMessage}
            setIsShowCommentMessage={setIsShowCommentMessage}
            comment={comment}
            setComment={setComment}
            keepCommentOn={props.personConfig.keepCommentOn}
            segment={segments.filter(m => Number(m.elementId) === Number(currentElement && currentElement.id))[0]}
            handleCommentSave={handleCommentSave}/>
        }
        <OriginalTextAndComment 
          workSummary={workSummary}
          originalSentenceText={originalSentenceText}
          authorComment={segment && segment.comment}
          flexOriginalTextHeight={props.flexOriginalTextHeight}/>

        {(isAuthor || (!isAuthor && isEditorOwner() && currentElement && (currentElement.dataset.type === 'ADDPARAGRAPHSENTENCE' || currentElement.dataset.type === 'ADDLISTITEM'))) && getEditsCount() > 1 && !isDraftReview &&
          <div className={styles.row}>
            <a className={styles.deleteAllButton} onClick={() => setIsShowDeleteConfirm(true)}>Delete all</a> 
            {isAuthor && (currentElement.dataset.type === 'ADDPARAGRAPHSENTENCE' || currentElement.dataset.type === 'ADDLISTITEM') &&
              <div className={styles.row}>
                <div className={styles.divider}>|</div>
                <a className={styles.acceptAllButton} onClick={() => setIsShowAcceptConfirm(true)}>Accept all</a> 
              </div>
            }
          </div>
        }
        {countDeleteSentencesInSeries(editsSorted && editsSorted[0]) && !isShowAllDeleteSentencesInSeries && 
          <div className={styles.row}>
            <div className={styles.acceptAllButton}>There are {countDeleteSentencesInSeries(editsSorted && editsSorted[0])} delete sentences in series:</div>
            <div className={styles.linkAction} onClick={() => setIsShowAllDeleteSentencesInSeries(!isShowAllDeleteSentencesInSeries)}>
              {isShowAllDeleteSentencesInSeries ? 'Hide others' : 'Show all'}
            </div>
          </div> 
        }
        {countDeleteSentencesInSeries(editsSorted && editsSorted[0]) && isShowAllDeleteSentencesInSeries &&
          <div className={styles.row}>
            <a className={styles.deleteAllButton} onClick={() => setIsShowDeleteConfirm(true)}>
              {isAuthor ? 'Reject all' : 'Undo all'}
            </a>
            {isAuthor && currentElement.dataset.type === 'DELETESENTENCE' &&
              <div className={styles.row}>
                <div className={styles.divider}>|</div>
                <a className={styles.acceptAllButton} onClick={() => setIsShowAcceptConfirm(true)}>
                  Accept all
                </a>
              </div>
            }
          </div>
        }
        {isTranslation && !isDraftReview && currentElement && 
          <div className={styles.editDisplay}>
            {localTranslation && <span className={styles.translateTitle}>Machine translation (not perfect)</span>}
            <span className={styles.editText} dangerouslySetInnerHTML={{__html: localTranslation}}/>
            <div className={styles.row}>
              {localTranslation &&
                <a onClick={handleAcceptTranslation} title={'Accept translation'}>
                  <Icon pathName={`thumbs_up0`} premium={true} className={styles.choiceIcons}/>
                </a>
              }
              <div className={styles.retranslate} onClick={retranslate}>retranslate</div>
            </div>
            <hr style={{width: '280px'}}/>
          </div>
        }

        <div className={styles.editDetails}>
          {currentElement && editsSorted.map((m, i) => {
              let differenceEditText = cleanDifferentEditText(m.text);
              let editorColor = getEditorColor(m.personId)

              return (
                <div className={styles.editDisplay} key={i}>
                  <EditorNameDateSentence 
                    edit={m}
                    kindexey={`sentence${i}`}
                    edits={edits}
                    segment={segment}
                    segments={segments}
                    editorColor={editorColor}
                    personConfig={personConfig}
                    originalSentenceText={originalSentenceText}
                    differenceEditText={differenceEditText}
                    handleSetChosenMoveEdit={props.handleSetChosenMoveEdit}
                    personId={m.personId}
                    tabsData={tabsData}
                    currentElement={currentElement}
                    isTranslation={isTranslation} />

                  <EditActionOptions 
                    edit={m}
                    index={`action${i}`}
                    personId={personId}
                    handleAcceptEdit={handleAcceptEdit}
                    isAuthor={isAuthor}
                    sendResponseEdit={sendResponseEdit}
                    getVoteUpCount={getVoteUpCount}
                    getVoteDownCount={getVoteDownCount}
                    getVoteTrollCount={getVoteTrollCount}
                    handleDeleteEdit={handleDeleteEdit}
                    handleFullTextView={handleFullTextView}
                    deleteIndex={deleteIndex}
                    isDraftReview={isDraftReview}
                    isTranslation={isTranslation}
                    setTranslation={setTranslation}/>
                </div>
              )
            }
          )}
        </div>
        <EditHistorySegment 
          visible={showHistory}
          personId={personId}
          history={editSegmentHistory}
          isAuthor={isAuthor}
          cleanDifferentEditText={cleanDifferentEditText}
          getEditorColor={getEditorColor}
          originalSentenceText={originalSentenceText}
          editsSorted={editsSorted}
          sendResponseEdit={sendResponseEdit}
          editLanguageId={editLanguageId}
          {...props} />
      </div>
      <MessageModal 
        show={isShowingLegend} 
        key={'showLegend'} 
        handleClose={handleEditIconLegendClose} 
        heading={`Edit Icon Options`} 
        showEditIconLegend={true}
        onClick={handleEditIconLegendClose} 
        isAuthor={isAuthor}/>
      <MessageModal 
        show={isShowDeleteConfirm} 
        key={'deleteAll'} 
        handleClose={() => setIsShowDeleteConfirm(false)} 
        heading={`Delete All Edits and Comments?`}
        explain={`Are you sure you want to delete all of the edits and comments as shown in the list?`}
        isConfirmType={true}
        onClick={handleAllDeletes}/>
      <MessageModal 
        show={isShowAcceptConfirm} 
        key={'acceptAll'} 
        handleClose={() => setIsShowAcceptConfirm(false)} 
        heading={`Accept All Edits and Comments?`}
        explain={`Are you sure you want to accept all of the edits and comments as shown in the list?`}
        isConfirmType={true}
        onClick={handleAllAccept} />
      <MessageModal 
        show={isShowTranslationBlankError} 
        key={'empty'} 
        handleClose={handleBlankClose} 
        heading={`Translation is blank or empty?`}
        explain={`It is not allowed to save a blank translation of a sentence.  Please check your entry and try again"`}
        isConfirmType={false}
        onClick={handleBlankClose}/>
      {/* <MessageModal 
        key={'showInstructions'}
        show={showInstructions} 
        handleClose={() => setShowInstructions(false)} 
        heading={`Editor Instructions`}
        explain={props.instructionText}
        onClick={() => setShowInstructions(false)}/> */}
    </div>
  )
}

export default SentenceEdits
