import React, {useEffect} from 'react'
import styles from './WorkFileTreeFolderDropDown.module.css'
import classes from 'join-classnames'

function WorkFileTreeFolderDropDown(props) {
  const { workFolders, label, setWorkFolderId, workFolderId='', labelClass } = props
  const tabSpace = "\xA0\xA0\xA0" //The &nbsp; doesn't work since they get displayed for some reason.

  useEffect(() => {
    if (workFolders && workFolders.length > 0) {
      getFolders(workFolders, 0)
    }
  }, [workFolders])

  const getFolders = (workFolders, indentValue) => {
    if (workFolders && workFolders.length > 0) {
      for(let i = 0; i < workFolders.length; i++) {
        let exists = document.getElementById(workFolders[i].workFolderId)
        if (!exists) {
          let option = document.createElement('option')
          option.key = workFolders[i].workFolderId
          option.id = workFolders[i].workFolderId
          option.value = workFolders[i].workFolderId
          option.selected = workFolders[i].workFolderId === workFolderId
          option.label = getIndents(indentValue) + workFolders[i].folderName

          const workFolderList = document.getElementById('workFolderList')
          if (workFolderList) workFolderList.append(option)
        }
        if (workFolders[i].subFolders && workFolders[i].subFolders.length > 0) getFolders(workFolders[i].subFolders, ++indentValue)
      }
    }
  }

  const getIndents = (indentValue) => {
    let indents = ''
    for(let i = 0; i < indentValue; i++) {
      indents += tabSpace
    }
    return indents
  }

  return (
    <div className={styles.container}>
      {label && <span className={classes(styles.labelText, labelClass)}>{label}</span>}
      <div>
        <select id={'workFolderList'} onChange={(event) => setWorkFolderId(event.target.value)} className={styles.editControl}></select>
      </div>
    </div>
  )
}

export default WorkFileTreeFolderDropDown