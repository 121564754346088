import * as types from '../actions/actionTypes';
import {doSort} from '../utils/sort.js';

export default function (state = [], action) {
  switch (action.type) {
    case types.WORK_SEGMENTS_INIT:
      return action.payload;

    case types.WORK_COMMENT_UPDATE: {
      const {editType, comment, elementId, newSentence} = action.payload
      let newState = Object.assign([], state, [])
      let workSegment = newState && newState.length > 0 && newState.filter(m => Number(m.elementId) === Number(elementId) && m.type === editType)[0]
      newState = newState && newState.length > 0 && newState.filter(m => !(Number(m.elementId) === Number(elementId) && m.type === editType))
      let text = newSentence ? newSentence : workSegment.text
      workSegment = {...workSegment, comment, text}
      newState = newState && newState.length > 0 ? newState.concat(workSegment) : [workSegment]
      newState = doSort(newState, {sortField: 'sequence', isAsc: true, isNumber: true})
      return newState
    }

    default:
      return state;
  }
}

export const selectWorkSegments = (state) => state;
