import React from 'react';
import styles from './DraftReviewControls.module.css';
import HeadlessSelectList from '../../components/HeadlessSelectList'
import DocScrollMatch from "../../assets/Edit Icons/doc-scroll-match.png";
import Download from "../../assets/Edit Icons/download.png";

function DraftReviewControls(props) {
	const {scrollDocumentToMatch, handleDownloadChoice, divDisplayId} = props

	return (
		<div className={styles.iconRow}>
         <HeadlessSelectList 
            iconAvatar={<img src={Download} className={styles.logoHigher} alt={'edit'} title={'Download'}/>}
            onSelect={handleDownloadChoice}
            divDisplayId={divDisplayId}
            listOptions={props.downloadOptions}/>
         <img src={DocScrollMatch} className={styles.docScrollMatch} alt={'edit'} title={'Scroll documents to match location'} onClick={() => scrollDocumentToMatch()}/>
		</div>
	)
}

export default DraftReviewControls
