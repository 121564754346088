import React from 'react';
import styles from './WorkSummariesReport.module.css';
import WorkSummaryLine from '../WorkSummaryLine'

function WorkSummariesReport(props) {
	const {
    personId,
		data,
    group,
		isVisible,
		//handlePenspringHomeworkOpen = () => {},
		setChosenWork,
		chosenWork = {},
    showDocumentDetails,
    mineOrOthers,
    moveWorkToFolder,
    isMoveWorkId,
    deleteWorkFromFolder,
    hasParentFolderGroup,
    thisWorkFolderId,
    isMobile,
		//setGradebookScoreByPenspring = () => {}
	} = props

  const handleSetChosenWork = (work) => {
    if (chosenWork && work.workId === chosenWork.workId) {
      setChosenWork(null)
      if (props.setWork) {
        props.setWork(null)
      }
    } else {
      setChosenWork(work)
    }
  }

	return (
    <div style={{ marginLeft: `${props.indent}px`, position: 'relative', zIndex: '99', userSelect: 'none' }}>
			<div className={data && data.length > 0 && isVisible ? styles.document : styles.hidden}>
				{data && data.length > 0 && data.map((m, i) =>
          <div key={i}>
						<WorkSummaryLine work={m}
              isWorkAddView={props.isWorkAddView} //This is for the Mine subfolder display on WorkAddView to choose a file (which will override showEditLink, by the way)
              showEditLink //Because this is on the Myworks and if it is on mobile, then show the pencil at the beginning for a short cut.
              onClick={() => handleSetChosenWork(m)}
              group={group}
              personId={personId}
              nameLength={150}
              darkerBackground={chosenWork && chosenWork.workId === m.workId}
              darkerLabel
              includeDates
              chosenWork={chosenWork}
              thisWorkFolderId={thisWorkFolderId}
              showExplorerOptions
              noFocusColor={!(chosenWork && chosenWork.workId === m.workId)}
              showDocumentDetails={showDocumentDetails}
              moveWorkToFolder={moveWorkToFolder}
              isMoveWorkId={isMoveWorkId}
              deleteWorkFromFolder={deleteWorkFromFolder}
              mineOrOthers={mineOrOthers}
              hasParentFolderGroup={hasParentFolderGroup}
              {...props} />
					</div>
				)}
			</div>
		</div>
	)
}

export default WorkSummariesReport