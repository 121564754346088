import React, {useState, useEffect} from 'react';
import styles from './WorkListGoogleDrive.module.css';
import InputText from '../../components/InputText'
import jwtDecode from 'jwt-decode'
import { doSort } from '../../utils/sort'

function WorkListGoogleDrive(props) {
	const {
		openList,
		setFileGoogle,
		acceptFiles = ['application/vnd.google-apps.document',
			'application/pdf',
			'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
			'text/xml',
			'text/plain']
	} = props;

	const [fileNamesFiltered, setFileNamesFiltered] = useState()
	const [filterDocName, setFilterDocName] = useState('')
	const [fileNames, setFileNames] = useState()
	const [accessTokenGoogle, setAccessTokenGoogle] = useState()

	useEffect(() => {
		let filtered = fileNames && fileNames.length > 0 ? [...fileNames] : []
		if (filterDocName) filtered = filtered && filtered.length > 0 && filtered.filter(m => m.name.toLowerCase().indexOf(filterDocName.toLowerCase()) > -1)
		filtered = doSort(filtered, { sortField: 'name', isAsc: true, isNumber: false })
		setFileNamesFiltered(filtered)
	}, [fileNames, filterDocName])

	function handleGoogleCallbackResponse(response) {
		const userJwt = jwtDecode(response.credential)
		localStorage.setItem("googleToken", JSON.stringify(response.credential))
		if (response) props.login({
			isNewAccount: false,
			googleId: userJwt.sub,
			socialMediaToken: response.credential,
			firstName: userJwt.given_name,
			lastName: userJwt.family_name,
			emailAddress: userJwt.email,
			emailAddressConfirm: userJwt.email,
			username: userJwt.email,
			clave: '',
			claveConfirm: '',
			recaptchaResponse: '',
			inviteCode: props.inviteCodeShort,
		}, props.inviteCodeShort, false);
	}

	useEffect(() => {
		if (openList) {
			/* global google */
			google.accounts.id.initialize({
				client_id: "612044266489-9jesmmipkgj4c6fqjnso5muo12ikg3cm.apps.googleusercontent.com",
				callback: handleGoogleCallbackResponse,
			})

			google.accounts.id.renderButton(
				document.getElementById('signInDiv'),
				{ theme: "outline", size: "large" }
			)

			const getAndSetAccessTokenApi = (accessTokenApi) => {
				accessTokenApi.requestAccessToken()
			}

			const callForFileList = async (accessToken) => {
				const baseUrl = 'https://www.googleapis.com/drive/v3/files';
				let fileNames = [];
				let nextPageToken = null;

				do {
					const response = await fetch(`${baseUrl}${nextPageToken ? `?pageToken=${nextPageToken}` : ''}`, {
						method: 'GET',
						headers: {
							'content-type': 'application/json',
							'Authorization': `Bearer ${accessToken}`,
						},
					});

					if(!response.ok) {
						throw new Error(`HTTP error! Status: ${response.status}`);
					}

					const data = await response.json();
					data && data.files && data.files.length > 0 && data.files.forEach(m => {
						fileNames.push(m)
					})
					nextPageToken = data.nextPageToken;					
				} while (nextPageToken)

				setFileNames(fileNames)
			}

			getAndSetAccessTokenApi(google.accounts.oauth2.initTokenClient({
				client_id: "612044266489-9jesmmipkgj4c6fqjnso5muo12ikg3cm.apps.googleusercontent.com",
				scope: "https://www.googleapis.com/auth/drive.readonly https://www.googleapis.com/auth/contacts.readonly",
				callback: (tokenResponse) => {
					if (tokenResponse && tokenResponse.access_token) {
						callForFileList(tokenResponse.access_token)
						setAccessTokenGoogle(tokenResponse.access_token)
					}
				}
			}))
		}
	}, [openList])

	async function downloadFile(file) {
		setFileGoogle({ blob: null, file })
		let url = '';
		const headers = {
			Authorization: `Bearer ${accessTokenGoogle}`,
			'Content-Type': 'application/json',
		};

		if (file.mimeType === 'application/vnd.google-apps.document') {
			url = `https://www.googleapis.com/drive/v3/files/${file.id}/export?mimeType=application/vnd.openxmlformats-officedocument.wordprocessingml.document`;
		//} else if (file.mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
		} else {
			url = `https://www.googleapis.com/drive/v3/files/${file.id}?alt=media`;
		}

		const response = await fetch(url, { method: 'GET', headers });

		if (!response.ok) {
			throw new Error('Failed to download file: ' + response.statusText);
		}

		const blob = await response.blob(); 
		if (file.mimeType === 'application/vnd.google-apps.document') {
			file.name = file.name.replace(/.docx/g, '') + '.docx' //Just to ensure that we are giving it the file extension just once.
		}
		setFileGoogle({blob, file})
	}

	return (
		<div>
			<div className={styles.mainDiv}>
				<InputText
					size={"medium"}
					name={"filterDocName"}
					label={`Filter file names`}
					value={filterDocName}
					onChange={(event) => setFilterDocName(event.target.value)} />
				<hr />
				<div className={styles.fileList}>
					{fileNamesFiltered?.filter(m => acceptFiles.indexOf(m.mimeType) > -1).map((m, i) =>
						<div key={i + 'summary'} onClick={() => downloadFile(m)} className={styles.fileName}>
							<input type='radio' checked={false} onChange={() => {}} />
							{m.name}
						</div>
					)}
					{!(fileNamesFiltered && fileNamesFiltered.length > 0) && 
						<div className={styles.noContacts}>There are not any document found</div>
					}
				</div>
			</div>
		</div>
	);
}

export default WorkListGoogleDrive;

