import React from 'react';
import styles from './EditListChoice.module.css';
import SelectSingleDropDown from '../SelectSingleDropDown'
import Icon from '../Icon'
import { useMediaQuery } from 'react-responsive';

function EditListChoice(props) {
	const {editOptions=[], handleSetEditChosen, editChosen} = props;
	const isMobile = useMediaQuery({ query: '(max-width: 500px)' })

	const handleEditMove = (direction) => {
		let index = -1
		editOptions.forEach((m, i) => {
			if (m.id === Number(editChosen)) index = i
		})

		if (direction === 'PREV') {
			if (index < 1) {
				handleSetEditChosen(0, '')
			} else {
				handleSetEditChosen(editOptions[index-1].id, editOptions[index-1].label)
			}
		} else {
			if (index >= editOptions.length-1) {
				handleSetEditChosen(editOptions[editOptions.length-1].id, editOptions[editOptions.length-1].label)
			} else {
				handleSetEditChosen(editOptions[index+1].id, editOptions[index+1].label)
			}
		}
	}

	let label = editOptions.length === 1 ? isMobile ? `1 edit` : `- 1 edit -` : isMobile ? `${editOptions.length || 0} edits` : `- ${editOptions.length || 0} edits -`
	return (
		<div className={styles.container}>
			{!isMobile && <Icon pathName={'arrow_up'} premium={false} className={styles.icon} onClick={() => handleEditMove('PREV')} title={'Go to previous edit'}/>}
			<div>
        <SelectSingleDropDown
					label={``}
					zeroethLabel={label}
					includeDownArrow
					value={editChosen}
					options={editOptions || []}
					height={`medium-short`}
					className={isMobile ? styles.narrowList : ''}
					onChange={(event) => {
						handleSetEditChosen(event.target.value, event.target.options[event.target.selectedIndex].innerHTML)
					}} />
			</div>
			{!isMobile && <Icon pathName={'arrow_down'} premium={false} className={styles.iconLeft} onClick={() => handleEditMove('NEXT')} title={'Go to next edit'}/>}
		</div>
	)
}

export default EditListChoice