import React, {useState, useEffect} from 'react';
import {useNavigate} from "react-router";
import styles from './WorkNameDisplay.module.css';
import SelectSingleDropDown from '../SelectSingleDropDown'
import classes from 'classnames';

export default (props) => {
  const { workSummary = { editorAssign: [] }, showLanguageOptions, editLanguageId, personId, isAuthor, isTranslation } = props
  const navigate = useNavigate()

  const [currentChapter, setCurrentChapter] = useState({chapterNbr: '', name: ''})

  useEffect(() => {
    const currentChapter = (workSummary && workSummary.chapterOptions && workSummary.chapterOptions.length > 0 && workSummary.chapterOptions.filter(m => m.chapterId === props.chapterId)[0]) || {}
    setCurrentChapter(currentChapter)
  }, [props.chapterId, workSummary])

  let languageCount = isAuthor
    ? workSummary && workSummary.workLanguageOptions && workSummary.workLanguageOptions.length
    : 1
  let translationLanguageName = isTranslation && personId && workSummary.editorAssign && workSummary.editorAssign.length > 0 && workSummary.editorAssign.filter(m => m.editorPersonId === personId)[0]
  if (isTranslation && translationLanguageName) translationLanguageName = translationLanguageName.languageName

  const getOptions = (work) => {
    return work.chapterOptions.reduce((acc, m) => {
      let option = {
        id: m.chapterId,
        label: m.chapterNbr + ' - ' + m.name
      }
      return acc && acc.length > 0 ? acc.concat(option) : [option]
    }, [])
  }

  const handleSetChosenChapter = (chapterId, workId) => {
    const storage = localStorage.getItem('chosenChapters')
    const chosenChapters = storage ? JSON.parse(storage) : {}
    chosenChapters[workId] = chapterId
    localStorage.setItem('chosenChapters', JSON.stringify(chosenChapters))
    navigate(`/editReview/${workSummary.workId}/${chapterId}/${workSummary.languageName_current}`)
  }

  return (
    <div className={classes(styles.workTitle, (workSummary && workSummary.sectionCount > 1 ? styles.showSection : ''))}>
      <div className={styles.row}>
        {!workSummary || !workSummary.title
          ? ''
          : workSummary.title.length > 30
            ? workSummary.title.substring(0,30) + `...`
            : workSummary.title
        }
      </div>
      {workSummary && workSummary.authorName && //&& workSummary.groupName
        <div>
          <div className={styles.author}>
            {workSummary.authorName}
            {workSummary && workSummary.languageName_current && workSummary.languageName_current !== "" && //!workSummary.groupName &&
              <span className={styles.language}>
                  ({workSummary && workSummary.languageName_current.length > 1 && workSummary.title &&
                      workSummary.title.length > 25 ? workSummary.languageName_current.substring(0,25) : workSummary.languageName_current})
              </span>
            }
            {translationLanguageName &&
              <span className={styles.translation}>
                  {`${translationLanguageName.length > 25 ? translationLanguageName.substring(0,25) : translationLanguageName} Translation`}
              </span>
            }
            {!translationLanguageName && showLanguageOptions && languageCount > 1 &&
              <div className={styles.listPosition}>
                <SelectSingleDropDown
                  label={``}
                  value={editLanguageId}
                  options={workSummary.workLanguageOptions || []}
                  includeDownArrowFirst
                  height={`small-narrow`}
                  className={styles.narrowListLanguage}
                  onChange={(event) => navigate(`/editReview/${workSummary.workId}/${props.chapterId}/${event.target.value}`)}/>
              </div>
            }
          </div>
          {workSummary?.chapterOptions?.length > 1 &&
            <div>
              <SelectSingleDropDown
                label={''}
                noBlank
                zeroethLabel={workSummary?.chapterOptions[0].chapterNbr + ' - ' + workSummary?.chapterOptions[0].name}
                zeroethValue={workSummary?.chapterOptions[0].chapterId}
                includeDownArrowFirst
                value={props.chapterId}
                options={getOptions(workSummary)}
                height={`medium`}
                labelClass={styles.textWhite}
                className={styles.narrowListChapters}
                onChange={(event) => handleSetChosenChapter(event.target.value, workSummary.workId)} />
            </div>
          }
        </div>
      }
      {currentChapter && workSummary.chapterOptions && workSummary.chapterOptions.length === 1 && workSummary.workName !== currentChapter.name &&
        <div className={styles.section}>
          {`(#` + currentChapter.chapterNbr + `) `}
          {currentChapter && currentChapter.name && currentChapter.name.length > 48
            ? currentChapter.name.substring(0,48) + `...`
            : currentChapter.name}
        </div>
      }
    </div>
  )
};
