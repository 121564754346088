import React, {useState} from 'react';
import {useNavigate} from 'react-router'
import styles from './GroupFileExplorerOptions.module.css';
import MessageModal from '../MessageModal';
import Icon from '../Icon';
import {guidEmpty} from '../../utils/GuidValidate'

function GroupFileExplorerOptions(props) {
  const {
    personId,
    group={},
    chosenGroup={ groupId: 0},
    setChosenGroup,
    mineOrOthers,
    deleteGroup,
  } = props;

  const navigate = useNavigate()
  const [isShowingDelete, setIsShowingDelete] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState('')

  const sendToGroupSetupEdit = (event) => {
    navigate(`/groupAddNew/${group.groupId}`)
  }

  const sendToGroupPeopleEdit = () => {
    navigate(`/groupContacts/${group.groupId}`)
  }

  const handleDeleteGroup = () => {
    deleteGroup(personId, group.groupId)
    setIsShowingDelete(false)
    setChosenGroup('')
  }

  const handleShowDeleteMessage = () => {
    setDeleteMessage(`Are you sure you want to delete this group?`)
    setIsShowingDelete(true)
  }

  return (
    <div>
      <div className={styles.container}>
        {mineOrOthers === 'Mine' && group.groupId !== guidEmpty &&
          <a onClick={sendToGroupSetupEdit} className={styles.menuItem}
             title={'Edit this group setup'}>
            <Icon pathName={`pencil0`}
                  premium={true}/>
          </a>
        }
        {mineOrOthers === 'Mine' &&
          <a onClick={sendToGroupPeopleEdit} className={styles.menuItem}
             title={'Edit this group setup'}>
            <Icon pathName={`users2`}
                  premium={true}/>
          </a>
        }
        { group.groupId !== guidEmpty &&
          <a onClick={handleShowDeleteMessage} title={`Delete this group`}>
            <Icon pathName={`trash2`}
                  premium={true}/>
          </a>
        }
      </div>
      <MessageModal show={isShowingDelete} handleClose={() => setIsShowingDelete(false)}
                    heading={`Delete this group?`} explain={deleteMessage}
                    isConfirmType onClick={handleDeleteGroup}/>
    </div>
  )
}

export default GroupFileExplorerOptions;