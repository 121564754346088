import React from 'react';
import styles from './EditorSaveButton.module.css';
import moment from 'moment';
import classes from 'classnames'

function EditorSaveButton(props) {
	const {
		label = "UPDATE",
		changeCounts,
		saveWorkSpaceTime,
		saveByButtonPress,
		addClassName,
	} = props

	return (
		<button onClick={saveByButtonPress} className={classes(styles.saveIcon, addClassName)} style={{ color: changeCounts ? 'maroon' : 'green' }} >
			<div className={styles.smallText}>{label}</div>
			<div className={styles.timeSave}>
				{moment(saveWorkSpaceTime).format("h:mm")}
				<span className={styles.smallSeconds}>{moment(saveWorkSpaceTime).format(":ss")}</span>
			</div>
		</button>
	)
};

export default EditorSaveButton;